<div class="template-selection">
  <div class="audience-set-selection-container">
    <div class="audience-set-selection-heading">
      <span class="audience-set-selection-heading-title">
        Select template
        <i class="fa-solid fa-rotate" (click)="getTemplateList(1)"></i>
      </span>
      <span class="audience-set-selection-heading-icons">
        <i class="fa-solid fa-xmark" (click)="cancelClicked.emit(true)"></i>
      </span>
    </div>
    <div class="audience-set-search-heading" id="popup-search">
      <search-input style="width: 100%" [(searchInput)]="templateSearchTag" (searchInputChange)="searchTemplates($event)"></search-input>
    </div>
    <div class="audience-set-selection-list" (scroll)="onTemplateListScroll($event)">
      <!-- <div >
        <div class="template-list-item" [ngStyle]="{'background' : isTemplateChecked(item) ? '#E3F7F3' : 'none'}" *ngFor="let item of templatesList" (click)="selectedTemplate = item">
          <div class="template-item-description">
            <span class="name">{{item?.entity_name}} <span class="category">{{item?.category}}</span></span>
            <span class="content">
              <mat-icon *ngIf="item?.header_type === 'IMAGE'" svgIcon="image_grey"></mat-icon> 
              <mat-icon *ngIf="item?.header_type === 'VIDEO'" svgIcon="video_grey"></mat-icon> 
              <mat-icon *ngIf="item?.header_type === 'DOCUMENT'" svgIcon="document_grey"></mat-icon> 
              <span>{{item?.template}}</span>
            </span>
          </div>
        </div>
      </div> -->
      <table class="audience-set-container-table" *ngIf="!loaderTemplateList && templatesList.length > 0">
        <tr class="audience-set-table-row" [ngClass]="selectedTemplate?.id === item?.id ? 'selected' : ''" [ngStyle]="{'background' : isTemplateChecked(item) ? '#E3F7F3' : ''}" *ngFor="let item of templatesList" (click)="selectedTemplate = item">                                                                                                                              
          <td style="border-radius: 10px 0 0 10px;" class="audience-set-table-set-name"><span style="max-width: 200px;">{{item?.entity_name}}</span></td>
          <td class="audience-set-table-cell">
            <span style="display: flex;gap: 4px;">
              <mat-icon svgIcon="marketing_template_grey" *ngIf="item.category === enums.marketing"></mat-icon><mat-icon svgIcon="products_light" *ngIf="item.category === enums.utility"></mat-icon>{{item?.category | pascalCase}}
            </span>
          </td>
          <td class="audience-set-table-cell">
            <span style="display: flex;gap: 4px;">
              <mat-icon svgIcon="image_icon" *ngIf="(item | templateType) === templateTypes.media"></mat-icon>
              <mat-icon svgIcon="notes" *ngIf="(item | templateType) === templateTypes.text"></mat-icon>
              <mat-icon svgIcon="location_light" *ngIf="(item | templateType) === templateTypes.location"></mat-icon>
              <mat-icon svgIcon="percent" *ngIf="(item | templateType) === templateTypes.lto"></mat-icon>
              <mat-icon svgIcon="label_grey" *ngIf="(item | templateType) === templateTypes.offer_code"></mat-icon>
              <mat-icon svgIcon="catalog" *ngIf="(item | templateType) === templateTypes.catalog"></mat-icon>
              <mat-icon svgIcon="products_light" *ngIf="(item | templateType) === templateTypes.products"></mat-icon>
              <mat-icon svgIcon="carousel" *ngIf="(item | templateType) === templateTypes.carousel"></mat-icon>
              {{item | templateType }}
            </span>
          </td>
          <td style="border-radius: 0 10px 10px 0" class="audience-set-table-cell"><span style="max-width: 350px;">{{item?.template}}</span></td>
        </tr>
      </table>
      <div *ngIf="loaderTemplateList" class="loader-container">
        <span class="skeleton-loader"><ngx-skeleton-loader count="9" appearance="line"></ngx-skeleton-loader></span>
        <span class="skeleton-loader"><ngx-skeleton-loader count="9" appearance="line"></ngx-skeleton-loader></span>
        <span class="skeleton-loader"><ngx-skeleton-loader count="9" appearance="line"></ngx-skeleton-loader></span>
      </div>
      <div class="empty-list-container" *ngIf="!loaderTemplateList && templatesList?.length === 0">No Templates yet</div>
    </div>
    <div class="audience-set-selection-footer">
      <span class="audience-set-selection-footer-redirect">
        Create new template <i class="fa-solid fa-arrow-up-right-from-square"></i>
      </span>
      <span class="audience-set-selection-footer-buttons">
        <cancel-button [cancelButtonText]="'Cancel'" (cancelClicked)="cancelClicked.emit(true)"></cancel-button>
        <save-button [saveButtonText]="'Next'" [disableSave]="!selectedTemplate" (saveClicked)="selectTemplate()"></save-button>
      </span>
    </div>
  </div>
</div>

<div class="shaded-screen"></div>