import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalStrings } from 'src/app/constants';

@Component({
  selector: 'email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.css']
})
export class EmailInputComponent implements OnInit {

  @Input() inputEmail!: string;
  @Input() fieldName!: string;
  @Input() maxChars!: number;
  @Input() placeholder: string = 'Enter email address';
  @Output() emailChange = new EventEmitter<string>();
  @Output() enterClicked = new EventEmitter<string>();
  @Output() isValid = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  checkLength(event: any){
    if(this.maxChars){
      if(this.inputEmail?.length < this.maxChars) return true;
      else return false;
    } else return true;
  }
  restrictLength(){
    this.inputEmail = this.inputEmail.substring(0,this.maxChars);
    this.inputEmail = this.inputEmail.trim();
    if(this.inputEmail.match(GlobalStrings.emailRegex)) {
      this.isValid.emit(true);
    } else this.isValid.emit(false);
  }
}
