import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Label } from 'src/app/models/label';
import { LabelsService } from 'src/app/services/labels.service';

import { ChatsService } from 'src/app/services/chats.service';
import { GlobalStrings } from 'src/app/constants';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'assign-label',
  templateUrl: './assign-label.component.html',
  styleUrls: ['./assign-label.component.css']
})
export class AssignLabelComponent implements OnInit {

  @Output() assignLabelEvent = new EventEmitter<Label[]>();
  @Output() cancelLabelEvent = new EventEmitter<boolean>();
  @Input() assignedLabelList: Label[] = [];
  @Input() customerId!: number;
  searchEmptyList: string = GlobalStrings.searchEmptyList;
  emptyLabelsMessage = GlobalStrings.blankLabelsMessage;
  MAX_LABEL_COUNT = 1000000;
  labelList: Label[] = [];
  loaderLabelList: boolean = false;
  loaderLabelPagination: boolean = false;
  loaderAssignLabel: boolean = false;
  pageNumber: number = 1;
  pageNext: boolean = false;
  labelSearchTag: string = '';
  prevLabelSearchTag: string = '';
  labelSet = new Set<number>();
  initialLabelSet = new Set<number>();
  isSearchLabel: boolean = false;

  constructor(
    private labelService: LabelsService,
    private toastr: ToasterService,
    private chatsService: ChatsService
  ) { }

  focusSearch() {
    setTimeout(() => {
    var currentInput = document.getElementById('popup-search');
    if(currentInput){
      var childInput = currentInput.querySelector('input');
      if (childInput) {
          childInput.focus();
        }
      }
    }, 10);
  }

  ngOnInit(): void {
    this.labelSet.clear();
    this.initialLabelSet.clear()
    this.loaderLabelList = true;
    let response = this.labelService.getLabel(1);
    response.subscribe((resp)=>{
      if(resp.body.code === 200) {
        try {
          if(resp.body.next) this.pageNext = true;
          else this.pageNext = false;
          for (var index = 0; index < resp.body.data?.length; index++) {
            this.labelList.push(new Label(resp.body.data[index]));
          }
          this.loaderLabelList = false;
          this.loaderLabelPagination = false;
          this.pageNumber++;
        } catch (ex) {
        }
      }
    },(error)=>{
      console.log(error);
      this.loaderLabelList = false;
      this.loaderLabelPagination = false;
    });
    console.log(this.assignedLabelList);
    this.assignedLabelList?.forEach((label)=>{
      this.labelSet.add(label.id);
      this.initialLabelSet.add(label.id);
    })
  }
  getLabels(pageNumber:number){
    this.loaderLabelPagination = true;
    let response = this.labelService.getLabel(pageNumber);
    response.subscribe((resp)=>{
      try {
        if(resp.body.code === 200) {
          if(resp.body.next) this.pageNext = true;
          else this.pageNext = false;
          if(pageNumber === 1) this.labelList = [];
          for (var index = 0; index < resp.body.data?.length; index++) {
            this.labelList.push(new Label(resp.body.data[index]));
          }
          this.loaderLabelList = false;
          this.loaderLabelPagination = false;
          this.pageNumber++;
        }
      } catch (exception) {
      }
    },(error)=>{
      console.log(error);
      this.loaderLabelList = false;
      this.loaderLabelPagination = false;
    });
  }

  getSearchedLabels(pageNumber:number) {
    this.loaderLabelPagination = true;
    let response = this.labelService.searchLabel(this.labelSearchTag,pageNumber);
    response.subscribe((resp)=>{
      try {
        if(resp.body.code === 200) {
          if(resp.body.next) this.pageNext = true;
          else this.pageNext = false;
          if(pageNumber === 1) this.labelList = [];
          for (var index = 0; index < resp.body.data?.length; index++) {
            this.labelList.push(new Label(resp.body.data[index]));
          }
          this.loaderLabelList = false;
          this.loaderLabelPagination = false;
          this.pageNumber++;
        }
      } catch (exception) {
      }
    },(error)=>{
      console.log(error);
      this.loaderLabelList = false;
      this.loaderLabelPagination = false;
    });
  }
  
  searchLabels(event: any){
    setTimeout(() => {
      if(this.prevLabelSearchTag !== this.labelSearchTag && this.labelSearchTag?.length > 2) {
        this.pageNumber = 1;
        this.pageNext = true;
        this.prevLabelSearchTag = this.labelSearchTag;
        this.getSearchedLabels(1);
      } else if(this.prevLabelSearchTag !== this.labelSearchTag && this.prevLabelSearchTag?.length > 2) {
        this.pageNumber = 1;
        this.pageNext = true;
        this.prevLabelSearchTag = this.labelSearchTag;
        this.getLabels(1);
      }
    }, 300);
  }
  onLabelsScroll(event: any) {
    if (
      event.target.scrollHeight -
        event.target.offsetHeight -
        event.target.scrollTop <
      50
    ) {
      if (this.pageNext && !this.loaderLabelPagination) {
        this.getLabels(this.pageNumber);
      }
    }
  }

  addLabel(id: number){
    if(this.labelSet.has(id)){
      this.labelSet.delete(id);
    } else {
      if(this.labelSet.size > (this.MAX_LABEL_COUNT - 1)) {
        this.toastr.error("Cannot assign more than "+this.MAX_LABEL_COUNT+" labels");
        return ;
      }
      this.labelSet.add(id);
    }
  }

  exitAssign(){
    this.cancelLabelEvent.emit();
  }
  assignLabel(){
    let labelArray: number[] = [];
    this.labelSet.forEach((id)=>{
      labelArray.push(id);
    });
    if(this.labelSet.size === this.initialLabelSet.size && [...this.labelSet].every((x)=> this.initialLabelSet.has(x))) {
      this.cancelLabelEvent.emit();
      return ;
    }
    console.log(this.labelSet);
    let input = {
      label_id: labelArray,
      customer_id: this.customerId
    }
    this.loaderAssignLabel = true;
    let response = this.chatsService.assignLabel(input);
    response.subscribe((resp)=>{
      try {
        if(resp.body.code === 200){
          let labelArray: Label[] = [];
          labelArray = this.assignedLabelList.filter(a => !this.labelList.map(b=>b.id).includes(a.id));
          this.labelList?.forEach((label)=>{
            if(this.labelSet.has(label.id)){
              labelArray.push(label);
            }
          })
          this.toastr.success(resp.body.message);
          this.assignLabelEvent.emit(labelArray);
          this.loaderAssignLabel = false;
        }
      } catch (ex) {
      }
    },(error)=>{
      console.log(error);
      this.loaderAssignLabel = false;
    })
  }

}
