import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent {

  @Input() currentPage: number = 1;
  @Input() paginationSize: number = 5;
  @Input() listLength!: number; //Count of the whole list in first page response
  @Output() pageChange = new EventEmitter<number>();

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.matIconRegistry.addSvgIcon(
      'pagination_left',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/pagination_left.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'pagination_right',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/pagination_right.svg'
      )
    );
  }
  
  pageLeft(){
    if(this.currentPage === 1) return ;
    else this.currentPage = this.currentPage -1;
    this.pageChange.emit(this.currentPage);
  }
  pageRight(){
    if(this.currentPage === (this.listLength)) return ;
    else this.currentPage = this.currentPage +1;
    this.pageChange.emit(this.currentPage);
  }
  isPageRightDisabled(){
    let float = 1.0;
    if(this.currentPage === Math.ceil(this.listLength/(this.paginationSize*float))) return true;
    else return false;
  }

  
}