<div class="dropdown">
  <button
  class="btn btn-custom-dropdown dropdown-toggle variable-dropdown"
  type="button"
  id="variableDropdownMenu"
  data-bs-toggle="dropdown"
  aria-expanded="false"
  >
    <span class="variable-selected-type">
      <span>User</span>
      <span class="selected-label">
        <span class="name">: {{ selectedTeamMember?.name !== '' ? selectedTeamMember?.name : 'All'}}</span>
      </span>
    </span>
    <i class="fas fa-angle-down"></i>
  </button>
  <ul
    class="dropdown-menu custom-dropdown-menu variable-dropdown-menu"
    aria-labelledby="headerDropdownMenu"
  >
    <li 
      [ngClass]="{active: !selectedTeamMember?.id}"
      class="label-item"
      (click)="selectMember(null)"
    >
      All
    </li>
    <li 
      *ngFor="let member of teamMemberList"
      [ngClass]="{active: selectedTeamMember?.id === member?.id}"
      class="label-item"
      (click)="selectMember(member)"
    >
      {{member?.name}}
    </li>
  </ul>
</div>