import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: 'cancel-button',
  templateUrl: './cancel-button.component.html',
  styleUrls: ['./cancel-button.component.css']
})

export class CancelButtonComponent {

  @Input() cancelButtonText!: string;
  @Input() hideCancel: boolean = false;
  @Input() disableCancel: boolean = false;
  @Output() cancelClicked = new EventEmitter<boolean>();
}