export class LoggedUser {
  private _id: number;
  private _user_type: number;
  private _mobile: string;
  private _on_boarding: boolean;
  private _is_active: boolean;
  private _date_joined: Date;
  private _last_login: Date;
  private _name: string;
  private _email: string;
  private _updated_on: Date;
  private _business: number;
  private _is_password_set: boolean;
  private _is_email_verified: boolean;
  private _profile_pic: string;
  private _user_status: string;

  constructor(userJson: any) {
    this._id = userJson?.id;
    this._user_type = userJson?.user_type;
    this._mobile = userJson?.mobile;
    this._on_boarding = userJson?.on_boarding;
    this._is_active = userJson?.is_active;
    this._date_joined = userJson?.date_joined;
    this._last_login = userJson?.last_login;
    this._name = userJson?.name;
    this._email = userJson?.email;
    this._updated_on = userJson?.updated_on;
    this._business = userJson?.business;
    this._is_password_set = userJson?.is_password_set;
    this._is_email_verified = userJson?.is_email_verified;
    this._profile_pic = userJson?.profile_pic;
    this._user_status = userJson?.user_status;
  }
  public get user_status(): string {
    return this._user_status;
  }
  
  public set user_status(user_status: string) {
      this._user_status = user_status;
  }

  public get id(): number {
      return this._id;
  }

  public set id(id: number) {
      this._id = id;
  }

  public get user_type(): number {
      return this._user_type;
  }

  public set user_type(user_type: number) {
      this._user_type = user_type;
  }

  public get mobile(): string {
      return this._mobile;
  }

  public set mobile(mobile: string) {
      this._mobile = mobile;
  }

  public get on_boarding(): boolean {
      return this._on_boarding;
  }

  public set on_boarding(on_boarding: boolean) {
      this._on_boarding = on_boarding;
  }

  public get is_active(): boolean {
      return this._is_active;
  }

  public set is_active(is_active: boolean) {
      this._is_active = is_active;
  }
  
  public get date_joined(): Date {
      return this._date_joined;
  }
  
  public set date_joined(date_joined: Date) {
      this._date_joined = date_joined;
  }
  
  public get last_login(): Date {
      return this._last_login;
  }
  
  public set last_login(last_login: Date) {
      this._last_login = last_login;
  }
  
  public get name(): string {
      return this._name;
  }
  
  public set name(name: string) {
      this._name = name;
  }
  
  public get email(): string {
      return this._email;
  }
  
  public set email(email: string) {
      this._email = email;
  }
  
  public get updated_on(): Date {
      return this._updated_on;
  }
  
  public set updated_on(updated_on: Date) {
      this._updated_on = updated_on;
  }
  
  public get business(): number {
      return this._business;
  }
  
  public set business(business: number) {
      this._business = business;
  }

  public get is_password_set(): boolean {
    return this._is_password_set;
  }
  
  public set is_password_set(is_password_set: boolean) {
      this._is_password_set = is_password_set;
  }

  public get is_email_verified(): boolean {
    return this._is_email_verified;
  }
  
  public set is_email_verified(is_email_verified: boolean) {
      this._is_email_verified = is_email_verified;
  }

  public get profile_pic(): string {
    return this._profile_pic;
  }

  public set profile_pic(profile_pic: string) {
    this._profile_pic = profile_pic;
  }

}