<div class="billing-card">
  <div class="white-card" *ngIf="isBillingForm === 'show'">
    <div class="card-heading">
      <span class="card-heading-title">Billing Details</span>
      <mat-icon svgIcon="edit" *ngIf="user?.user_type === 1" (click)="editBillingDetails()"></mat-icon>
    </div>
    <div class="card-fields">
      <span class="field-title">Legal business name</span>
      <span class="field-value">{{billingDetails[0]?.billing_name}}</span>
    </div>
    <div class="card-fields">
      <span class="field-title">Business address</span>
      <span class="field-value">
        {{billingDetails[0]?.address_line_1}},{{billingDetails[0]?.address_line_2 ? billingDetails[0]?.address_line_2+',' : ''}}
        {{billingDetails[0]?.city}},{{billingDetails[0]?.state}},{{billingDetails[0]?.country}},{{billingDetails[0]?.zip}}
      </span>
    </div>
    <div class="card-fields" *ngIf="billingDetails[0]?.country?.toLowerCase() === 'india'">
      <span class="field-title">GST number</span>
      <span class="field-value">{{billingDetails[0]?.gst_no ? billingDetails[0]?.gst_no : '-'}}</span>
    </div>
  </div>
  <div class="white-card" *ngIf="isBillingForm === 'edit'">
    <div class="card-heading"><span>Billing information</span></div>
    <div class="card-fields">
      <span class="field-title">Company name<span style="color: #EC2222;">*</span></span>
      <text-input 
        class="w-100"
        [inputText]="billingForm.controls['company_name']?.value" 
        (textChange)="billingForm.controls['company_name'].setValue($event)"
      ></text-input>
    </div>
    <div class="card-fields">
      <span class="field-title">Address<span style="color: #EC2222;">*</span></span>
      <text-input 
        class="w-100"
        [inputText]="billingForm.controls['address']?.value" 
        (textChange)="billingForm.controls['address'].setValue($event)"
      ></text-input>
    </div>
    <div class="state-pincode">
      <div class="card-fields">
        <span class="field-title">Country<span style="color: #EC2222;">*</span></span>
        <div class="dropdown" style="width: 300px">
          <button 
            class="btn dropdown-toggle country-code-dropdown" 
            data-bs-toggle="dropdown"
            aria-expanded="false" 
            type="button"
            id="countryDropdownMenu"
            (click)="focusSearch()"
          >
            <span style="color: #111B21;" *ngIf="billingForm.controls['country']?.value && billingForm.controls['country']?.value !== ''">{{billingForm.controls['country']?.value}}</span>
            <span *ngIf="billingForm.controls['country']?.value === ''">Select country</span>
          </button>
          <ul class="dropdown-menu country-code-dropdown-menu" aria-labelledby="countryDropdownMenu">
            <search-input (click)="$event.stopPropagation()" (searchInputChange)="countrySearchTag = $event" [(searchInput)]="countrySearchTag"></search-input>
            <li *ngFor="let country of _sharedDataService.apiCountryList | search:countrySearchTag" class="country-code-dropdown-item" (click)="setBillingCountry(country)">
              {{ country?.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="card-fields">
        <span class="field-title">State<span style="color: #EC2222;">*</span></span>
        <div class="dropdown" style="width: 300px">
          <button 
            class="btn dropdown-toggle country-code-dropdown" 
            data-bs-toggle="dropdown"
            aria-expanded="false" 
            type="button"
            id="countryDropdownMenu"
            (click)="focusSearch()"
          >
            <span style="color: #111B21;" *ngIf="billingForm.controls['state']?.value && billingForm.controls['state']?.value !== ''">{{billingForm.controls['state']?.value}}</span>
            <span *ngIf="billingForm.controls['state']?.value === ''">Select state</span>
          </button>
          <ul class="dropdown-menu country-code-dropdown-menu" aria-labelledby="countryDropdownMenu">
            <search-input (click)="$event.stopPropagation()" (searchInputChange)="stateSearchTag = $event" [(searchInput)]="stateSearchTag"></search-input>
            <li *ngFor="let state of _sharedDataService.apiStateList | search:stateSearchTag" class="country-code-dropdown-item" (click)="setBillingState(state)">
              {{ state?.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="state-pincode">
      <div class="card-fields">
        <span class="field-title">City<span style="color: #EC2222;">*</span></span>
        <div class="dropdown" style="width: 300px">
          <button 
            class="btn dropdown-toggle country-code-dropdown" 
            data-bs-toggle="dropdown"
            aria-expanded="false" 
            type="button"
            id="countryDropdownMenu"
            (click)="focusSearch()"
          >
            <span style="color: #111B21;" *ngIf="billingForm.controls['city']?.value && billingForm.controls['city']?.value !== ''">{{billingForm.controls['city']?.value}}</span>
            <span *ngIf="billingForm.controls['city']?.value === ''">Select city</span>
          </button>
          <ul class="dropdown-menu country-code-dropdown-menu" aria-labelledby="countryDropdownMenu">
            <search-input (click)="$event.stopPropagation()" (searchInputChange)="citySearchTag = $event" [(searchInput)]="citySearchTag"></search-input>
            <li *ngFor="let city of _sharedDataService.apiCityList | search:citySearchTag" class="country-code-dropdown-item" (click)="billingForm.controls['city'].setValue(city.name)">
              {{ city?.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="card-fields" style="width: 300px">
        <span class="field-title">Pincode<span style="color: #EC2222;">*</span></span>
        <text-input 
          class="w-100"
          [inputText]="billingForm.controls['pincode']?.value" 
          (textChange)="billingForm.controls['pincode'].setValue($event)"
        ></text-input>
      </div>
    </div>
    <div class="card-fields" *ngIf="billingForm.controls['country']?.value?.toLowerCase() === 'india'">
      <span class="field-title">GST number</span>
      <text-input 
        class="w-100"
        [inputText]="billingForm.controls['gstin']?.value" 
        (textChange)="billingForm.controls['gstin'].setValue($event)"
      ></text-input>
    </div>
    <div class="footer-buttons">
      <cancel-button [cancelButtonText]="'Cancel'" (cancelClicked)="isBillingForm = 'show';billingForm.reset()"></cancel-button>
      <save-button [saveButtonText]="'Confirm billing information'" [disableSave]="billingForm.invalid" [loading]="loaderBillingDetails" (saveClicked)="saveBilling()"></save-button>
    </div>
  </div>
  <div class="white-card empty-card" *ngIf="isBillingForm === 'empty'">
    <div class="empty-card-heading">
      <p>Add and manage billing information</p>
      <span>Add your business billing information to generate the invoice</span>
    </div>
    <save-button [saveButtonText]="'+ Add billing information'" (saveClicked)="isBillingForm = 'edit'"></save-button>
  </div>
</div>