import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalStrings } from 'src/app/constants';
import { Template } from 'src/app/models/template';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.css']
})
export class TemplatePreviewComponent implements OnInit {

  @Input() template!: Template;
  @Input() location: any = null;
  @Input() headerVariable: string= '';
  @Input() bodyVariables: string[] = [];
  @Input() bodyVariableTypes: string[] = Array<string>(16).fill('Text');
  @Input() headerVariableType: string = '';
  @Input() expiryDate!: Date;
  @Input() productsCount!: number

  MAX_VARIABLES: number = 16;
  isHeaderVariable = false;
  headerVariable1 = '';
  isBodyVariable = false;
  isWebsiteVariable = false;
  websiteVariableText = '';
  bodyVariablesCount = 0;
  previewHeaderText: string[] = [];
  previewBodyText: string[] = [];
  buttonsPreview: boolean = false;

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    public sharedDataService: SharedDataService,
  ) {
    this.matIconRegistry.addSvgIcon(
      'mobile_icons',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/mobile_icons.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'location_light',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/location_light.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'photo_camera',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/photo_camera.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'video_camera_back',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/video_camera_back.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'article',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/article.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'copy_light',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/copy_light.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'redeem',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/redeem.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'quick_reply',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/quick_reply.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'list',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/list.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'catalog',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/catalog.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'products_light',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/products_light.svg'
      )
    );
  }

  ngOnInit(): void {
    if (this.template?.header) {
      this.checkHeader();
    }
    this.checkBody();
    if(this.template?.url && this.template.url.indexOf('{{1}}') >0){
      this.isWebsiteVariable = true;
    } else {
      this.isWebsiteVariable = false;
    }
    
  }

  checkHeader() {
    let tempHeaderText = this.template?.header;
    if (this.template?.header.indexOf('{{1}}') > -1) {
      this.isHeaderVariable = true;
      let variableHeaderIndex = this.template.header.indexOf('{{1}}');
      this.previewHeaderText[0] = tempHeaderText.slice(
        0,
        variableHeaderIndex
      );
      this.previewHeaderText[1] = tempHeaderText.slice(
        variableHeaderIndex + 5
      );
      //console.log(this.previewHeaderText);
    } else {
      this.isHeaderVariable = false;
      this.previewHeaderText[0] = tempHeaderText;
    }
  }
  checkBody() {
    let tempBodyText = this.template?.template ?? '';
    const regex = new RegExp(GlobalStrings.templateVariableRegex, 'gi');
    let bodyVariableMatches = tempBodyText.match(regex);
    if(bodyVariableMatches){
      this.isBodyVariable = true;
      this.bodyVariablesCount = bodyVariableMatches.length;
      let remainingStrings = tempBodyText.split(regex);
      this.previewBodyText = remainingStrings;
    } else {
      this.isBodyVariable = false;
      this.previewBodyText= [tempBodyText];
      this.bodyVariablesCount = 0;
    }
  }
  getHeaderText() {
    this.checkHeader();
    let replacedHeader = ''
    if(this.previewHeaderText[0]) replacedHeader += this.previewHeaderText[0];
    if(this.isHeaderVariable) replacedHeader = replacedHeader + this.isHeaderVariable ? (this.headerVariableType === 'Text' ? (this.headerVariable1 === ''? '{{1}}' : this.headerVariable1) : '\<\< '+this.headerVariableType+' >>') : '' + this.previewHeaderText[1];
    return replacedHeader;
  }

  getBodyText() {
    this.checkBody();
    let replacedBody = '';
    if(this.previewBodyText[0]) replacedBody += this.previewBodyText[0];
    for(var index = 1; index< this.previewBodyText?.length; index++){
      if(this.bodyVariableTypes[index-1] === 'Text'){
        replacedBody = replacedBody + ((this.bodyVariables[index-1] === '' || !this.bodyVariables[index-1]) ? '{{'+index+'}}' : this.bodyVariables[index-1]) + this.previewBodyText[index];
      } else {
        replacedBody = replacedBody + '\<\< '+this.bodyVariableTypes[index -1]+' >>' + this.previewBodyText[index];
      }
    }
    return replacedBody;
  }

  getCarouselBodyText(index: number) {
    let card = this.template.carousel_cards[index];
    let tempBodyText = card.body_text;
    const regex = new RegExp(GlobalStrings.templateVariableRegex, 'gi');
    let bodyVariableMatches = tempBodyText?.match(regex);
    if(bodyVariableMatches){
      let remainingStrings = tempBodyText.split(regex);
      let replacedBody = '';
      if(remainingStrings[0]) replacedBody += remainingStrings[0];
      for(var index = 1; index< remainingStrings?.length; index++){
        replacedBody = replacedBody + ((card.body_text_example[index-1] === '' || !card.body_text_example[index-1]) ? '{{'+index+'}}' : card.body_text_example[index-1]) + remainingStrings[index];
      }
      return replacedBody;
    } else {
      return tempBodyText;
    }
  }

  getButtonsList(preview: boolean = false) :{ type: string; text: string; }[] {
    let buttons: { type: string; text: string; }[] = [];
    if(this.template?.catalog_button_text?.length > 0) {
      buttons.push({
        type: 'CATALOG',
        text: this.template.catalog_button_text,
      });
    }
    if(this.template?.mpm_button_text?.length > 0) {
      buttons.push({
        type: 'CATALOG',
        text: this.template.mpm_button_text,
      });
    }
    this.template?.buttons?.forEach((element) => {
      buttons.push({
        type: 'QUICK_REPLY',
        text: element,
      });
    });
    this.template?.cta_website?.forEach((element) => {
      buttons.push({
        type: 'URL',
        text: element.text,
      });
    })
    this.template?.coupon_code?.forEach((element) => {
      buttons.push({
        type: 'COUPON_CODE',
        text: 'Copy offer code',
      });
    })
    this.template?.limited_time_offer?.forEach((element) => {
      buttons.push({
        type: 'COUPON_CODE',
        text: 'Copy offer code',
      });
    })
    this.template?.phone_numbers?.forEach((element) => {
      buttons.push({
        type: 'PHONE_NUMBER',
        text: element.text,
      });
    })
    if(preview) return buttons;
    if(buttons?.length > 3) {
      buttons = buttons.slice(0,2);
      buttons.push({
        type: 'SEE_MORE',
        text: 'See all options',
      })
      return buttons;
    }
    else return buttons;
  }
}