<button 
  class="container-footer-save" 
  [disabled]="disableSave || loading" 
  [hidden]="hideSave"
  (click)="saveClicked.emit(true)"
  >
  <span *ngIf="!loading">{{saveButtonText}}</span>
  <mat-progress-spinner
    *ngIf="loading"
    mode="indeterminate"
    diameter="22"
  ></mat-progress-spinner>
</button>