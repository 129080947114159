<!-- (click)="userDetails(conversation, i)" -->
<div
  class="chat-list-item"
  (click)="openConversation($event)"
  [style.backgroundColor]="
    conversation?.id === openConversationId ? '#E3F7F3' : ''
  "
>
  <div class="user-pic">
    <expiry-icon 
      [name]="conversation?.customer | name"
      [date]="conversation?.last_customer_message_at"
    ></expiry-icon>
  </div>
  <div class="user-detail-wrap">
    <div class="user-name-label-time-wrap">
      <div class="user-name-label-wrap">
        <span class="user-name">
          {{ conversation?.customer | name }}</span
        >
        <button
          type="button"
          class="btn btn-assigned-person"
          *ngIf="conversation?.assignee?.id && !call"
          (click)="assignConversation($event)"
        >
          <mat-icon
            svgIcon="team_member_blue"
          ></mat-icon>
          <span class="assigned-agent-name">{{ conversation?.assignee?.name }}</span>
        </button>
        <button
          type="button"
          class="btn btn-assigned-button"
          *ngIf="!conversation?.assignee?.id && !call"
          (click)="assignConversation($event)"
        >
          <mat-icon
            svgIcon="team_member_blue"
          ></mat-icon>
          <span class="assigned-agent-name">Assign</span>
        </button>
      </div>
      <span class="time-wrap">{{
        checkTime(conversation?.last_message?.created_at)
          ? (conversation?.last_message?.created_at | date: "shortTime")
          : (conversation?.last_message?.created_at | ddmmyyyy).slice(0,-5)
      }}</span>
    </div>
    <p class="chat-sinppet-with-count">
      <span class="tick-marks-msg-wrap">
        <span>
        <!-- Double Blue tick for msg read -->
        <mat-icon
          class="msg-tick-mat-icon"
          *ngIf="
            conversation?.last_message?.message_status == 'read' &&
            conversation?.last_message?.msg_type !== 'user'
          "
          svgIcon="blue_tick"
        ></mat-icon>
        <!-- Double tick for msg delivered -->
        <mat-icon
          class="msg-tick-mat-icon"
          *ngIf="
            conversation?.last_message?.message_status == 'delivered' &&
            conversation?.last_message?.msg_type !== 'user'
          "
          svgIcon="double_tick"
        ></mat-icon>
        <!-- Single tick for msg read -->
        <mat-icon
          class="msg-tick-mat-icon"
          *ngIf="
            conversation?.last_message?.message_status == 'sent' &&
            conversation?.last_message?.msg_type !== 'user'
          "
          svgIcon="single_tick"
        ></mat-icon>
        <!-- Clock for msg not sent -->
        <mat-icon
          class="msg-tick-mat-icon"
          *ngIf="
            conversation?.last_message?.message_status !== 'read' &&
            conversation?.last_message?.message_status !== 'sent' &&
            conversation?.last_message?.message_status !==
              'delivered' &&
            conversation?.last_message?.msg_type !== 'user' &&
            conversation?.last_customer_message_at
          "
          class="clock-icon"
          svgIcon="clock"
        ></mat-icon></span>
        <i
          *ngIf="conversation?.last_message?.fb_msg_type === 'image'"
          class="fas fa-image"
        ></i>
        <i
          *ngIf="conversation?.last_message?.fb_msg_type === 'video'"
          class="fas fa-video"
        ></i>
        <i
          *ngIf="conversation?.last_message?.fb_msg_type === 'document'"
          class="fas fa-file"
        >
        </i>
        <i 
          class="fa-solid fa-location-dot"
          *ngIf="conversation?.last_message?.fb_msg_type === 'location'"
        ></i>
        <mat-icon
        class="chats-template-icon"
          *ngIf="conversation?.last_message?.fb_msg_type === 'template'"
          svgIcon="template-icon"
        ></mat-icon>
        <mat-icon
        class="chats-template-icon"
          *ngIf="conversation?.last_message?.fb_msg_type === 'contacts'"
          svgIcon="person"
        ></mat-icon>
        <i
          *ngIf="
            conversation?.last_message?.fb_msg_type ===
              'product_list' ||
            conversation?.last_message?.fb_msg_type === 'product'
          "
          class="fas fa-shopping-bag"
        >
        </i>
        <i
          *ngIf="conversation?.last_message?.fb_msg_type === 'order'"
          class="fas fa-cart-shopping"
        >
        </i>
        <mat-icon
          class="chats-unsupported-icon"
          *ngIf="conversation?.last_message?.fb_msg_type !== 'template' &&
            conversation?.last_message?.fb_msg_type !== 'image' &&
            conversation?.last_message?.fb_msg_type !== 'video' &&
            conversation?.last_message?.fb_msg_type !== 'document' &&
            conversation?.last_message?.fb_msg_type !== 'product' &&
            conversation?.last_message?.fb_msg_type !== 'order' &&
            conversation?.last_message?.fb_msg_type !== 'text' &&
            conversation?.last_message?.fb_msg_type !== 'product_list' && 
            conversation?.last_message?.fb_msg_type !== 'location' && 
            conversation?.last_message?.fb_msg_type !== 'interactive' &&
            conversation?.last_message?.fb_msg_type !== 'list' &&
            conversation?.last_message?.fb_msg_type !== 'quick_reply' &&
            conversation?.last_message?.fb_msg_type !== 'sticker' &&
            conversation?.last_message?.fb_msg_type !== 'audio' &&
            conversation?.last_message?.fb_msg_type !== 'contacts' &&
            conversation?.last_message?.fb_msg_type
          "
          svgIcon="campaign_failed_grey"
        ></mat-icon>
        <span class="chat-sinppet">{{
          getDisplayText(conversation?.last_message)
        }}</span>
      </span>
      <span
        *ngIf="getUnread(conversation?.unread_count) > 0"
        class="chat-snippet-unread-count"
        >{{ getUnread(conversation?.unread_count) <= 99 ? conversation?.unread_count : '99+' }}</span
      >
    </p>
    <div class="user-label-wrap" *ngIf=" conversation?.labels && conversation.labels.length > 0">
      <span *ngFor="let label of conversation.labels">
        <label-component [labelElement]="convertConvLabelToLabel(label)"></label-component>
      </span>
    </div>
  </div>
</div>