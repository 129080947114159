export class SubscriptionPlan {
  private _id: number;
  private _features: any;
  private _name: string;
  private _description: string;
  private _is_free: boolean;
  private _is_trial: boolean;
  private _monthly_price: number;
  private _yearly_price: number;
  private _type: string;
  private _is_recommended: boolean;
  private _conversation_rate: number;
  private _price: any;

  constructor(subscriptionJson: any) {
    this._id = subscriptionJson?.id;
    this._features = subscriptionJson?.features;
    this._name = subscriptionJson?.name;
    this._description = subscriptionJson?.description;
    this._is_free = subscriptionJson?.is_free;
    this._is_trial = subscriptionJson?.is_trial;
    this._monthly_price = subscriptionJson?.monthly_price;
    this._yearly_price = subscriptionJson?.yearly_price;
    this._type = subscriptionJson?.type;
    this._is_recommended = subscriptionJson?.is_recommended;
    this._conversation_rate = subscriptionJson?.conversation_rate;
    this._price = subscriptionJson?.price;
  }

  public get id(): number {
      return this._id;
  }
  public set id(id: number) {
      this._id = id;
  }
  public get features(): any {
      return this._features;
  }
  public set features(features: any) {
      this._features = features;
  }
  public get name(): string {
      return this._name;
  }
  public set name(name: string) {
      this._name = name;
  }
  public get description(): string {
      return this._description;
  }
  public set description(description: string) {
      this._description = description;
  }
  public get is_free(): boolean {
      return this._is_free;
  }
  public set is_free(is_free: boolean) {
      this._is_free = is_free;
  }
  public get is_trial(): boolean {
      return this._is_trial;
  }
  public set is_trial(is_trial: boolean) {
      this._is_trial = is_trial;
  }
  public get monthly_price(): number {
      return this._monthly_price;
  }
  public set monthly_price(monthly_price: number) {
      this._monthly_price = monthly_price;
  }
  public get yearly_price(): number {
      return this._yearly_price;
  }
  public set yearly_price(yearly_price: number) {
      this._yearly_price = yearly_price;
  }
  public get type(): string {
      return this._type;
  }
  public set type(type: string) {
      this._type = type;
  }
  public get is_recommended(): boolean {
      return this._is_recommended;
  }
  public set is_recommended(is_recommended: boolean) {
      this._is_recommended = is_recommended;
  }
  public get conversation_rate(): number {
      return this._conversation_rate;
  }
  public set conversation_rate(conversation_rate: number) {
      this._conversation_rate = conversation_rate;
  }
  public get price(): any {
    return this._price;
  }
  public set price(price: any) {
      this._price = price;
  }

}