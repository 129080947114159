import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatsService {
  constructor(private http: HttpClient) {}

  getConversations(params: any): Observable<any> {
    let parameters = new HttpParams().appendAll(params);
    console.log(parameters);
    return this.http.get<any>('/conversations',{
      params: parameters,
      observe: 'response',
    });
  }

  getSingleConversation(id: number) :Observable<any> {
    return this.http.get<any>('/conversations/'+ id, {
      observe: 'response',
    })
  }

  blockChat(obj: any): Observable<any> {
    return this.http.put<any>('/user/customer/block/', obj, {
      observe: 'response',
    });
  }

  getConversationHistory(id: number): Observable<any> {
    return this.http.get<any>('/conversations/history/' + id + '/', {
      observe: 'response',
    });
  }

  getTags(id: number): Observable<any> {
    return this.http.get<any>('/user/tag/' + id + '/', { observe: 'response' });
  }

  addTags(id: number, obj: any): Observable<any> {
    return this.http.post<any>('/user/tag/' + id + '/', obj, {
      observe: 'response',
    });
  }

  getConversationsMessages(id: number, page: number): Observable<any> {
    return this.http.get<any>(
      '/conversations/' + id + '/message/?page=' + page,
      { observe: 'response' }
    );
  }

  assignChat(obj: any): Observable<any> {
    return this.http.post<any>('/conversations/agent/', obj, {
      observe: 'response',
    });
  }

  assignLabel(obj: any): Observable<any> {
    return this.http.put<any>('/labels/customer/', obj, {
      observe: 'response',
    });
  }

  getNotes(id: number): Observable<any> {
    return this.http.get<any>('/conversations/private-note/' + id, {
      observe: 'response',
    });
  }

  getMedia(id: number,type: string = 'all',pageNumber: number = 1): Observable<any> {
    if(type === 'all'){
      return this.http.get<any>('/conversations/media/' + id, {
        observe: 'response',
      });
    } else return this.http.get<any>('/conversations/media/' + id+ '?type='+type+'&page='+pageNumber, {
      observe: 'response',
    });
  }

  addPrivateNote(obj: any): Observable<any> {
    return this.http.post<any>('/conversations/private-note/', obj, {
      observe: 'response',
    });
  }

  sendMessage(obj: any): Observable<any> {
    return this.http.post<any>('/conversations/message/', obj, {
      observe: 'response',
    });
  }

  getPresignUrl(obj: any): Observable<any> {
    return this.http.post<any>('/pre-sign/', obj, { observe: 'response' });
  }

  updateCustomer(id: number, obj: any): Observable<any> {
    return this.http.put<any>('/user/customer/' + id + '/', obj, {
      observe: 'response',
    });
  }

  closeConversation(obj: any): Observable<any> {
    return this.http.put<any>('/conversations/close/', obj, {
      observe: 'response',
    });
  }

  getFbProducts(pageNumber: number = 1): Observable<any> {
    return this.http.get<any>(`/commerce/products?page=${pageNumber}`, { observe: 'response' });
  }

  getItemListDetails(id: number): Observable<any> {
    return this.http.get<any>('/conversations/product-message/' + id, {
      observe: 'response',
    });
  }
  getItemDetails(retailerId:string): Observable<any> {
    return this.http.get<any>('/commerce/products/'+retailerId,{
      observe: 'response'
    });
  }

  sendPaymentLink(obj: any): Observable<any> {
    return this.http.post<any>('/conversations/order/',obj, { observe: 'response' });
  }

  searchFBProducts(searchTag: string): Observable<any> {
    return this.http.get<any>('/commerce/products?q=' + searchTag, {
      observe: 'response',
    });
  }

  searchConversations(searchTag: string,pageNumber: number): Observable<any> {
    return this.http.get<any>('/search/conversation?q='+ searchTag+'&page='+pageNumber,{
      observe: 'response',
    });
  }

  s3Upload(url: string, formData: FormData): Observable<any> {
    return this.http.post<any>(url, formData,{
      headers: {
        skip: 'true',
      },
    });
  }

  getMessageStatus(id: number) :Observable<any> {
    return this.http.get<any>('/conversations/message/status/'+ id, {
      observe: 'response',
    });
  }
}
