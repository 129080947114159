<div class="white-card" *ngIf="rateCardType === 'whatsapp'">
  <div class="downloadable-container" id="rate">
    <div class="container-heading">
      <span class="container-heading-title">{{build}} Conversation Pricing</span>
    </div>
    <table class="campaign-container-table">
      <tr class="campaign-table-heading-row" >
          <th class="campaign-table-heading" style="border-radius: 10px 0 0 0;">Country</th>
          <th class="campaign-table-heading">Marketing</th>
          <th class="campaign-table-heading">Utility</th>
          <th class="campaign-table-heading">Authentication</th>
          <th class="campaign-table-heading" style="border-radius: 0 10px 0 0;">Service</th>
      </tr>
      <tr class="campaign-table-row"  *ngFor="let rate of rateCard">
        <ng-container *ngIf="!sharedDataService.isPartnerBusiness">
          <td class="campaign-table-cell-name" style="border-radius: 0 0 0 10px;">{{rate?.country_name}}</td>
          <td class="campaign-table-cell">{{(rate?.marketing  | paiseToRupee) | currency: 4}}</td>
          <td class="campaign-table-cell">{{(rate?.utility  | paiseToRupee) | currency: 4}}</td>
          <td class="campaign-table-cell">{{(rate?.authentication  | paiseToRupee) | currency: 4}}</td>
          <td class="campaign-table-cell" style="border-radius: 0 0 10px 0;">{{(rate?.service  | paiseToRupee) | currency: 4}}</td>
        </ng-container>
        <ng-container *ngIf="sharedDataService.isPartnerBusiness">
          <td class="campaign-table-cell-name" style="border-radius: 0 0 0 10px;">{{rate?.country_name}}</td>
          <td class="campaign-table-cell">₹{{rate?.marketing}}</td>
          <td class="campaign-table-cell">₹{{rate?.utility}}</td>
          <td class="campaign-table-cell">₹{{rate?.authentication}}</td>
          <td class="campaign-table-cell" style="border-radius: 0 0 10px 0;">₹{{rate?.service}}</td>
        </ng-container>
      </tr>
    </table>
  </div>
</div>
<div class="white-card" *ngIf="rateCardType === 'call'">
  <div class="downloadable-container" id="rate">
    <div class="container-heading">
      <span class="container-heading-title">{{build}} Call Pricing</span>
    </div>
    <table class="campaign-container-table">
      <tr class="campaign-table-heading-row" >
          <th class="campaign-table-heading" style="border-radius: 10px 0 0 0;">Country</th>
          <th class="campaign-table-heading">Pulse</th>
          <th class="campaign-table-heading">Outgoing call</th>
          <th class="campaign-table-heading">Incoming call</th>
      </tr>
      <tr class="campaign-table-row"  *ngFor="let rate of callRateCard">
        <td class="campaign-table-cell-name">{{rate?.country_name}}</td>
        <td class="campaign-table-cell">{{rate?.pulse}}</td>
        <td class="campaign-table-cell">{{(rate?.inbound_rate  | paiseToRupee) | currency: 4}}</td>
        <td class="campaign-table-cell">{{(rate?.outbound_rate  | paiseToRupee) | currency: 4}}</td>
      </tr>
    </table>
  </div>
</div>