export class LastMessage {
  private _id: number;
  private _display_text: string;
  private _msg_type: string;
  private _fb_msg_type: string;
  private _sender: string;
  private _message_status: string;
  private _created_at: Date;

  constructor(lastMessageJson: any) {
    this._id = lastMessageJson?.id;
    this._display_text = lastMessageJson?.display_text;
    this._msg_type = lastMessageJson?.msg_type;
    this._fb_msg_type = lastMessageJson?.fb_msg_type;
    this._sender = lastMessageJson?.sender;
    this._message_status = lastMessageJson?.message_status;
    this._created_at = lastMessageJson?.created_at;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get display_text(): string {
    return this._display_text;
  }

  public set display_text(display_text: string) {
    this._display_text = display_text;
  }

  public get msg_type(): string {
    return this._msg_type;
  }

  public set msg_type(msgType: string) {
    this._msg_type = msgType;
  }

  public get fb_msg_type(): string {
    return this._fb_msg_type;
  }

  public set fb_msg_type(fbMsgType: string) {
    this._fb_msg_type = fbMsgType;
  }

  public get sender(): string {
    return this._sender;
  }

  public set sender(sender: string) {
    this._sender = sender;
  }

  public get message_status(): string {
    return this._message_status;
  }

  public set message_status(messageStatus: string) {
    this._message_status = messageStatus;
  }

  public get created_at(): Date {
    return this._created_at;
  }

  public set created_at(createdAt: Date) {
    this._created_at = createdAt;
  }
}

export class ConversationLabel {
  private _label_id: number;
  private _name: string;
  private _color: string;
  private _bg_color: string;

    public get bg_color(): string {
        return this._bg_color;
    }

    public set bg_color(bg_color: string) {
        this._bg_color = bg_color;
    }


  constructor(labelJson: any) {
    this._label_id = labelJson?.label_id;
    this._name = labelJson?.name;
    this._color = labelJson?.color;
    this._bg_color = labelJson?.bg_color;
  }

  public get label_id(): number {
    return this._label_id;
  }

  public set label_id(labelId: number) {
    this._label_id = labelId;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get color(): string {
    return this._color;
  }

  public set color(color: string) {
    this._color = color;
  }
}

export class Address {
  private _city: string;
  private _state: string;
  private _line_1: string;
  private _country: string;
  private _pin_code: number;
  private _line_2: string;

  constructor(addressJson: any) {
    this._line_1 = addressJson?.line_1;
    this._line_2 = addressJson?.line_2;
    this._city = addressJson?.city;
    this._state = addressJson?.state;
    this._country = addressJson?.country;
    this._pin_code = addressJson?.pin_code;
  }

  public get city(): string {
    return this._city;
  }

  public set city(city: string) {
    this._city = city;
  }

  public get state(): string {
    return this._state;
  }

  public set state(state: string) {
    this._state = state;
  }

  public get line_1(): string {
    return this._line_1;
  }

  public set line_1(line1: string) {
    this._line_1 = line1;
  }

  public get country(): string {
    return this._country;
  }

  public set country(country: string) {
    this._country = country;
  }

  public get pin_code(): number {
    return this._pin_code;
  }

  public set pin_code(pincode: number) {
    this._pin_code = pincode;
  }

  public get line_2(): string {
    return this._line_2;
  }

  public set line_2(line2: string) {
    this._line_2 = line2;
  }
}

export class Customer {
  private _id: number;
  private _name: string;
  private _mobile: string;
  private _email: string;
  private _address: Address[];
  private _date_of_birth: string;
  private _gender: number;
  private _whatsapp_name: string;

  constructor(customerJson: any) {
    this._id = customerJson?.id;
    this._name = customerJson?.name;
    this._mobile = customerJson?.mobile;
    this._email = customerJson?.email;
    this._address = [];
    customerJson?.address?.forEach((addressElement: any) => {
      this._address.push(new Address(addressElement));
    });
    this._date_of_birth = customerJson?.date_of_birth;
    this._gender = customerJson?.gender;
    this._whatsapp_name = customerJson?.whatsapp_name;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get mobile(): string {
    return this._mobile;
  }

  public set mobile(mobile: string) {
    this._mobile = mobile;
  }

  public get email(): string {
    return this._email;
  }

  public set email(email: string) {
    this._email = email;
  }

  public get address(): Address[] {
    return this._address;
  }

  public set address(address: Address[]) {
    address?.forEach((addressElement: any) => {
      this._address.push(new Address(addressElement));
    });
  }

  public get date_of_birth(): string {
    return this._date_of_birth;
  }

  public set date_of_birth(_date_of_birth: string) {
    this._date_of_birth = _date_of_birth;
  }

  public get gender(): number {
    return this._gender;
  }

  public set gender(gender: number) {
    this._gender = gender;
  }

  public get whatsapp_name(): string {
    return this._whatsapp_name;
  }

  public set whatsapp_name(whatsapp_name: string) {
    this._whatsapp_name = whatsapp_name;
  }
}

export class Assignee {
  private _name: string;
  private _id: number;

  constructor(assgineeJson: any) {
    this._id = assgineeJson?.id;
    this._name = assgineeJson?.name;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }
}

export class Conversation {
  private _id: number;
  private _last_message: LastMessage;
  private _labels: ConversationLabel[];
  private _customer: Customer;
  private _expire_on: Date;
  private _unread_count: number;
  private _assignee: Assignee;
  private _last_customer_message_at: Date;
  private _last_message_at: Date;
  private _status: string;
  private _created_at: Date;
  private _closed_on: Date;
  private _contact_id: number;
  private _fbBusinessAccount: string;

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get last_message(): LastMessage {
    return this._last_message;
  }

  public set last_message(_last_message: LastMessage) {
    this._last_message = new LastMessage(_last_message);
  }

  public get labels(): ConversationLabel[] {
    return this._labels;
  }

  public set labels(labels: ConversationLabel[]) {
    labels?.forEach((label: any) => {
      this._labels.push(new ConversationLabel(label));
    });
  }

  public get customer(): Customer {
    return this._customer;
  }

  public set customer(customer: Customer) {
    this._customer = new Customer(customer);
  }

  public get expire_on(): Date {
    return this._expire_on;
  }

  public set expire_on(_expire_on: Date) {
    this._expire_on = _expire_on;
  }

  public get unread_count(): number {
    return this._unread_count;
  }

  public set unread_count(_unread_count: number) {
    this._unread_count = _unread_count;
  }

  public get assignee(): Assignee {
    return this._assignee;
  }

  public set assignee(assignee: Assignee) {
    this._assignee = new Assignee(assignee);
  }

  public get last_customer_message_at(): Date {
    return this._last_customer_message_at;
  }

  public set last_customer_message_at(_last_customer_message_at: Date) {
    this._last_customer_message_at = _last_customer_message_at;
  }

  public get last_message_at(): Date {
    return this._last_message_at;
  }

  public set last_message_at(_last_message_at: Date) {
    this._last_message_at = _last_message_at;
  }

  public get status(): string {
    return this._status;
  }

  public set status(status: string) {
    this._status = status;
  }

  public get created_at(): Date {
    return this._created_at;
  }

  public set created_at(createdAt: Date) {
    this._created_at = createdAt;
  }

  public get closed_on(): Date {
    return this._closed_on;
  }

  public set closed_on(closedOn: Date) {
    this._closed_on = closedOn;
  }

  public get contact_id(): number {
    return this._contact_id;
  }

  public set contact_id(contactId: number) {
    this._contact_id = contactId;
  }

  public get fbBusinessAccount(): string {
    return this._fbBusinessAccount;
  }

  public set fbBusinessAccount(fbBusinessAccount: string) {
    this._fbBusinessAccount = fbBusinessAccount;
  }

  constructor(conversationJson: any) {
    this._id = conversationJson?.id;
    this._last_message = new LastMessage(conversationJson?.last_message);
    this._labels = [];
    conversationJson?.labels?.forEach((labelElement: any) => {
      this._labels.push(new ConversationLabel(labelElement));
    });
    this._customer = new Customer(conversationJson?.customer);
    this._assignee = new Assignee(conversationJson?.assignee);
    this._expire_on = conversationJson?.expire_on;
    this._unread_count = conversationJson?.unread_count ? conversationJson?.unread_count : 0;
    this._last_customer_message_at = conversationJson?.last_customer_message_at;
    this._last_message_at = conversationJson?.last_message_at;
    this._status = conversationJson?.status;
    this._created_at = conversationJson?.created_at;
    this._closed_on = conversationJson?.closed_on;
    this._contact_id = conversationJson?.contact_id;
    this._fbBusinessAccount = conversationJson?.fbBusinessAccount;
  }
}