<div class="time-container">
  <div class="dropdown from-age-dropdown">
    <button
      class="btn btn-custom-dropdown dropdown-toggle"
      type="button"
      id="hours"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="fa-regular fa-clock"></i>
      <div class="time">
        <span>{{hours}}</span>
        <span class="colon">:</span>
        <span>{{minutes}}</span> 
        <span>{{ampm}}</span>
      </div>
    </button>
    <ul
      class="dropdown-menu audience-custom-dropdown-menu"
      aria-labelledby="ampm"
      (click)="$event.stopPropagation()"
    >
      <div class="white-card">
        <div class="hours-minutes-container">
          <div class="hours-spinner">
            <span style="cursor: pointer;" (click)="decreaseHours()"><i class="fa-solid fa-angle-up"></i></span>
            <span>
              <span class="number-value">{{hours}}</span>
              <!-- <input type="number" [(ngModel)]="hours"> -->
            </span>
            <span style="cursor: pointer;" (click)="increaseHours()"><i class="fa-solid fa-angle-down"></i></span>
          </div>
          <div class="hours-spinner">
            <span style="cursor: pointer;" (click)="decreaseMinutes()"><i class="fa-solid fa-angle-up"></i></span>
            <span>
              <span class="number-value">{{minutes}}</span>
              <!-- <input type="number" [(ngModel)]="minutes"> -->
            </span>
            <span style="cursor: pointer;" (click)="increaseMinutes()"><i class="fa-solid fa-angle-down"></i></span>
          </div>
        </div>
        <div class="am-pm-container">
          <save-button *ngIf="ampm === 'AM'" [saveButtonText]="'AM'"></save-button>
          <cancel-button *ngIf="ampm === 'PM'" [cancelButtonText]="'AM'" (cancelClicked)="ampm = 'AM'; emitTime()"></cancel-button>
          <save-button *ngIf="ampm === 'PM'" [saveButtonText]="'PM'"></save-button>
          <cancel-button *ngIf="ampm === 'AM'" [cancelButtonText]="'PM'" (cancelClicked)="ampm = 'PM'; emitTime()"></cancel-button>
        </div>
      </div>
    </ul>
  </div>
</div>