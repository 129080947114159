import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Customer } from '../models/customer';
import { CloudNumber } from '../models/cloud-number';

@Injectable({
  providedIn: 'root'
})
export class VoiceService {

  makeCall = new BehaviorSubject<any>([]);
  viewedCloudNumber!: CloudNumber;

  constructor(private http: HttpClient) { }

  callCustomer(customer: Customer) {
    this.makeCall.next(customer);
  }

  placeCall(obj: any): Observable<any> {
    return this.http.post<any>('/channel/voice/phone-call',obj, {
      observe: 'response'
    })
  }

  getCallConversations(id: number,params: Params): Observable<any> {
    return this.http.get<any>('/conversations/call/'+id, {
      observe: 'response',
      params: params,
    })
  }

  getCallLogs(id: number, page: number): Observable<any> {
    return this.http.get<any>('/channel/voice/call-log/'+id, {
      observe: 'response',
      params: {
        page: page,
      }
    })
  }

  getSingleCallLog(inbox_id: number, id: number): Observable<any> {
    return this.http.get<any>('/channel/voice/'+inbox_id+'/call-log/'+id+'/detail', {
      observe: 'response',
    })
  }

  getInboxes(): Observable<any> {
    return this.http.get<any>('/channel/voice/inbox', {
      observe: 'response'
    })
  }

  updateInbox(obj: any): Observable<any> {
    return this.http.put<any>('/channel/voice/inbox', obj, {
      observe: 'response'
    })
  }

  killCall(obj: any): Observable<any> {
    return this.http.put<any>('/channel/voice/phone-call/kill',obj, {
      observe: 'response'
    })
  }

  getNewCloudNumber(page: number,state: string = 'All'): Observable<any> {
    if(state === 'All') {
      return this.http.get<any>('/channel/voice/cloud-number', {
        observe: 'response',
        params: {
          page: page,
        }
      })
    } else {
      return this.http.get<any>('/channel/voice/cloud-number', {
        observe: 'response',
        params: {
          page: page,
          q: state,
        }
      })
    }
  }
  
  buyCloudNumber(obj: any): Observable<any> {
    return this.http.post<any>('/channel/voice/inbox',obj, {
      observe: 'response'
    })
  }

  getCloudNumberAssignees(id: number,page: number= 1, status: string = 'all', search: string = ''): Observable<any> {
    let params :Record<string,any> = {}
    if(status !== 'all') params['status'] = status;
    if(search !== '') params['q'] = search;
    return this.http.get<any>('/channel/voice/cloud-number/assign/'+id, {
      observe: 'response',
      params: params,
    })
  }

  assignToCloudNumber(obj: any): Observable<any> {
    return this.http.post<any>('/channel/voice/cloud-number/assign',obj, {
      observe: 'response'
    })
  }

  deleteCloudNumber(obj: any): Observable<any> {
    return this.http.patch<any>('/channel/voice/inbox', obj, {
      observe: 'response'
    })
  }

  assignLabelToCall(obj: any): Observable<any> {
    return this.http.put<any>('/labels/call', obj, {
      observe: 'response'
    })
  }

  holdCall(obj: any): Observable<any> {
    return this.http.put<any>('/channel/voice/phone-call/hold', obj, {
      observe: 'response'
    })
  }

  muteCall(obj: any): Observable<any> {
    return this.http.put<any>('/channel/voice/phone-call/mute', obj, {
      observe: 'response'
    })
  }

  addCallLogNote(id: number,obj: any): Observable<any> {
    return this.http.post<any>('/note/'+id,obj, {
      observe: 'response'
    })
  }

  transferCall(inbox: number, obj: any): Observable<any> {
    return this.http.post<any>('/channel/voice/call/transfer/'+inbox,obj, {
      observe: 'response'
    })
  }

  getTunes(): Observable<any> {
    return this.http.get<any>('/channel/voice/tune', {
      observe: 'response'
    })
  }
}
