import { Template } from 'src/app/models/template';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';

import { CommonService } from 'src/app/services/common.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalEnums, TemplateCategories, TemplateTypes } from 'src/app/constants';

@Component({
  selector: 'template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.css']
})
export class TemplateListComponent implements OnInit {

  @Input() selectedTemplate!: Template;
  @Output() cancelClicked = new EventEmitter<boolean>();
  @Output() saveClicked = new EventEmitter<Template>();
  loaderTemplateList: boolean = false;
  loaderTemplatePagination: boolean = false;
  templatesList: Template[] = [];
  templatePageNumber = 1;
  templatePageNext = true;
  isSearchTemplate: boolean = false;
  templateSearchTag: string = '';
  prevTemplateSearchTag: string = '';
  enums = TemplateCategories;
  templateTypes = TemplateTypes
  constructor(
    private templateService: TemplateService,
    private commonService: CommonService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.matIconRegistry.addSvgIcon(
      'image_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/image_icon.svg'
      )
    ); 
    this.matIconRegistry.addSvgIcon(
      'document_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/document_grey.svg'
      )
    ); 
    this.matIconRegistry.addSvgIcon(
      'video_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/video_grey.svg'
      )
    ); 
    this.matIconRegistry.addSvgIcon(
      'marketing_template_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/marketing_template_grey.svg'
      )
    ); 
    this.matIconRegistry.addSvgIcon(
      'products_light',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/products_light.svg'
      )
    ); 
    this.matIconRegistry.addSvgIcon(
      'notes',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/notes.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'location_light',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/location_light.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'percent',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/percent.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'label_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/label_grey.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'catalog',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/catalog.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'products_light',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/products_light.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'message_light',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/message_light.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'carousel',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/carousel.svg'
      )
    );
  }

  ngOnInit(): void {
    this.getTemplateList(1);
  }

  focusSearch() {
    setTimeout(() => {
      var currentInput = document.getElementById('popup-search');
      if(currentInput){
        var childInput = currentInput.querySelector('input');
        if (childInput) {
            childInput.focus();
          }
        }
      }, 10);
  }

  getTemplateList(pageNumber: number){
    if(pageNumber === 1) this.loaderTemplateList = true;
    else this.loaderTemplatePagination = true;
    let response = this.templateService.getTemplate(1,pageNumber);
    response.subscribe((resp)=>{
      try {
        if(resp.body.code === 200) {
          if(resp.body.next) this.templatePageNext = true;
          else this.templatePageNext = false;
          if(pageNumber === 1) this.templatesList = [];
          for (var index = 0; index < resp.body.data?.length; index++) {
            this.templatesList.push(new Template(resp.body.data[index]));
          }
          this.loaderTemplateList = false;
          this.loaderTemplatePagination = false;
          this.templatePageNumber++;
        } else {
          console.log(resp.body.message);
          this.loaderTemplateList = false;
          this.loaderTemplatePagination = false;
        }
      } catch (exception) {
      }
    },(error)=>{
      console.log(error);
      this.loaderTemplateList = false;
      this.loaderTemplatePagination = false;
    });
  }

  onTemplateListScroll(event: any) {
    if (
      event.target.scrollHeight -
        event.target.offsetHeight -
        event.target.scrollTop <
      50
    ) {
      if (this.templatePageNext && !this.loaderTemplatePagination) {
        this.getTemplateList(this.templatePageNumber);
      }
    }
  }

  getSearchedTeplates(pageNumber:number) {
    this.loaderTemplatePagination = true;
    let response = this.templateService.searchTemplate(this.templateSearchTag,pageNumber,1);
    response.subscribe((resp)=>{
      try {
        if(resp.body.code === 200) {
          if(resp.body.next) this.templatePageNext = true;
          else this.templatePageNext = false;
          if(pageNumber === 1) this.templatesList = [];
          for (var index = 0; index < resp.body.data?.length; index++) {
            this.templatesList.push(new Template(resp.body.data[index]));
          }
          this.loaderTemplateList = false;
          this.loaderTemplatePagination = false;
          this.templatePageNumber++;
        }
      } catch (exception) {
      }
    },(error)=>{
      console.log(error);
      this.loaderTemplateList = false;
      this.loaderTemplatePagination = false;
    });
  }
  
  searchTemplates(event: any){
    setTimeout(() => {
      if(this.prevTemplateSearchTag !== this.templateSearchTag && this.templateSearchTag?.length > 2) {
        this.templatePageNumber = 1;
        this.templatePageNext = true;
        this.prevTemplateSearchTag = this.templateSearchTag;
        this.getSearchedTeplates(1);
      } else if(this.prevTemplateSearchTag !== this.templateSearchTag && this.prevTemplateSearchTag?.length > 2) {
        this.templatePageNumber = 1;
        this.templatePageNext = true;
        this.prevTemplateSearchTag = this.templateSearchTag;
        this.getTemplateList(1);
      }
    }, 300);
  }

  isTemplateChecked(template: Template) {
    if(this.selectedTemplate?.id === template?.id) return true;
    else return false;
  }

  getFileType(filename: string | undefined) {
    return this.commonService.getStringAfterDot(filename);
  }

  selectTemplate(){
    this.saveClicked.emit(this.selectedTemplate);
  }
}
