import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './components/shared/page-not-found/page-not-found.component';
import { AuthGuard } from './helpers/authGuard';

//TODO Add resolver to login so that the login module won't load when token is available
const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./components/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'customers',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/customers/customers.module').then((m) => m.CustomersModule),
  },
  {
    path: 'campaigns',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/campaigns/campaigns.module').then((m) => m.CampaignsModule),
  },
  {
    path: 'templates',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/templates/templates.module').then((m) => m.TemplatesModule),
  },
  {
    path: 'chats',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/chats/chats.module').then((m) => m.ChatsModule),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  // {
  //   path: 'calls',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./components/calls/calls.module').then(
  //       (m) => m.CallsModule
  //     ),
  // },
  {
    path: 'analytics',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/analytics/analytics.module').then((m) => m.AnalyticsModule),
  },
  // {
  //   path: 'store',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./components/store/store.module').then((m) => m.StoreModule),
  // },
  {
    path: 'user',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/user/user.module').then((m) => m.UserModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
