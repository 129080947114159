import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { error } from 'console';
import { GlobalEnums, GlobalStrings } from 'src/app/constants';
import { TeamMember } from 'src/app/models/team-member';
import { ToasterService } from 'src/app/services/toastr.service';
import { VoiceService } from 'src/app/services/voice.service';
import { WebSocketService } from 'src/app/services/web-socket.service';

@Component({
  selector: 'transfer-call',
  templateUrl: './transfer-call.component.html',
  styleUrls: ['./transfer-call.component.css']
})
export class TransferCallComponent implements OnInit {
  
  @Input() inboxId!: number;
  @Input() conferenceId!: string;
  @Input() customerCallSid!: string;
  @Output() close = new EventEmitter<boolean>();
  @Output() transfer = new EventEmitter<any>();
  searchEmptyList = GlobalStrings.searchEmptyList
  teamMemberList: TeamMember[] = [];
  teamMemberSearchTag: string = '';
  prevTeamMemberSearchTag: string = '';
  loaderTeamMember: boolean = false;
  loaderTeamMemberPagination: boolean = false;
  teamMemberPageNext: boolean = true;
  teamMemberPageNumber: number = 1;
  selectedTeamMember!: TeamMember;
  loaderTransfer: boolean = false;
  transferType: string = '';
  transferState: string = 'dialing';
  callStatus!: string;
  transferSuccess: any;

  constructor(
    private _voiceService: VoiceService,
    private _toastr: ToasterService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private websockService: WebSocketService,
  ) {
    this.matIconRegistry.addSvgIcon(
      'pic_background',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/pic_background.svg'
      )
    );
  }
  
  ngOnInit(): void {
    this.teamMemberPageNumber = 1;
    this.teamMemberPageNext = true;
    this.getTeamMember(1);

    this.websockService.callEvents.subscribe((data) => {
      this.updateCall(data);
    })
  }

  getTeamMember(pageNumber: number){
    if(pageNumber === 1) {
      this.loaderTeamMember = true;
      this.loaderTeamMemberPagination = true;
    }
    else this.loaderTeamMemberPagination = true;
    let response = this._voiceService.getCloudNumberAssignees(this.inboxId, pageNumber, GlobalEnums.available);
    response.subscribe({
      next: (resp)=>{
      try {
        if(resp.body.code === 200) {
          if(resp.body.next) this.teamMemberPageNext = true;
          else this.teamMemberPageNext = false;
          if(pageNumber === 1) this.teamMemberList = [];
          for (var index = 0; index < resp.body.data?.length; index++) {
            if(resp.body.data[index]?.user_type === 1) {
              this.teamMemberList.unshift(new TeamMember(resp.body.data[index]?.user));
            }
            else this.teamMemberList.push(new TeamMember(resp.body.data[index]?.user));
          }
          this.loaderTeamMember = false;
          this.loaderTeamMemberPagination = false;
          this.teamMemberPageNumber++;
        }
      } catch (exception) {
      }
    },error: (error)=>{
      console.log(error);
      this.loaderTeamMember = false;
      this.loaderTeamMemberPagination = false;
    }
    });
  }
  onTeamMemberScroll(event: any) {
    if (
      event.target.scrollHeight -
        event.target.offsetHeight -
        event.target.scrollTop <
      50
    ) {
      if (this.teamMemberPageNext && !this.loaderTeamMemberPagination) {
        this.getTeamMember(this.teamMemberPageNumber);
      }
    }
  }

  getSearchedTeamMembers(pageNumber:number) {
    this.loaderTeamMemberPagination = true;
    let response = this._voiceService.getCloudNumberAssignees(this.inboxId, pageNumber, GlobalEnums.available, this.teamMemberSearchTag);
    response.subscribe({
      next: (resp)=>{
      try {
        if(resp.body.code === 200) {
          if(resp.body.next) this.teamMemberPageNext = true;
          else this.teamMemberPageNext = false;
          if(pageNumber === 1) this.teamMemberList = [];
          for (var index = 0; index < resp.body.data?.length; index++) {
            this.teamMemberList.push(new TeamMember(resp.body.data[index]?.user));
          }
          this.loaderTeamMember = false;
          this.loaderTeamMemberPagination = false;
          this.teamMemberPageNumber++;
        }
      } catch (exception) {
      }
    },error: (error)=>{
      console.log(error);
      this.loaderTeamMember = false;
      this.loaderTeamMemberPagination = false;
    }});
  }
  
  searchTeamMembers(event: any){
    setTimeout(() => {
      if(this.prevTeamMemberSearchTag !== this.teamMemberSearchTag && this.teamMemberSearchTag?.length > 2) {
        this.teamMemberPageNumber = 1;
        this.teamMemberPageNext = true;
        this.prevTeamMemberSearchTag = this.teamMemberSearchTag;
        this.getSearchedTeamMembers(1);
      } else if(this.prevTeamMemberSearchTag !== this.teamMemberSearchTag && this.prevTeamMemberSearchTag?.length > 2) {
        this.teamMemberPageNumber = 1;
        this.teamMemberPageNext = true;
        this.prevTeamMemberSearchTag = this.teamMemberSearchTag;
        this.getTeamMember(1);
      }
    }, 300);
  }

  selectTeamMember(member: TeamMember){
    this.selectedTeamMember = member;
    console.log(this.selectedTeamMember);
  }

  askFirst() {
    this.transferType = GlobalEnums.talk_first;
    this.transferCall();
  }
  transferNow() {
    this.transferType = GlobalEnums.tran_now;
    this.transferCall();
  }

  transferCall() {
    this.loaderTransfer = true;
    let input = {
      type_of_transfer: this.transferType,
      call_conf_room_id: this.conferenceId,
      agent_id: this.selectedTeamMember?.id,
      customer_call_sid: this.customerCallSid
    }
    this._voiceService.transferCall(this.inboxId,input).subscribe({
      next: resp => {
        this.transferState = 'in-call';
        this.callStatus = 'calling...';
        this.transferSuccess = resp.body.data;
        let tranferEvent = {
          type: this.transferType,
        }
        this.transfer.emit(tranferEvent);
      }, error: error => {
        console.log(error);
        this._toastr.error(error);
      }
    })
  }

  cutCall() {
    this.loaderTransfer = true;
    let input = {
      call_conf_room_id: this.conferenceId,
      call_sid: this.transferSuccess?.sid, 
    }
    this._voiceService.killCall(input).subscribe({
      next: resp => {
        if (resp.body?.code == 200) {
          this.close.emit(true);
        } else {
          console.log(resp.body.message);
          this._toastr.error(resp.body.message);
        }
        this.loaderTransfer = false;
      }, error: error => {
        console.log(error);
        this._toastr.error(error);
        this.loaderTransfer = false;
      }
    })
  }
  transferUser() {
    let tranferEvent = {
      type: 'user',
      sid: this.transferSuccess?.sid,
      name: this.selectedTeamMember?.name,
      number: this.selectedTeamMember?.mobile,
      status: 'in-progress',
      id: this.selectedTeamMember?.id
    }
    this.transfer.emit(tranferEvent);
  }

  updateCall(data: any) {
    if(data?.sid === this.transferSuccess?.sid) {
      this.callStatus = data.event;
    }
  }
}
