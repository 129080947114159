import { Injectable } from '@angular/core';
import { AppSettings } from './app.settings';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalStrings {
  public static chats: string = 'Chats';
  public static customers: string = 'Contacts';
  public static campaign: string = 'Campaigns';
  public static settings: string = 'Settings';
  public static marketing: string = 'marketing';
  public static Marketing: string = 'Marketing';
  public static Save: string = 'Save';
  public static Cancel: string = 'Cancel';
  public static Edit: string = 'Edit';
  public static runCampaign: string = 'Run Campaign';
  public static Delete: string = 'Delete';
  public static createCampaignButton: string = '+ Create campaign'; 

  /* Note strings*/
  public static agentNoteTeamMember: string =
    'Find below List of your Team Members. Contact Admin if you want to Edit your Name or Email Address.';
  public static adminNoteTeamMember: string =
    'Add, edit, delete your Team Members from here. Newly added members will get OTP on WhatsApp for verification.';
  public static agentNoteLabel: string =
    'Find below list of all available Labels. Please contact Admin if you want to Add New Label.';
  public static adminNoteLabel: string =
    'Each label should have unique name and colour. To add new Label click on + button above.';
  public static agentNoteCampaign: string =
    'Find below List of Complete Campaigns, Contact Admin to create New Campaign.';
  public static adminNoteCampaign: string =
    'Find below list of completed campaigns. To create a new campaign click on +New Campaign button';
  public static agentNoteTempate: string =
    'Find below list of templates either Approved, In Review or Rejected. Please contact Admin if you want to add New template.';
  public static adminNoteTemplate: string =
    'Find below list of templates either Approved, In Review or Rejected. To add new template click on +New Template button';

  public welcomeMessageDescription: string = 'When a new user opens a chat with you on WhatsApp for the first time, send an automated welcome message.';
  public iceBreakerDescription: string = 'Set tappable text that appear in a message thread the first time you chat with a user.';

    public static addBulkCustomersDescription1: string =
    '1. You can download the template and fill it with your data and upload that file below to upload bulk contacts.';
  public static addBulkCustomersDescription2: string =
    '2. Upload only 5,000 contacts at a time in excel.';
  public static addBulkCustomersDescription3: string =
    '3. Only .xlsx file is allowed, if your file is .xls, convert it to .xlsx before uploading.';

  public static addAudienceSetNote: string = 
    'Only those contacts whose labels and contact profile are updated will be added in audiences.';
  public static campaignAudienceNote: string = 'Your campaign will be send to these audiences.';
  public static campaignTemplateNote: string = 'Your selected message template for campaign';
  public static campaignScheduleNote: string = ' Specify the exact time when campaigns can start sending messages.';
  public static campaingBudgetNote: string = 'This is approximate cost calculated based on selected template and number of contacts. It may vary based on delivery status of messages.'
  public static campaignCreateAudienceNote: string = 'Define who you want to send your campaign message.';
  public static campaignCreateTemplateNote: string = 'Your selected message template for campaign';
  public static campaignPriceScenarios: string = 
  'UIC : User Initiated Conversation - ₹ 0.2906\nBIC : Business Initiated conversation - ₹ 0.4843\nAds that click to WhatsApp -Free\nFacebook Page call-to-action button - Free';
  public static templateCreateCategoryNote: string = 'Choose a message template category';
  public static templateCreateLanguageNote: string = 'Choose language for your message template.';
  public static templateCreateHeaderNote: string = 'Add a title or choose which type of media you’ll use for this header.';
  public static templateCreateMessageNote: string = 'Enter the text for your message';
  public static templateCreateFooterNote: string = 'Add a short line of text to the bottom of your message template.';
  public static templateCreateButtonNote: string = 'Create buttons that let contacts respond to your message or take action.';
  public static templateTransactionalNote: string = 'Messages about specific transaction, account, order or contact request.';
  public static templateMarketingNote: string = 'Promotions or information about your business, product or service. ';
  public static templateOtpNote: string = 'Send codes that allow your contacts to access their accounts';

  public static chatExpireNote: string = 'The contact has not spoken to you in the last 24 hours. You can only send pre-approved templates.';
  public static bulkCustomerNote1: string = 'Add contacts up to 5,00,000 in bulk by uploading a .xlsx or .csv file ';
  public static bulkCustomerNote2: string = 'Final details before importing the contacts';
  public static qualityHeadingNote: string = 'WhatsApp Business phone number\'s quality rating';
  public static qualityBodyNote: string = 'Your WhatsApp Business phone number\'s (+'+(JSON.parse(localStorage.getItem('business')!)?.waba_number)+') quality rating is a rating based on the recent messages  that your customers have received over the past seven days. This rating is determined by the feedback from your customers, such as the recent blocks, your phone number and other reporting issues.'
  public static qualityTypeNote: string = 'The Quality rating bar displays the quality states, including:';

  public subscriptionExpiryHeading: string = 'Your plan has expired!';
  public subscriptionExpiryMessage: string = 'Your plan has expired. Please renew or upgrade to stay connected with the contacts.';
  public subscriptionConversationsNote: string = 'The Meta conversation-based pricing fee varies by use case and is charged in 24-hour increments that start when the first message from a business is delivered. Conversations are categorized with one of the following categories:';
  public subscriptionMarketingDescription: string = 'Enables you to achieve a wide range of goals, from generating awareness to driving sales and retargeting customers. Examples include new product, service, or feature announcements, targeted promotions/offers, and cart abandonment reminders.';
  public subscriptionUtilityDescription: string = 'Enables you to follow-up on user actions or requests. Examples include opt-in confirmation, order/delivery management (e.g., delivery update); account updates or alerts (e.g., payment reminder); or feedback surveys.';
  public subscriptionServiceDescription: string = 'Any user-initiated conversation, such as a contact support inquiry. These conversations are started when a business responds to an inbound message from a user with a non-templated (i.e. free form) response. The first 1,000 service conversations are free.';
  public subscriptionAuthenticationDescription: string = 'Enables you authenticate users with one-time passcodes, potentially at multiple steps in the login process (e.g., account verification, account recovery, integrity challenges).';
  public subscriptionConversationsNote2: string = 'Marketing, utility, and authentication conversations can only be opened with template messages. Service messages can only be opened with free-form messages.';

  public static dynamicAudienceSetNote: string = 'Dynamic : Contacts that currently exist and those that will be added in the future based on the filters you define will automatically added to this set.';
  public static staticAudienceSetNote: string = 'Static : Contacts are manually added to this set based on specified filters, and the size of the set remains fixed.';
  public static analyticsHeading: string = 'Here is an update on your store.';

  public partnerCampaignBudgetNotes: string[] = [
    'These are the charges you will directly settle to Meta',
    'Per conversation fee charged by '+environment.configuration+''
  ];
  public partnerSubscriptionWalletNote: string = 'Subscription credits are calculated by amount paid for subscription / number of conversations allocated';

  public userInactivationMessage: string = 'Inactivated user will temporary removed from Sobot. All chats assigned to the inactivated user will be automatically unassigned. If needed, you can reactivate the user at any time';

  /* Confirmation messages */
  public static deleteAudienceSet: string = 'Are you sure you want to delete this Contact Set ?';
  public static deleteTeamMemberMessage: string = 'Any conversations assigned to this user will be unassigned. Are you sure you want to mark this user as Inactive ?';
  public static deleteLabelMessage: string = 'Deleting label will also remove all users from Contact set where it is used. Are you sure you want to delete label?';
  public static deleteTemplateMessage: string = 'Are you sure you want to delete this Template ?';
  public static logoutMessage: string = 'Are you sure you want to Logout ?';
  public static closeChatMessage: string = 'Are you sure you want to mark this conversation as Closed conversation?';
  public static blockChatMessage: string = 'Message from this contact will not be delivered to you until you Unblock the Contact. Are you sure you want to Block the number?';
  public static cancelCampaignConfirmMsg: string = 'Confirm Stop Campaign';
  public static deleteAddressConfirmMsg: string = 'Are you sure you want to delete this address ?';
  public deleteCloudNumberMsg: string = 'By deleting your phone number, you will loose access to all calls from this phone numbers';
  public waitMusicMessage: string = 'Customize this phone number’s music while a call is on hold or in a queue';

  /* Regex */
  public static websiteRegex: RegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
  public static templateVariableRegex: string = '{{[0-9][0-9]*}}';
  public static templateNewLineRegex: string = '\n\n\n'
  public static newUrlRegex: RegExp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/;
  public static boldRegex: RegExp = /(?:^|\s)(\*)([^\s](?:(?=(\\?))\3.)*?[^\s])\*(?:\s|$)/g;
  public static italicRegex: RegExp = /(?:^|\s)(_)([^_\s](?:(?=(\\?))\3.)*?[^_\s])_(?:\s|$)/g;
  public static strikeRegex: RegExp = /(?:^|\s)(~)(~?[^~\s](?:(?=(\\?))\3.)*?[^~\s])~(?:\s|$)/g;
  public static emailRegex: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static templateNameRegex: RegExp = /^[A-Za-z0-9_-]+$/;


  public static sampleExcelUrl: string = 'https://sobot-assets.s3.ap-south-1.amazonaws.com/demo/excel/demo_customer_data.xlsx';
  public static countryCodeApiEndpoint: string = 'https://restcountries.com/v2/all?fields=name,callingCodes,flag';

  /* Document Mime Types */
  public static pdf_mime_type: string = 'application/pdf';
  public static docx_mime_type: string =
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  public static pptx_mime_type: string =
    'application/vnd.openxmlformats-officedocument.presentationml.presentation';
  public static xlsx_mime_type: string =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  public static mp4_mime_type: string = 'video/mp4';
  public static _3gp_mime_type: string = 'video/3gpp';
  
  
  /* ASCII */
  public static rupee: string = '₹';
  public static dollar: string = '$';
  
  
  /* Error messages */
  public static campaignSchedulePastTime: string = 'Please select date /time greater than current time';
  public static templateVariablesNumericOrder: string = 'Entered variables must be in numeric order';
  public static templateEmptyExampleValues: string = 'Enter example values for all message variables';
  public static templateConsecutiveVariable: string = 'Make sure template body doesn\'t have consecutive variables.';
  public static templateStartsWithVariable: string = 'Template body can not start or end with variable.';
  public static templateBodyMultipleEnter: string = 'Templates body can not have double paragraph spaces.';
  public static templateImageType: string = 'Only jpeg, jpg & png files are allowed';
  public static templateDocumentType: string = 'Only pdf files are allowed';
  public static templateVideoType: string = 'Only mp4 & 3gp files are allowed';
  public static mediaImageSize: string = 'Please upload images less than 5MB';
  public static mediaVideoSize: string = 'Please upload video less than 16MB';
  public static mediaAudioSize: string = 'Please upload audio less than 16MB';
  public static mediaDocumentSize: string = 'Please upload document less than 100MB';
  public static templateMoreThan16Variables: string = 'Only upto 16 variables are allowed.'
  public static templateMoreThan1Variable: string = 'Template header cannot have more than 1 variables.'
  public invalidLoginNumber: string = 'Invalid WhatsApp number.'
  public invalidOtp: string = 'Invalid  or incorrect OTP.';
  public static unsupportedMessageType: string = 'Unsupported message.';
  public emptyDynamicVariablesMessage: string = 'If variable data is not available for the particular contact then message will not be delivered to that individual. ';
  //Map Api key
  public static dev_maps_api_key: string = 'AIzaSyBkb_j-IGeapA-J1AVf6DJ09eHDXusEzlU';

  //Empty screen messages
  public filterEmptyList: string = 'Try changing the filters or search term';
  public static searchEmptyList: string = 'No data found for this keyword.';
  public static emptyMyChatsMessage: string = 'Messages sent by contacts will appear here';
  public static emptyAssignedChatsMessage: string = 'Conversations assigned to team member will appear here';
  public static blankCustomerMessage: string = 'You can manually add or upload a bulk of contacts and start communicating with them through '+environment.configuration+'.';
  public static emptyTeamMemberMessage: string = 'All your team members will be visible here';
  public static blankCampaignMessage: string = 'Campaign are marketing or promotional way to deliver relevant and valuable messages to customers. Use campaigns to reach and engage customers, drive sales, and build brand awareness.';
  public static blankAudienceSetMessage: string = 'An contact set is a group of contacts that share common characteristics or attributes and are targeted for a specific marketing campaign.';
  public static blankApprovedTemplateMessage: string = 'The templates are a pre-written and pre-approved message format that you can use to communicate with your customers through the '+environment.configuration+'.';
  public static emptyInReviewTemplateMessage: string = 'All In-Review templates will be visible here, you can add template by clicking on "+ Create Template"';
  public static emptyRejectedTemplateMessage: string = 'All Rejected templates will be visible here, you can add template by clicking on "+ Create  Template"';
  public static blankLabelsMessage: string = 'Labels can help you to categorize and prioritize chats, and ensure that each chat is routed to the appropriate team member with the necessary expertise to handle the inquiry. ';
  public static blankLabelHeading: string = 'Create and manage your labels';
  public static blankCampaignHeading: string = 'Create and manage your campaigns';
  public static blankAudienceSetHeading: string = 'Create and manage your contact sets';
  public static blankCustomerHeading: string = 'Add and manage your contacts';
  public static blankTemplateHeading: string = 'Create and manage your templates';
  public blankStoreHeading: string = 'Connect your store';
  public blankStoreMessage: string = 'You can connect your existing e-commerce store and sync all your products to '+environment.configuration+'.';
  public blankProductsHeading: string = 'Add and manage your products';
  public blankProductsMessage: string = 'You can manage your products effectively, you can keep your store up-to-date and ensure that customers have access to the products they need.';
  public blankOrdersHeading: string = 'Manage your orders';
  public blankOrdersMessage: string = 'You can manage your orders effectively and keep your contacts informed about the status of their orders.';
  public blankWhatsappRequirements: string[] = [
    'You have a personal Facebook account.',
    'The number, which will be connecting is not currently in use with another WhatsApp Account.',
    'You can verify your ownership of the number by receiving SMS or international phone calls.',
    'You already have a Facebook business account, Which is verified. (optional)'
  ];
  public blankApiKeyMessage: string = 'This is public API key to access '+environment.configuration+' APIs from external platforms.';
  public blankMarketingOptOutMessage: string = 'Contacts who have chosen not to receive marketing messages.'; 
  public blankBlockCustomersMessage: string = 'Contacts who have been blocked by business';
  public disabledTempalteCreateMessage: string = 'Your account under review, please await activation of the \'create template\' feature.';

  //Store app descriptions
  public woocommerceMessage: string = 'Connect your WooCommerce store and sync all your products.'
  public shopifyMessage: string = 'Connect your Shopify store and sync all your products.'
  public sobotStoreMessage: string = 'If you do not have an e-commerce store, we are comming with an opportunity to create your own online store through our '+environment.configuration+' platform.';
  public woocomUrlMessage: string = 'To connect your WooCommerce account enter your WooCommerce store URL and click on Connect';

  public dialog360Url: string = 'https://hub.360dialog.com/dashboard/app/';

  public static onboardingRoute: string = '3ms7BRPS6F3j44GuqLTXf1colTWJtqId';

  public customer_limit: Record<string, string> = {
    'TIER_50': '50',
    'TIER_250' : '250', 
    'TIER_1K' : '1000', 
    'TIER_10K' : '10000', 
    'TIER_100K' : '100000',
    'TIER_UNLIMITED' : 'Unlimited',
    '': '250',
  };
  public business_status: Record<string,string> = {
    'connected' : 'Connected',
    'ready' : 'Connected', 
    'restricted' : 'Restricted',
    'flagged' : 'Flagged',
    'pending' : 'Pending', 
    'deleted' : 'Deleted',
    'migrated' : 'Migrated',
    'banned' : 'Banned',
    'disconnected' : 'Disconnected',
    'unknown' : 'Unknown',
    'unverified' : 'Unverified',
    'rate_limited' : 'Rate_Limited',
    '' : 'Unknown',
  }
  public quality_rating: Record<string,string> = {
    'green' : 'High quality',
    'high' : 'High quality',
    'yellow' : 'Medium quality',
    'medium': 'Medium quality',
    'red' : 'Low quality',
    'low' : 'Low quality',
    'unknown': 'Unknown',
    '' : 'Unknown'
  }
  public order_status: Record<number,string> = {
    1 : 'Pending',
    2 : 'Accepted',
    3 : 'Delivered',
    4 : 'Payment-pending',
    5 : 'Cancelled',
    6 : 'Processing',
    7 : 'On hold',
    8 : 'Completed',
    9 : 'Refunded',
    10 : 'Failed',
    11 : 'Unknown'
  }
  public rejected_reasons: Record<string,string> = {
    'ABUSIVE_CONTENT': 'Content has been determined to be abusive.',
    'INCORRECT_CATEGORY' : 'Category has been determined to be incorrect. See Template Categories.',
    'INVALID_FORMAT' : 'Duplicate content or missing examples.',
    'SCAM' : 'Content has been determined to be a scam.'
  }

  public analytics_help_text = {
    active: 'Total conversations which are yet to complete 24 hour window',
    open: 'Total conversations which are assigned to someone but not closed, may include conversations outside 24 hours window.',
    subscribed: 'Total conversations conusmed based on your Subscription',
    uic: 'A conversation starts when the first business message is delivered and ends 24 hours later. Conversais marked as UIC when first message sent to business is by your customer',
    status: {
      all:'Denotes your connection status with Facebook. Status can be Connected, disconnected etc',
      restricted: 'Your number has reached its 24-hour messaging limit and can no longer send messages to contacts. Please wait until the messaging limit is reset to send messages.',
      flagged: 'Your quality rating reaches to low state. You can\'t upgrade messaging limit tiers during the Flagged phase. If the message quality improves to a high or medium state by the seventh day from when your status was moved to Flagged, the status will return to Connected. If the quality rating doesn\'t improve, your status will still return to Connected, but you\'ll be placed in a lower',
    },
    quality_rating: 'This is rating of your account quality. Green is best, Red is worst',
    messaging_limit: 'This is per day conversation limit of your business. If you run campaign for 7 days rolling period to unique users upto your current BIC limit, your limit will be increased to next slot upto unlimited',
    new: 'Conversations by first time visitors of the business',
    monthly_free_uic: 'Free conversations consumed out of 1000. You will be charged for UIC after 1000 free conversations are over.',
    active_user: 'Total users who have read message sent by business.',
    interactive_user: 'Total users who have read your message and replied back.',
    direct_customers_added: 'Users who reached your business by sending first message on WhatsApp.',
    manual_customers_added: 'Users added by Excel sheet.',
    undelivered : 'Messages sent by business but not yet delivered to contact',
    delivered : 'Messages sent by business and delivered to contact',
    read : 'Messages sent by business and read by contact',
    received : 'Messages sent by cutomer  to business',
    failed : 'Messages failed to send by business.',
    conversation_type: 'Conversation type depends on type of message sent or received. It could be Marketing, Service, Utility or Authentication.',
    marketing : 'Total no of Conversations created by Marketing templates.',
    utility : 'Total no of Conversations created by Utility templates.',
    authentication : 'Total no of Conversations created Authentication templates',
    service : 'Total no of Conversations created by contact sending first message.',
    free_entry_point : 'The number of messaging conversations on WhatsApp that are free of charge because the conversations start from specific entry points, which include Ads that click to WhatsApp and Facebook Page CTAs. The conversation window for each free entry point conversation is 72-hours. Only the first conversation that starts from the entry point is free of charge. Subsequent conversations with the user are charged.',
    free : 'You are not charged for these conversations.',
    paid : 'You are charged for these conversations.',
    total : 'Total conversations including Free and Paid both',
  }

  public MarketingOptOutButton: Record<string,string> = {
    'mr' : 'प्रमोशन थांबवा',
    'hi' : 'प्रमोशन रोकें',
    'en' : 'Stop promotions',
    'en_US' : 'Stop promotions',
    'en_GB' : 'Stop promotions',
    '' : 'Stop promotions',
  }

  public WalletStatementNotes: Record<string,string> = {
    marketing : "Paid for the marketing conversation",
    service : "Paid for the service conversation",
    utility : "Paid for the utility conversation",
    authentication : "Paid for the authentication conversation",
    recharge : "Recharge added to wallet",
    refund_campaign : "Refund for \"campaign_name\" campaign",
    refund_wallet : "Refund added to wallet",
    campaign_deduct : "Paid for \"campaign_name\" campaign",
  }

  public TemplateStatusMapping: Record<string,number> = {
    'approved' : 1,
    'in_review' : 0,
    'rejected' : 2,
    'in_appeal' : 5,
    'paused': 7,
    'all' : -1
  }

  public TemplateQualityMapping: Record<string,string> = {
    'unknown': 'Active - Quality pending',
    'green' : 'Active - High Quality',
    'yellow' : 'Active - Medium Quality',
    'red' : 'Active - Low Quality'
  }

  public CampaignStatusMapping: Record<string,number> = {
    'scheduled' : 1,
    'in_progress' : 2,
    'completed' : 3,
    'failed': 4,
    'cancelled' : 5,
    'paused': 6,
    'all' : -1
  }

  public PartnerKeys: Record<string,any>  = {
    vibtree: {
      baseUrl: AppSettings.UI_BASE_URL,
      code: '7ccd51dc-b092-440e-824f-b89eb4a21a14'
    },
    behindthesheen: {
      baseUrl: AppSettings.UI_BASE_URL,
      code: 'b0957aa4-896f-4049-b888-e940f6e56fcd'
    },
    garageplug: {
      baseUrl: AppSettings.UI_BASE_URL,
      code: '157d17ce-60b9-4101-ae91-deaee3677f3c'
    },
    dingg: {
      baseUrl: AppSettings.UI_BASE_URL,
      code: '64c05076-1d14-4299-87d7-923d18deb1c5'
    }
  }

  public ActivityEvent: Record<string,string> = {
    "All" : "All",
    "note_added" : "Note",
    "address_added" : "Profile",
    "customer_added" : "Contact",
    "bulk_customers" : "Contact",
    "customer_set_added" : "Contact",
    "new_field_added" : "Contact",
    "template_added" : "WhatsApp Template",
    "campaign_created" : "WhatsApp Campaign",
    "campaign_scheduled" : "WhatsApp Campaign",
    "wallet_recharged" : "Wallet",
    "opt_in_subscription_version" : "Subscription",
    "set_webhook" : "Webhook",
    "business_blocked" : "Business Blocked",
    "business_unblocked" : "Business Unblocked",
    "campaign_resumed": "WhatsApp Campaign",
    "campaign_paused": "WhatsApp Campaign",
    "" : "",
  }
  
}

export enum GlobalEnums {
  male = 1,
  female = 2,
  last_week = 1,
  last_month = 2,
  custom = 3,
  inProgress = '2',
  scheduled = '1',
  completed = '3',
  failed = '4',
  cancelled = '5',
  preparing = '7',
  isPaused = '6',
  approved = 1,
  inReview = 0,
  rejected = 2,
  inAppeal = 5,
  paused = 7,
  disabled = 4,
  payment_success = 3001,
  payment_failed = 3002,
  payment_already_done = 3003,
  available = "available",
  busy = "busy",
  away = "away",
  dnd = "dnd",
  in_call = "in_call",
  tran_now = "tran_now",
  talk_first = "talk_first",
}

export enum TemplateCategories {
  marketing = 'MARKETING',
  utility = 'UTILITY'
}

export enum TemplateHeaderTypes {
  image = 'IMAGE',
  video = 'VIDEO',
  text = 'TEXT',
  document = 'DOCUMENT',
  location = 'LOCATION'
}

export enum FBAnalytics {
  marketing = 'marketing',
  service = 'service',
  free_entry_point = 'free_entry_point',
  utility = 'utility',
  authentication = 'authentication',
}

export enum CallEnum {
  in_progress = 'in-progress',
  completed = 'completed',
  inbound = 'inbound',
  outbound = 'outbound-api',
  failed = 'failed',
  ringing = 'ringing',
  initiated = 'initiated',
  conference_end = 'conference-end',
}

export enum UserStatus {
  active = 'active',
  invited = 'invited',
  removed = 'removed',
  available = 'available',
  away = 'away',
  incall = 'in_call',
}

export enum HealthStatus {
  available = 'AVAILABLE',
  blocked = 'BLOCKED',
}

export enum TemplateTypes {
  text = 'Text',
  media = 'Media',
  location = 'Location',
  carousel = 'Carousel',
  offer_code = 'Offer Code',
  lto = 'Limited-Time Offer',
  catalog = 'Catalog',
  products = 'Products'
}