import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    //console.log(localStorage.getItem('token'));
    if (
      localStorage.getItem('token') != 'undefined' &&
      localStorage.getItem('token') != '' &&
      localStorage.getItem('token') != null
    ) {
      const token = localStorage.getItem('token');
      if (token) {
        if(request.headers.get('skip')){
        } else {
          request = request.clone({
            setHeaders: {
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: token,
              'ngrok-skip-browser-warning' : environment.production ? 'false' : 'true'
            },
          });
        }
      } else {
        this.router.navigate(['/']);
        localStorage.clear();
      }
    }

    return next.handle(request).pipe(
      catchError((err, caught) => {
        if (err == 'jwt expired') {
          this.handleAuthError();
          return of(err);
        }
        throw err;
      })
    );
  }
  private handleAuthError() {
    localStorage.removeItem('auth');
    this.router.navigate(['/']);
  }
}
