import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { LoggedUser } from 'src/app/models/logged-user';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { WalletService } from 'src/app/services/wallet.service';
import { WebSocketService } from 'src/app/services/web-socket.service';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  user: LoggedUser = new LoggedUser(JSON.parse(localStorage.getItem('user')!));
  activeTab!: string;
  expanded: boolean = false;
  dropdownExpanded: string = 'none';
  businessName: string = (localStorage.getItem('businessname')!);
  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private router: Router,
    public elementRef: ElementRef,
    public sharedService: SharedDataService,
    public websocketService: WebSocketService,
    public walletService: WalletService,
  ) {
    this.matIconRegistry.addSvgIcon(
      'chat_icon_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/chat_icon_grey.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'home_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/home_grey.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'bitmoji',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/bitmoji.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'product_store',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/product_store.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'campaign',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/campaign.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'template_solid',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/template_solid.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'analytics_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/analytics_grey.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/settings.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'notifications',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/notifications.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'menu_expand',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/menu_expand.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'menu_collapse',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/menu_collapse.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'person',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/person.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'user_profile',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/user_profile.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'account_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/account_grey.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'wallet_solid',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/wallet_solid.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'calls',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/calls.svg'
      )
    );
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event: any) {
    if(window.innerWidth < 426) { 
      console.log("Less than")
      this.expanded = true;
    } else {
      console.log("Greater than")
    }
  }
  // @HostListener('mouseenter') onMouseEnter() {
  //   this.elementRef.nativeElement.classList.add('expanded');
  // }
  
  // @HostListener('mouseleave') onMouseLeave() {
  //     this.elementRef.nativeElement.classList.remove('expanded');
  // }

  ngOnInit(): void {
    this.getActiveTab();
    if(this.user.user_type === 1) this.getWallet();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.getActiveTab());

    this.sharedService.userChange.subscribe((data) => {
      this.user = new LoggedUser(JSON.parse(localStorage.getItem('user')!));
    })
    
  }

  getActiveTab() {
    let activeRoute = this.router.url.split('/');
    console.log(activeRoute)
    this.activeTab = activeRoute[1].split('?')[0];
    if(activeRoute?.length > 2) {
      if(this.activeTab === 'customers' && activeRoute[2].split('?')[0] === 'customer-set') this.activeTab = 'customer-set'
    }
  }

  changeDropdown(dropdown :string) {
    this.activeTab = dropdown;
    // if(dropdown === this.dropdownExpanded) this.dropdownExpanded = 'none';
    // else this.dropdownExpanded = dropdown;
  }

  connectWebsocket() {
    this.websocketService.openWebSocket();
  }
  connectInternet() {
    this.websocketService.connectInternet();
  }

  getWallet() {
    this.walletService.getWallet().subscribe({
      next: resp => {
        console.log(resp);
        this.walletService.walletBalance = resp.body.data.credits;
      }
    })
  }

  openCustomerSetDropdown() {
    setTimeout(() => {
      this.dropdownExpanded = 'customers'
    },100)
  }

  closeCustomerSetDropdown() {
    setTimeout(() => {
      this.dropdownExpanded = 'none'
    },100)
  }

  openClicked() {
    this.sharedService.hoverActive = false;
    this.expanded = true;
    this.sharedService.responsiveMenuBarOpen = true;
  }

  closeClicked() {
    this.sharedService.hoverActive = true;
    this.expanded = false;
    this.dropdownExpanded = '';
    this.sharedService.responsiveMenuBarOpen = false;
  }

  hoverOpen() {
    this.expanded = true;
    this.openCustomerSetDropdown();
  }

  hoverClose() {{
    this.expanded = false;
    this.closeCustomerSetDropdown();
    this.sharedService.responsiveMenuBarOpen = false;
  }}
}
