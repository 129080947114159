import { Component, Input } from "@angular/core";

@Component({
  selector: 'tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})

export class TagComponent {

  @Input() tagText!: string;

  getBackgroundColor(tagText: string) {
    if(tagText === 'Completed') return '#E3F7F3';
    if(tagText === 'Approved') return '#E3F7F3';
    if(tagText === 'Delivered') return '#E3F7F3';
    if(tagText === 'Refunded') return '#E3F7F3';
    if(tagText === 'Active - Quality pending') return '#E3F7F3';
    if(tagText === 'Active - High Quality') return '#E3F7F3';

    if(tagText === 'In progress') return '#ECF7FD';
    if(tagText === 'In Appeal') return '#ECF7FD';
    if(tagText === 'In Review') return '#ECF7FD';
    if(tagText === 'Accepted') return '#ECF7FD';
    if(tagText === 'On hold') return '#ECF7FD';
    if(tagText === 'Processing') return '#ECF7FD';
    if(tagText === 'Preparing') return '#ECF7FD';

    if(tagText === 'Disabled') return '#FDE7E7';
    if(tagText === 'Failed') return '#FDE7E7';
    if(tagText === 'Rejected') return '#FDE7E7';
    if(tagText === 'Payment-pending') return '#FDE7E7';
    if(tagText === 'Unknown') return '#FDE7E7';
    if(tagText === 'Active - Low Quality') return '#FDE7E7';

    if(tagText === 'Scheduled') return '#E9E3F9';
    if(tagText === 'Pending') return '#E9E3F9';

    if(tagText === 'Cancelled') return '#FCF8EB';
    if(tagText === 'Stopped') return '#FCF8EB';
    if(tagText === 'Paused') return '#FCF8EB';
    if(tagText === 'Active - Medium Quality') return '#FCF8EB';

    return '#FFFFFF';
  }

  getTextColor(tagText: string) {
    if(tagText === 'Completed') return 'var(--primary-color)';
    if(tagText === 'Approved') return 'var(--primary-color)';
    if(tagText === 'Delivered') return 'var(--primary-color)';
    if(tagText === 'Refunded') return 'var(--primary-color)';
    if(tagText === 'Active - Quality pending') return 'var(--primary-color)';
    if(tagText === 'Active - High Quality') return 'var(--primary-color)';

    if(tagText === 'In progress') return '#4FB6EC';
    if(tagText === 'In Review') return '#4FB6EC';
    if(tagText === 'In Appeal') return '#4FB6EC';
    if(tagText === 'Accepted') return '#4FB6EC';
    if(tagText === 'On hold') return '#4FB6EC';
    if(tagText === 'Processing') return '#4FB6EC';
    if(tagText === 'Preparing') return '#4FB6EC';

    if(tagText === 'Disabled') return '#EC2222';
    if(tagText === 'Failed') return '#EC2222';
    if(tagText === 'Rejected') return '#EC2222';
    if(tagText === 'Payment-pending') return '#EC2222';
    if(tagText === 'Unknown') return '#EC2222';
    if(tagText === 'Active - Low Quality') return '#EC2222';

    if(tagText === 'Scheduled') return '#5729CE';
    if(tagText === 'Pending') return '#5729CE';

    if(tagText === 'Paused') return '#E6BA45';
    if(tagText === 'Cancelled') return '#E6BA45';
    if(tagText === 'Stopped') return '#E6BA45';
    if(tagText === 'Active - Medium Quality') return '#E6BA45';

    return '#FFFFFF';
  }
}