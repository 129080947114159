import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CloudNumber } from 'src/app/models/cloud-number';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { VoiceService } from 'src/app/services/voice.service';

@Component({
  selector: 'inbox-dropdown',
  templateUrl: './inbox-dropdown.component.html',
  styleUrls: ['./inbox-dropdown.component.css']
})
export class InboxDropdownComponent implements OnInit {

  currentCloudNumber!: CloudNumber;
  @Output() change = new EventEmitter<CloudNumber>();
  @Input() disabled: boolean = false;
  @Input() selectedNumberId!: number;

  constructor(
    public sharedDataService: SharedDataService,
    private _voiceService: VoiceService,
  ) {
    
  }
  ngOnInit(): void {
    let cloudNumbers = JSON.parse(localStorage.getItem('cloud-numbers')!);
    console.log(cloudNumbers)
    if(cloudNumbers) {
      this.sharedDataService.cloudNumbers = [];
      cloudNumbers?.forEach((element : any) => {
        this.sharedDataService.cloudNumbers.push(new CloudNumber(element));
      });
      if(this.selectedNumberId) {
        for(var index=0;index < this.sharedDataService.cloudNumbers?.length;index++) {
          if(this.sharedDataService.cloudNumbers[index].id === this.selectedNumberId) this.numberChanged(this.sharedDataService.cloudNumbers[index]);
        }
      } else this.numberChanged(this.sharedDataService.cloudNumbers[0]);
    } else {
      this._voiceService.getInboxes().subscribe({
        next: resp => {
          if (resp.body?.code == 200) {
            let responseList = resp.body.data;
            this.sharedDataService.setCloudNumbers(responseList);
            if(this.selectedNumberId) {
              for(var index=0;index < this.sharedDataService.cloudNumbers?.length;index++) {
                if(this.sharedDataService.cloudNumbers[index].id === this.selectedNumberId) this.numberChanged(this.sharedDataService.cloudNumbers[index]);
              }
            } else this.numberChanged(this.sharedDataService.cloudNumbers[0]);
          }
        }, error: error => {
          console.log(error)
        }
      })
    }
  }

  numberChanged(cloudNumber: CloudNumber) {
    this.currentCloudNumber = cloudNumber;
    this.change.emit(this.currentCloudNumber);
  }

}
