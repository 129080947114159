import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Head, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Conversation } from '../models/conversation';
import { Customer } from '../models/customer';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  newUserObject!: Customer;
  isNewUserConversation: boolean = false;

  customer!: Customer;
  private updatedCustomer = new BehaviorSubject<Customer>(new Customer(null));

  update$ = this.updatedCustomer.asObservable();

  updateCustomer(customer: Customer): void {
    this.updatedCustomer.next(customer);
  }

  constructor(private http: HttpClient, private router: Router) {}

  getCustomerData() :Customer {
    return this.customer;
  }
  setCustomerData(customer: Customer){
    this.customer = new Customer(customer);
  }

  getUsers(page: number, type: string = 'all'): Observable<any> {
    if(type.toLowerCase() === 'blocked') {
      return this.http.get<any>('/user/customer?block=1&page=' + page, {
        observe: 'response',
      });
    } 
    return this.http.get<any>('/user/customer?page=' + page, {
      observe: 'response',
    });
  }
  getSingleUser(id: number): Observable<any> {
    return this.http.get<any>('/user/customer/' + id, {
      observe: 'response',
    });
  }

  addUser(obj: any): Observable<any> {
    return this.http.post<any>('/user/customer/', obj, { observe: 'response' });
  }

  addExcel(formData: FormData, fileName: string): Observable<any> {
    const temp = this.http.post<any>('/user/customer/excel/', formData, {
      headers: {
        'Content-Disposition':
          'attachment; filename=' + fileName.replaceAll(' ', '_'),
      },
    });
    return temp;
  }

  bulkCustomerUpload(obj: any): Observable<any> {
    return this.http.post<any>('/user/customer/excel/', obj, {
      observe: 'response',
    });
  }

  updateUser(id: number, obj: any): Observable<any> {
    return this.http.put<any>('/user/customer/' + id + '/', obj, {
      observe: 'response',
    });
  }

  updateCustomerBio(id: number,obj: any): Observable<any> {
    return this.http.patch<any>('/user/customer/' + id + '/', obj, {
      observe: 'response',
    });
  }

  addNewUserConversation(customerObj: Customer) {
    this.isNewUserConversation = true;
    console.log(customerObj);
    this.newUserObject = customerObj;
  }

  getNewUserObject() {
    if (this.isNewUserConversation) {
      this.isNewUserConversation = false;
      return this.newUserObject;
    } else return new Customer(null);
  }

  searchUsers(pageNumber:number,searchTag: string,type: string = 'all'): Observable<any> {
    if(type.toLowerCase() === 'blocked') {
      return this.http.get<any>('/search/customer?q='+searchTag+'&block=1&page=' + pageNumber, {
        observe: 'response',
      });
    } 
    return this.http.get<any>('/search/customer?q='+searchTag+'&page='+pageNumber,{
      observe:'response',
    });
  }

  getCustomerHistory(id: number = -1,pageNumber: number): Observable<any> {
    if(id === -1){
      return this.http.get<any>('/user/customer/history?page='+pageNumber, { observe: 'response' });
    } else {
      return this.http.get<any>('/user/customer/history/'+id +'?page='+pageNumber, { observe: 'response' });
    }
  }

  getCustomFieldsExport() :Observable<any> {
    return this.http.get<any>('/console/export-custom/', {
      responseType: 'blob' as 'json',
    })
  }

  getCustomFields() :Observable<any> {
    return this.http.get<any>('/business/custom-field', {
      observe: 'response',
    })
  }

  addCustomField(obj: any) :Observable<any> {
    return this.http.post<any>('/business/custom-field',obj, {
      observe: 'response',
    });
  }

  getMarketingOptOutCustomers(page: number) :Observable<any> {
    return this.http.get<any>('/user/opt-out', { observe: 'response'});
  }
}
