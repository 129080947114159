import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';
import { GlobalStrings } from 'src/app/constants';
import { AgentService } from 'src/app/services/agent.service';
import { CommonService, NumberDetails } from 'src/app/services/common.service';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'change-number',
  templateUrl: './change-number.component.html',
  styleUrls: ['./change-number.component.css']
})
export class ChangeNumberComponent {

  constructor(
    private _agentService: AgentService,
    public constants: GlobalStrings,
    private _toast: ToasterService,
    private _commonService: CommonService,
  ) {
  }

  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Output() success: EventEmitter<string> = new EventEmitter();
  numberDetails: NumberDetails = {
    number: '',
    callingCode: '',
    countryCode: '',
    flag: ''
  }
  isValid : boolean = false;

  otpSent: boolean = false;
  isValidOtp: boolean = false;
  @ViewChild(NgOtpInputComponent, { static: false })
  ngOtpInput!: NgOtpInputComponent;
  otpConfig: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
  };
  mobileOtp!: string;
  invalidOtp: boolean = false;
  retryOtpCount: number = 0;
  isLoading: boolean = false;

  sendVerification() {
    if(this.numberDetails.countryCode === '') {
      this._toast.error("Enter country code");
      return ;
    }
    if(this._commonService.isValidNumber(this.numberDetails.number,this.numberDetails.countryCode)){
      this.isLoading = true;
      let input = {
        mobile: this.numberDetails.callingCode + this.numberDetails.number,
      }
      this._agentService.updateMobileOtp(input).subscribe({
        next: resp => {
          if(resp.body.code === 200) {
            this.otpSent = true;
            this.isLoading = false;
          }
          else {
            console.log(resp.body.message);
            this._toast.error(resp.body.message);
            this.isLoading = false;
          }
        }, error: error => {
          console.log(error);
          this.isLoading = false;
          this._toast.error(error);
        }
      })
    } else {
      this._toast.error("Invalid mobile number");
    }
  }

  resendOtp(){
    this.sendVerification();
    this.mobileOtp = '';
    this.ngOtpInput.setValue('');
    this.invalidOtp = false;
    this.retryOtpCount = 30;
    for(let i = 0; i < 30; i++){
      setTimeout(() =>{
        this.retryOtpCount--;
      }, i*1000);
    }
  }

  onMobileOtpChange(otp: any) {
    if (otp && otp.length == 6) {
      this.mobileOtp = otp;
      this.isValidOtp = true;
    } else {
      this.isValidOtp = false;
    }
  }

  changeEmail() {
    this.isLoading = true;
    let input = {
      mobile: this.numberDetails.callingCode + this.numberDetails.number,
      otp: this.mobileOtp,
    }
    this._agentService.updateMobile(input).subscribe({
      next: resp => {
        if(resp.body.code === 200) {
          this.isLoading = false;
          this._toast.success(resp.body.message);
          this.success.emit(input.mobile);
        }
        else {
          console.log(resp.body.message);
          this._toast.error(resp.body.message);
          this.isLoading = false;
          this.invalidOtp = true;
        }
      }, error: error => {
        console.log(error);
        this.isLoading = false;
        this._toast.error(error);
        this.invalidOtp = true;
      }
    })
  }

  wrongNumber() {
    this.otpSent = false; 
    this.mobileOtp = '';
    this.numberDetails.number = this.numberDetails.callingCode + this.numberDetails.number;
  }
}
