import {
  Directive,
  Attribute,
  Renderer2,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';

@Directive({
  selector: '[uiImageLoader]',
})
export class ImageLoaderDirective {
  constructor(
    @Attribute('loader') public loader: string,
    @Attribute('onErrorSrc') public onErrorSrc: string,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.defaultImage);
  }

  @Input('defaultImage') defaultImage = 'assets/images/broken_image.png';

  @HostListener('load') onLoad() {
    setTimeout(() => {
      this.renderer.setAttribute(
        this.el.nativeElement,
        'src',
        this.el.nativeElement.src
      );
    },500)
  }
  @HostListener('error') onError() {
    this.el.nativeElement.src = this.defaultImage;
  }
}
