import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.css'],
})

export class SearchInputComponent {

  @ViewChild("search") inputElement!: ElementRef;
  @Input() searchInput!: string;
  @Input() autofocus: boolean = true;
  @Output() searchInputChange = new EventEmitter<string>();

  constructor(private el: ElementRef){}

  ngAfterViewInit(): void {
    if(this.autofocus){
      //this.inputElement.nativeElement.focus();
    }
  }
  removeSearch(){
    this.searchInput = '';
    this.searchInputChange.emit(this.searchInput);
  }
}