import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalStrings } from 'src/app/constants';
import { Address, Customer, CustomerLabel } from 'src/app/models/customer';
import { Label } from 'src/app/models/label';
import { ChatsService } from 'src/app/services/chats.service';
import { CommonService } from 'src/app/services/common.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { UsersService } from 'src/app/services/users.service';
import { VoiceService } from 'src/app/services/voice.service';

@Component({
  selector: 'customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.css']
})
export class CustomerProfileComponent implements OnInit {

  customer!: Customer;
  isBlockCustomer: boolean = false;
  blockCustomerMessage = GlobalStrings.blockChatMessage;
  deleteAddressMessage = GlobalStrings.deleteAddressConfirmMsg;
  isEditLabel: boolean = false;
  assingedLabelList: Label[] = [];
  isEditCustomer: boolean = false;
  isEditAddress: boolean = false;
  isAddAddress: boolean = false;
  editedAddressIndex: number = 1;
  isDeleteConfirm: boolean = false;
  deleteAddressIndex!: number;

  isLoading: boolean = false;

  constructor(
    private chatsService: ChatsService,
    private _toast: ToasterService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private commonService: CommonService,
    private usersService: UsersService,
    private router: Router,
    public sharedService: SharedDataService,
    private _activatedRoute: ActivatedRoute,
    private voiceService: VoiceService,
  ) { 
    this.matIconRegistry.addSvgIcon(
      'chat_icon_green',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/chat_icon_green.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'circle_plus_green',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/circle_plus_green.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'close_conversation_black',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/close_conversation_black.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'block_red',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/block_red.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/facebook.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/linkedin.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'twitter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/twitter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'whatsapp_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/whatsapp_grey.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'calls',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/calls.svg'
      )
    );
  }

  ngOnInit(): void {
    document.getElementById('side-window')!.classList.toggle("active");
    // document.getElementById('side-window')!.style.transition = 'width 400ms';
    if(this.usersService.getCustomerData()?.id) {
      this.customer = this.usersService.getCustomerData();
      this.customer?.labels?.forEach((label)=>{
        this.assingedLabelList.push(this.commonService.getLabelFromAudienceLabel(label));
      });
    }
    else {
      this._activatedRoute.paramMap.subscribe((params) => {
        console.log(params);
        let id = params.get('id') ? Number(params.get('id')) : 0;
        if(id) {
          this.isLoading = true;
          this.usersService.getSingleUser(id).subscribe({
            next: resp => {
              if(resp.body.code === 200) {
                this.customer = new Customer(resp.body.data);
                this.customer?.labels?.forEach((label)=>{
                  this.assingedLabelList.push(this.commonService.getLabelFromAudienceLabel(label));
                });
                this.isLoading = false;
                console.log(this.customer)
              } else {
                this.isLoading = false;
                this._toast.error(resp.body.message);
                this.closeProfile();
              }
            }, error: error => {
              this.isLoading = false;
              this._toast.error(error);
              this.closeProfile();
            }
          })
        } else this.closeProfile();
      })
    }
  }

  blockCustomer() {
    let obj = {
      contact_id: this.customer.id,
      block: !this.customer.is_block,
    };
    this.chatsService.blockChat(obj).subscribe(
      (resp) => {
        if (resp.body.code == 200) {
          this._toast.success(resp.body.message);
          this.customer.is_block = !this.customer.is_block;
          this.isBlockCustomer = false;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  editCustomer() {
    this.isEditCustomer = true;
  }
  cancelEditCustomer() {
    this.isEditCustomer = false;
  }
  updatedCustomer(event: Customer) {
    this.isEditCustomer = false;
    this.isAddAddress = false;
    this.isEditAddress = false;
    this.customer = new Customer(event);
  }
  cancelEditAddress() {
    this.isEditAddress = false;
    this.isAddAddress = false;
  }
  editAddress(index: number){
    this.isEditAddress = true;
    this.editedAddressIndex = index;
  }
  deleteAddress(){
    this.customer.address.splice(this.deleteAddressIndex, 1);
    let customerObject: Record<string, any> = {
      name: this.customer.name,
      email: this.customer.email,
      address: this.customer.address,
    };
    if (this.customer.gender > 0 && this.customer.gender < 5) {
      customerObject['gender'] = this.customer.gender;
    }
    if (
      this.customer.date_of_birth &&
      this.customer.date_of_birth !== '' &&
      this.customer.date_of_birth.length == 10
    ) {
      customerObject['date_of_birth'] = this.customer.date_of_birth;
    }
    if(this.customer.address && this.customer.address.length > 0){
      customerObject['address'] = [];
      this.customer.address?.forEach((address: Address)=>{
        let input: Record<string,any> = {
          line_1: address?.line_1,
          city: address?.city,
          state: address?.state,
          country: address?.country,
          pin_code: address?.pin_code,
        } 
        if(address?.line_2) input['line_2'] = address?.line_2;
        customerObject['address'].push(input);
      })
    } else customerObject['address'] = [];
    let response = this.usersService.updateUser(this.customer.id, customerObject)
    response.subscribe(
      (res) => {
        if (res.body.code >= 200 && res.body.code < 300) {
          this.isDeleteConfirm = false;
          this._toast.success(res.body.message);
        }
      },
      (error) => {
        this.isDeleteConfirm = false;
        this._toast.error(error);
        console.log(error);
      }
    );
  }

  addLabel() {
    this.isEditLabel = true;
  }
  assignLabel(event: Label[]){
    let labelArray = event;
    let customerLabelArray: CustomerLabel[] = [];
    labelArray?.forEach((label)=>{
      let input = {
        label_id: label?.id,
        name: label?.name,
        color: label?.color,
        bg_color: label?.bg_color,
      };
      customerLabelArray.push(new CustomerLabel(input));
    })
    this.customer.labels = customerLabelArray;
    this.assingedLabelList = event;
    this.isEditLabel = false;
  }

  addAddress() {
    this.isAddAddress = true;
  }
  
  openConversation() {
    console.log(this.customer);
    this.usersService.addNewUserConversation(this.customer);
    this.router.navigate(['/chats']);
  }

  closeProfile() {
    this.usersService.updateCustomer(this.customer);
    this.router.navigate(['/customers']);
  }

  callCustomer() {
    this.voiceService.callCustomer(this.customer);
  }

}
