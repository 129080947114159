import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalStrings } from 'src/app/constants';
import { Conversation, ConversationLabel, LastMessage } from 'src/app/models/conversation';
import { Label } from 'src/app/models/label';
import { LoggedUser } from 'src/app/models/logged-user';

@Component({
  selector: 'conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.css']
})
export class ConversationComponent implements OnInit {

  @Input() call: boolean = false;
  @Input() conversation!: Conversation;
  @Input() openConversationId!: number; 
  @Output() conversationClicked = new EventEmitter<Conversation>();
  @Output() openAssignConversation = new EventEmitter<Conversation>();

  user: LoggedUser = new LoggedUser(JSON.parse(localStorage.getItem('user')!));

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry
  ) {
    this.matIconRegistry.addSvgIcon(
      'campaign_failed_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/campaign_failed_grey.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'person',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/person.svg'
      )
    );
   }

  ngOnInit(): void {
  }

  userName(name: string | undefined) {
    if(name == undefined) return '';
    return name.charAt(0).toUpperCase();
  }

  checkTime(msgTime: Date | undefined) {
    if(msgTime == undefined) return '';
    let msgDate = new Date(msgTime);
    let currDate = new Date();
    let yesDate = new Date(currDate.setDate(currDate.getDate() - 1));
    if (msgDate > yesDate) return true;
    else return false;
  }

  getDisplayText(lastMessage: LastMessage | null | undefined) {
    if(lastMessage?.fb_msg_type){
      if (lastMessage?.display_text && lastMessage?.display_text !== '') {
        return lastMessage.display_text;
      } else if (lastMessage?.fb_msg_type === 'image') {
        return 'Image';
      } else if (lastMessage?.fb_msg_type === 'video') {
        return 'Video';
      } else if (lastMessage?.fb_msg_type === 'document') {
        return 'Document';
      } else if (lastMessage?.fb_msg_type === 'order') {
        return 'Cart';
      }else if (lastMessage?.fb_msg_type === 'product_list' || lastMessage?.fb_msg_type === 'product') {
        return 'Catalog';
      }  else if (lastMessage?.fb_msg_type === 'location') {
        return 'Location';
      } else if (lastMessage?.fb_msg_type === 'quick_reply') {
        return lastMessage.display_text;
      } else if (lastMessage?.fb_msg_type === 'sticker') {
        return 'Sticker';
      } else if (lastMessage?.fb_msg_type === 'audio') {
        return 'Audio';
      } else if (lastMessage?.fb_msg_type === 'contacts') {
        return 'Contact';
      } else if (lastMessage?.fb_msg_type === 'address_message') {
        return 'Address';
      }
      else return GlobalStrings.unsupportedMessageType;
    } else return '';
  }

  convertConvLabelToLabel(label: ConversationLabel) {
    let input = {
      name: label?.name,
      color: label?.color,
      description: '',
      id: label?.label_id,
      bg_color: label?.bg_color,
      isChecked: false,
      label_chats_count: 0,
      label_audienceset_count: 0,
    }
    let labelObj = new Label(input);
    return labelObj;
  }

  getUnread(count: number | undefined) {
    if(count == undefined) return 0;
    else return count;
  }

  openConversation(event: any){
    this.conversationClicked.emit(this.conversation);
  }
  assignConversation(event: Event){
    console.log(event);
    event.stopPropagation();
    this.openAssignConversation.emit(this.conversation);
  }
}

