import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: 'save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.css']
})

export class SaveButtonComponent {

  @Input() saveButtonText!: string;
  @Input() hideSave: boolean = false;
  @Input() disableSave: boolean = false;
  @Input() loading: boolean = false;
  @Output() saveClicked = new EventEmitter<boolean>();
}