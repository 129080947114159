<div class="audio-player">
  <button (click)="toggleAudio()">
    <i class="fa-solid fa-play" *ngIf="!isPlaying"></i>
    <i class="fa-solid fa-pause" *ngIf="isPlaying"></i>
  </button>
  <input #rangeInput type="range" [max]="duration" [value]="currentTime" (input)="seek($event)">
  <span class="timer">{{ formatTime(currentTime) }} / {{ formatTime(duration) }}</span>
  <audio #audioPlayer (timeupdate)="updateTime($event)">
    <source [src]="audioUrl" type="audio/mp3">
  </audio>
</div>