<div class="template-edit">
  <div  class="template-edit-container">
    <div class="template-edit-heading">
      <span class="audience-set-selection-heading-title"><i class="fa-solid fa-arrow-left" (click)="closeTemplateView()"></i> {{template?.entity_name}}</span>
      <span class="template-edit-close-icon">
        <i class="fas fa-xmark" (click)="closeTemplateView()"></i>
      </span>
    </div>
    <div class="template-body">
      <div
        class="template-body-selection"
        *ngIf="
          template?.header_type === 'LOCATION' ||
          template?.template_type === 'MEDIA' ||
          isHeaderVariable ||
          isBodyVariable ||
          isWebsiteVariable ||
          (template.phone_numbers && template.phone_numbers.length > 0) || 
          (template.cta_website && template.cta_website.length > 0) ||
          (template.carousel_cards && template.carousel_cards.length > 0) ||
          (template.catalog_button_text && template.catalog_button_text.length > 0) ||
          (template.mpm_button_text && template.mpm_button_text.length > 0)
        "
      >
        <div
          class="template-body-header"
          *ngIf="template?.template_type === 'MEDIA'"
        >
          <div class="v-1rem" *ngIf="template?.header_type === 'IMAGE'">
            <div class="card-container">
              <p class="card-heading">Header</p>
              <p class="campaign-edit-note">You can choose a new image for the campaign.</p>
            </div>
            <div class="template-body-header-selection">
              <span class="template-media-type">Image</span>
              <div class="campaign-select-audience-set m-0" (click)="changeTemplateImage($event)">
                <span class="campaign-audience-set-name">{{(imageToBeUploaded ? imageToBeUploaded.name : (template.example)) | afterSlash}}</span>
                <button class="campaign-change-button" >Change</button>
              </div>
              <p><small>You can only choose JPG, PNG and JPEG file.</small></p>
            </div>
          </div>
          <div class="v-1rem" *ngIf="template?.header_type === 'VIDEO'">
            <div class="card-container">
              <p class="card-heading">Header</p>
              <p class="campaign-edit-note">You can choose a new video for the campaign.</p>
            </div>
            <div class="template-body-header-selection">
              <span class="template-media-type">Video</span>
              <div class="campaign-select-audience-set m-0" (click)="changeTemplateVideo($event)">
                <span class="campaign-audience-set-name">{{videoToBeUploaded ? videoToBeUploaded?.name : (template.example) | afterSlash}}</span>
                <button class="campaign-change-button">Change</button>
              </div>
              <p><small>You can only choose MP4 and 3gp file.</small></p>
            </div>
          </div>
          <div class="v-1rem" *ngIf="template?.header_type === 'DOCUMENT'">
            <p class="card-heading">Header</p>
            <p class="campaign-edit-note">You can choose a new document for the campaign.</p>
            <div class="template-body-header-selection">
              <span class="template-media-type">Document</span>
              <div class="campaign-select-audience-set m-0" (click)="changeTemplateDocument($event)">
                <span class="campaign-audience-set-name">{{documentToBeUploaded ? documentToBeUploaded?.name : (template.example)}}</span>
                <button class="campaign-change-button" >Change</button>
              </div>
              <p><small>You can only choose pdf file.</small></p>
            </div>
          </div>
        </div>
        <div class="v-1rem" *ngIf="isHeaderVariable && template?.header_type === 'TEXT'">
          <div class="card-container">
            <p class="card-heading">Header</p>
            <span class="campaign-edit-note">Add variable to text of this message</span>
          </div>
          <div class="template-header-variables">
            <span *ngIf="isCampaign">
              <span class="template-media-type">Variable {{1}}</span>
              <variable-dropdown (typeChange)="headerVariableType = $event"></variable-dropdown>
            </span>
            <span class="template-media-type">Header Variable Text</span>
            <text-input [placeholder]="'Enter value of {{ 1 }} in header'" [inputText]="headerVariable1" (textChange)="headerVariable1 = $event"></text-input>
          </div>
        </div>
        <div class="v-1rem" *ngIf="template?.catalog_button_text && template.catalog_button_text.length > 0">
          <div class="card-container">
            <p class="card-heading">Catalog</p>
            <span class="campaign-edit-note">Add the retailer ID of the item which should be shown in preview</span>
          </div>
          <div class="template-header-variables">
            <span class="template-media-type">Retailer ID</span>
            <text-input [placeholder]="'Enter retailer id'" [inputText]="catalogRetailerId" (textChange)="catalogRetailerId = $event"></text-input>
          </div>
        </div>
        <div class="v-1rem" *ngIf="template?.mpm_button_text && template.mpm_button_text.length > 0">
          <div class="card-container">
            <p class="card-heading">Products</p>
            <span class="campaign-edit-note">Add sections and products to be sent</span>
          </div>
          <div class="template-header-variables" style="padding: 0;">
            <span class="template-media-type">Thumbnail product Id</span>
            <text-input [placeholder]="'Enter retailer id'" [inputText]="productsThumbnailId" (textChange)="productsThumbnailId = $event"></text-input>
          </div>
          <div class="carousel-cards v-1rem">
            <ng-container *ngFor="let card of productsList; let i = index">
              <div class="template-header-variables">
                <span class="template-media-type">Section {{i+1}}</span>
                <text-input [placeholder]="'Enter section title'" [inputText]="card.title" (textChange)="card.title = $event"></text-input>
              </div>
              <div class="template-body-variables">
                <div class="template-body-variable-list" *ngFor="let row of card.rows; let j = index">
                  <span class="w-100">
                    <span class="template-media-type">Product {{j+1}}</span>
                    <text-input 
                      [placeholder]="'Enter retailer id'"
                      [inputText]="row.title" 
                      (textChange)="row.title = $event">
                    </text-input>
                  </span>
                  <span class="product-remove-icon" (click)="removeProduct(i,j)"><i class="fas fa-xmark"></i></span>
                </div>
                <cancel-button *ngIf="card?.rows?.length !== 30" [cancelButtonText]="'+ Add Product'" (cancelClicked)="addItems(i)"></cancel-button>
              </div>
            </ng-container>
            <cancel-button *ngIf="productsList?.length !== 10" [cancelButtonText]="'+ Add Section'" (cancelClicked)="addSection()"></cancel-button>
          </div>
        </div>
        <div class="v-1rem" *ngIf="template?.header_type === 'LOCATION'">
          <div class="card-container">
            <p class="card-heading">Header</p>
            <span class="campaign-edit-note">Add latitude & longitude of this message</span>
          </div>
          <div class="template-header-variables" >
            <span class="template-media-type">Latitude</span>
            <text-input [placeholder]="'Enter latitude'" [inputText]="location.latitude" (textChange)="location.latitude= $event"></text-input>
          </div>
          <div class="template-header-variables" >
            <span class="template-media-type">Longitude</span>
            <text-input [placeholder]="'Enter longitude'" [inputText]="location.longitude" (textChange)="location.longitude = $event"></text-input>
          </div>
          <div class="template-header-variables" >
            <span class="template-media-type">Name</span>
            <text-input [placeholder]="'Enter name of address'" [inputText]="location.name" (textChange)="location.name= $event"></text-input>
          </div>
          <div class="template-header-variables" >
            <span class="template-media-type">Address</span>
            <text-input [placeholder]="'Enter address'" [inputText]="location.address" (textChange)="location.address = $event"></text-input>
          </div>
        </div>
        <div
          class="v-1rem"
          *ngIf="isBodyVariable"
        >
          <div class="card-container">
            <p class="card-heading">Message</p>
            <span class="campaign-edit-note">Add variable to text of this message</span>
          </div>
          <div class="template-body-variables">
            <div class="template-body-variable-list" *ngFor="let variableText of previewBodyText.slice(1); let i = index">
              <span *ngIf="isCampaign">
                <span class="template-media-type">Variable {{i+1}}</span>
                <variable-dropdown (typeChange)="variableInputType[i] = $event"></variable-dropdown>
              </span>
              <span class="w-100" *ngIf="variableInputType[i] === 'Text'">
                <span class="template-media-type">Body Variable {{i+1}}</span>
                <text-input 
                  [placeholder]="'Enter value of {{'+(i+1) +'}} in body'"
                  [inputText]="variableTextInput[i]" 
                  (textChange)="variableTextInput[i] = $event">
                </text-input>
              </span>
            </div>
          </div>
        </div>
        <div class="empty-variables-error" *ngIf="isCampaign && (isBodyVariable || isHeaderVariable)">{{constants.emptyDynamicVariablesMessage}}</div>
        <div class="v-1rem" *ngIf="(template.phone_numbers && template.phone_numbers.length > 0) || (template.cta_website && template.cta_website.length > 0)">
          <p class="card-heading">Call To Action buttons</p>
          <div class="template-phone-number-field" *ngIf="template.phone_numbers && template.phone_numbers.length > 0">
            <span class="template-media-type">{{template.phone_numbers[0]?.text}}</span>
            <span class="template-phone-number-value">{{template.phone_numbers[0]?.phone_number}}</span>
          </div>
          <div class="template-phone-number-field" *ngIf="template.cta_website && template.cta_website.length > 0">
            <span class="template-media-type" style="margin-bottom: 1rem;">Website URL Variable Text</span>
            <ng-container *ngFor="let website of template.cta_website; let i = index">
              <ng-container *ngIf="website.url.indexOf(variable) > 0">
                <p style="margin-bottom: 0.5rem;" class="template-phone-number-value">{{website.url?.substring(0,website.url.length -5)}}<span style="color: #54656F;">{{websiteVariableText[i] === '' ? '{{1}}' : websiteVariableText[i]}}</span></p>
                <text-input 
                    [placeholder]="'Enter value of {{1}} in URL'"
                    [inputText]="websiteVariableText[i]" 
                    (textChange)="websiteVariableText[i] = $event">
                </text-input>
              </ng-container>
              <ng-container *ngIf="!website.url?.indexOf(variable) || website.url.indexOf(variable) <= 0">
                <p class="template-phone-number-value">{{website.url}}</p>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="v-1rem" *ngIf="template?.limited_time_offer && template.limited_time_offer.length > 0">
          <p class="card-heading" style="margin: 0;">Coupon code</p>
          <text-input 
              [placeholder]="'Enter coupon code'"
              [inputText]="template.limited_time_offer[0].coupon_code" 
              (textChange)="template.limited_time_offer[0].coupon_code = $event">
          </text-input>
        </div>
        <div class="v-1rem" *ngIf="template?.limited_time_offer && template.limited_time_offer[0]?.has_expiration">
          <p class="card-heading" style="margin: 0;">Expiration time</p>
          <div class="campaign-schedule-container">
            <span class="campaign-edit-note">
              Date
              <date-picker [startDate]="today" [date]="scheduleDate" (dateChange)="scheduleDate = ($event)"></date-picker>
            </span>
            <span class="campaign-edit-note">
              Time
              <time-picker 
              (timeChange)="scheduleTime = $event"
              ></time-picker>
            </span>
          </div>
        </div>
        <div class="v-1rem" *ngIf="(template.carousel_cards && template.carousel_cards.length > 0)">
          <div class="card-container">
            <p class="card-heading">Carousel cards</p>
            <p class="campaign-edit-note">Modify the example content provided and replace it with new.</p>
          </div>
          <div class="carousel-cards v-1rem">
            <ng-container *ngFor="let card of carouselFileList; let i = index">
              <div class="template-body-header-selection" *ngIf="template.carousel_cards[i].header_type === 'IMAGE'">
                <div class="carousel-card-heading">Card {{i+1}}</div>
                <span class="template-media-type">Image</span>
                <div class="campaign-select-audience-set m-0" (click)="changeCarouselImage($event,i)">
                  <span class="campaign-audience-set-name">{{(carouselFileList[i]?.file ? carouselFileList[i].file?.name : (template.carousel_cards[i]?.example)) | afterSlash}}</span>
                  <button class="campaign-change-button" >Change</button>
                </div>
                <p><small>You can only choose JPG, PNG and JPEG file.</small></p>
              </div>
              <div class="template-body-header-selection" *ngIf="template.carousel_cards[i].header_type === 'VIDEO'">
                <div class="carousel-card-heading">Card {{i+1}}</div>
                <span class="template-media-type">Video</span>
                <div class="campaign-select-audience-set m-0" (click)="changeCarouselVideo($event,i)">
                  <span class="campaign-audience-set-name">{{(carouselFileList[i]?.file ? carouselFileList[i].file?.name : (template.carousel_cards[i]?.example)) | afterSlash}}</span>
                  <button class="campaign-change-button">Change</button>
                </div>
                <p><small>You can only choose MP4 and 3gp file.</small></p>
              </div>
              <div class="template-body-variables" *ngIf="template.carousel_cards[i]?.body_text_example && template.carousel_cards[i].body_text_example.length > 0">
                <div class="template-body-variable-list" *ngFor="let variable of card.variables; let j = index">
                  <span *ngIf="isCampaign">
                    <span class="template-media-type">Variable {{j+1}}</span>
                    <variable-dropdown (typeChange)="card.variableInputType[j] = $event"></variable-dropdown>
                  </span>
                  <span class="w-100" *ngIf="card.variableInputType[j] === 'Text'">
                    <span class="template-media-type">Body Variable {{j+1}}</span>
                    <text-input 
                      [placeholder]="'Enter value of {{'+(j+1) +'}} in body'"
                      [inputText]="variable.value" 
                      (textChange)="variable.value= $event; carouselTextChange(i,j,$event)">
                    </text-input>
                  </span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="template-preview-container">
        <div class="container-preview-card">
          <p class="card-heading">Preview</p>
          <template-preview 
            [template]="template" 
            [location]="location"
            [bodyVariables]="variableTextInput"
            [headerVariable]="headerVariable1"
            [bodyVariableTypes]="variableInputType"
            [headerVariableType]="headerVariableType"
            [productsCount]="productsCount"
          ></template-preview>
        </div>
      </div>
    </div>
    <div  class="template-buttons-wrap">
      <cancel-button [cancelButtonText]="'Back'" (cancelClicked)="closeTemplateView()"></cancel-button>
      <save-button [disableSave]="loaderSendTemplate" [loading]="loaderSendTemplate" [saveButtonText]="isCampaign ? 'Select' : 'Send'" (saveClicked)="validateMediaUrl()"></save-button>
    </div>
  </div>
</div>

<div class="shaded-screen"></div>