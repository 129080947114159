import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Assignee, Conversation } from 'src/app/models/conversation';
import { TeamMember } from 'src/app/models/team-member';
import { AgentService } from 'src/app/services/agent.service';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ChatsService } from 'src/app/services/chats.service';
import { NGXLogger } from 'ngx-logger';
import { GlobalStrings } from 'src/app/constants';
import { ToasterService } from 'src/app/services/toastr.service';
import { Call, Assignee as CallAssignee } from 'src/app/models/call';

@Component({
  selector: 'assign-member',
  templateUrl: './assign-member.component.html',
  styleUrls: ['./assign-member.component.css']
})
export class AssignMemberComponent implements OnInit {

  @Input() conversation!: Conversation;
  @Input() callConversation!: Call;
  @Output() onAssignChat = new EventEmitter<Conversation>();
  @Output() onAssignCall = new EventEmitter<Call>();
  @Output() onCancelAssign = new EventEmitter<boolean>();

  searchEmptyList = GlobalStrings.searchEmptyList
  teamMemberList: TeamMember[] = [];
  loaderTeamMember: boolean = false;
  loaderTeamMemberPagination: boolean = false;
  teamMemberPageNext: boolean = true;
  teamMemberPageNumber: number = 1;
  selectedTeamMember!: TeamMember;
  isTeamMemberSelected: boolean = false;
  loaderAssignAgent: boolean = false;
  loaderUnassignAgent: boolean = false;
  isSearchMember: boolean = false;
  teamMemberSearchTag: string = '';
  prevTeamMemberSearchTag: string = '';
  assigneeId!: number;

  constructor(
    private agentService: AgentService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private chatsService: ChatsService,
    private toastr: ToasterService,
    private logger: NGXLogger,
  ) { 
    this.matIconRegistry.addSvgIcon(
      'team_member_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/team_member_grey.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'admin_shield_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/admin_shield_grey.svg'
      )
    );
  }

  focusSearch() {
    setTimeout(() => {
    var currentInput = document.getElementById('popup-search');
    if(currentInput){
      var childInput = currentInput.querySelector('input');
      if (childInput) {
          childInput.focus();
        }
      }
    }, 10);
  }

  ngOnInit(): void {
    if(this.conversation) this.assigneeId = this.conversation?.assignee?.id;
    if(this.callConversation) this.assigneeId = this.callConversation?.assignee?.id;
    console.log(this.assigneeId);
    if(this.assigneeId) this.isTeamMemberSelected = true;
    this.teamMemberPageNumber = 1;
    this.teamMemberPageNext = true;
    this.getTeamMember(1);
  }

  isTeamMemberChecked(teamMember: TeamMember){
    if(this.selectedTeamMember?.id === teamMember?.id) return true;
    else return false
  }
  getTeamMember(pageNumber: number){
    if(pageNumber === 1) {
      this.loaderTeamMember = true;
      this.loaderTeamMemberPagination = true;
    }
    else this.loaderTeamMemberPagination = true;
    let response = this.agentService.getActiveAgents(pageNumber);
    response.subscribe((resp)=>{
      try {
        if(resp.body.code === 200) {
          if(resp.body.next) this.teamMemberPageNext = true;
          else this.teamMemberPageNext = false;
          if(pageNumber === 1) this.teamMemberList = [];
          for (var index = 0; index < resp.body.data?.length; index++) {
            if(resp.body.data[index]?.user_type === 1) {
              this.teamMemberList.unshift(new TeamMember(resp.body.data[index]));
              if(this.teamMemberList[0].id === this.assigneeId) this.selectedTeamMember = this.teamMemberList[0];
            }
            else this.teamMemberList.push(new TeamMember(resp.body.data[index]));
            if(this.teamMemberList[index].id === this.assigneeId) this.selectedTeamMember = this.teamMemberList[index];
          }
          this.loaderTeamMember = false;
          this.loaderTeamMemberPagination = false;
          this.teamMemberPageNumber++;
        }
      } catch (exception) {
      }
    },(error)=>{
      console.log(error);
      this.loaderTeamMember = false;
      this.loaderTeamMemberPagination = false;
    });
  }
  onTeamMemberScroll(event: any) {
    if (
      event.target.scrollHeight -
        event.target.offsetHeight -
        event.target.scrollTop <
      50
    ) {
      if (this.teamMemberPageNext && !this.loaderTeamMemberPagination) {
        this.getTeamMember(this.teamMemberPageNumber);
      }
    }
  }
  assignChatSubmit() {
    if(this.assigneeId === this.selectedTeamMember?.id){
      if(this.conversation) this.onAssignChat.emit(this.conversation);
      if(this.callConversation) this.onAssignCall.emit(this.callConversation);
      return ;
    }
    this.loaderAssignAgent = true;
    let obj:Record<string,any> = {
    };
    if(this.conversation) obj['conversation_id'] = this.conversation?.id;
    if(this.callConversation) obj['conversation_id'] = this.callConversation?.conversation;    
    obj['assignee_id'] = this.selectedTeamMember.id;
    if(this.callConversation) obj['voice_assignee'] = true;
    this.chatsService.assignChat(obj).subscribe(
      (resp) => {
        if (resp.body.code == 200) {
          if(this.conversation){
            let updatedConversation = new Conversation(this.conversation);
            updatedConversation.assignee.id = this.selectedTeamMember.id;
            updatedConversation.assignee.name = this.selectedTeamMember?.name;
            this.onAssignChat.emit(updatedConversation);
          }
          if(this.callConversation){
            let updatedConversation = new Call(this.callConversation);
            updatedConversation.assignee.id = this.selectedTeamMember.id;
            updatedConversation.assignee.name = this.selectedTeamMember?.name;
            this.onAssignCall.emit(updatedConversation);
          }
          this.toastr.success(resp.body.message);
          this.loaderAssignAgent = false;
        }
      },
      (error) => {
        this.toastr.error(error);
        console.log(error);
        this.loaderAssignAgent = false;
      }
    );
  }

  selectTeamMember(member: TeamMember){
    this.selectedTeamMember = member;
    this.isTeamMemberSelected = true;
    console.log(this.selectedTeamMember);
  }
  closeTeamMemberPopup(){
    this.onCancelAssign.emit(true);
  }

  getSearchedTeamMembers(pageNumber:number) {
    this.loaderTeamMemberPagination = true;
    let response = this.agentService.searchTeamMember(this.teamMemberSearchTag,pageNumber);
    response.subscribe((resp)=>{
      try {
        if(resp.body.code === 200) {
          if(resp.body.next) this.teamMemberPageNext = true;
          else this.teamMemberPageNext = false;
          if(pageNumber === 1) this.teamMemberList = [];
          for (var index = 0; index < resp.body.data?.length; index++) {
            this.teamMemberList.push(new TeamMember(resp.body.data[index]));
          }
          this.loaderTeamMember = false;
          this.loaderTeamMemberPagination = false;
          this.teamMemberPageNumber++;
        }
      } catch (exception) {
      }
    },(error)=>{
      console.log(error);
      this.loaderTeamMember = false;
      this.loaderTeamMemberPagination = false;
    });
  }
  
  searchTeamMembers(event: any){
    setTimeout(() => {
      if(this.prevTeamMemberSearchTag !== this.teamMemberSearchTag && this.teamMemberSearchTag?.length > 2) {
        this.teamMemberPageNumber = 1;
        this.teamMemberPageNext = true;
        this.prevTeamMemberSearchTag = this.teamMemberSearchTag;
        this.getSearchedTeamMembers(1);
      } else if(this.prevTeamMemberSearchTag !== this.teamMemberSearchTag && this.prevTeamMemberSearchTag?.length > 2) {
        this.teamMemberPageNumber = 1;
        this.teamMemberPageNext = true;
        this.prevTeamMemberSearchTag = this.teamMemberSearchTag;
        this.getTeamMember(1);
      }
    }, 300);
  }

  unassignConversation() {
    this.loaderUnassignAgent = true;
    let obj: Record<string,any> = {
      un_assign: true,
    };
    if(this.conversation) obj['conversation_id'] = this.conversation?.id;
    if(this.callConversation) obj['conversation_id'] = this.callConversation?.conversation;
    if(this.callConversation) obj['voice_assignee'] = true;
    this.chatsService.assignChat(obj).subscribe(
      (resp) => {
        if (resp.body.code == 200) {if(this.conversation){
          let updatedConversation = new Conversation(this.conversation);
          updatedConversation.assignee = new Assignee(null);
          this.onAssignChat.emit(updatedConversation);
        }
        if(this.callConversation){
          let updatedConversation = new Call(this.callConversation);
          updatedConversation.assignee = new CallAssignee(null);
          this.onAssignCall.emit(updatedConversation);
        }
          this.toastr.success(resp.body.message);
          this.loaderUnassignAgent = false;
        }
      },
      (error) => {
        this.toastr.error(error);
        console.log(error);
        this.loaderUnassignAgent = false;
      }
    );
  }
}
