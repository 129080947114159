import { Pipe, PipeTransform } from '@angular/core';
import { Template } from '../models/template';
import { TemplateHeaderTypes, TemplateTypes } from '../constants';

@Pipe({
  name: 'templateType'
})
export class TemplateTypePipe implements PipeTransform {

  transform(template: Template): string {
    if(template?.carousel_cards?.length > 0) return TemplateTypes.carousel;
    else if(template?.limited_time_offer?.length > 0) return TemplateTypes.lto;
    else if(template?.coupon_code?.length > 0) return TemplateTypes.offer_code;
    else if(template?.catalog_button_text?.length > 0) return TemplateTypes.catalog;
    else if(template?.mpm_button_text?.length > 0) return TemplateTypes.products;
    else if(template?.header_type === TemplateHeaderTypes.location) return TemplateTypes.location;
    else if(template?.header_type === TemplateHeaderTypes.image || template?.header_type === TemplateHeaderTypes.video || template?.header_type === TemplateHeaderTypes.document) return TemplateTypes.media;
    else return TemplateTypes.text;
  }

}
