import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSettings } from 'src/app/app.settings';
import { LoggedUser } from 'src/app/models/logged-user';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-razorpay',
  templateUrl: './razorpay.component.html',
  styleUrls: ['./razorpay.component.css'],
  providers: [WindowRefService]
})
export class RazorpayComponent implements OnInit {

  user: LoggedUser = new LoggedUser(JSON.parse(localStorage.getItem('user')!));
  build = environment.configuration;

  constructor(
    private subscriptionService: SubscriptionService,
    private winRef: WindowRefService,
    private _toast: ToasterService,
    private sharedService: SharedDataService,
  ) { }
  ngOnInit(): void { }

  @Output() paymentId = new EventEmitter<any>();
  @Output() paymentInitiated = new EventEmitter<boolean>();
  @Output() paymentCancelled = new EventEmitter<boolean>()
  @Input() subscriptionType!: string;
  @Input() planId !: number;
  @Input() coversationTopup: number = 0;
  @Input() amount!: number;
  @Input() buttonText: string = 'Pay Now';
  @Input() billingId!: number;
  @Input() duration: string = 'monthly';
  orderId!: string;
  isLoading: boolean = false;
  rzpPaymentId!: string;

  createOrder() {
    let input = {
      plan_id: this.planId,
      subscription_type: this.subscriptionType,
      billing_id : this.billingId,
      duration: this.duration,
      currency: this.sharedService.business?.currency?.code,
    }
    console.log(input)
    this.isLoading = true;
    this.subscriptionService.createSubscriptionOrder(input).subscribe({
      next: resp => {
        this.orderId = resp.body.data.order_id;
        this.rzpPaymentId = resp.body.data.payment_id;
        this.payWithRazor(resp.body.data.payment_id);
        console.log(resp);
        this.isLoading = false;
        this.paymentInitiated.emit(true);
      },
      error: error => { 
        this.isLoading = false; 
        this._toast.error(error);
      }
    })
  }

  payWithRazor(order_id: string) {
    const options: any = {
      key: environment.razorPayID,
      currency: this.sharedService.business?.currency?.code,
      name: this.build,
      description: "Test Transaction",
      order_id: order_id,
      // callback_url: `${AppSettings.API_BASE_URL}/subscription/order`,
      // redirect: true,
      prefill: {
        name: this.user.name,
        email: this.user?.email,
      },
      notes: {
        address: "None",
      },
      theme: {
        color: "var(--primary-color)",
      },
    
    };
    console.log(options);
    options.handler = ((response: any, error: any) => {
      options.response = response;
      console.log(response);
      options.error = error;
      //this._toast.error(error);
      let input = {
        order_id: this.orderId,
        payment_id: this.rzpPaymentId,
      };
      this.paymentId.emit(input)
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal = {};
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress 
      console.log('Transaction cancelled.');
      // this._toast.error('Transaction cancelled');
      // this.paymentCancelled.emit(true);
      let input = {
        order_id: this.orderId,
        payment_id: this.rzpPaymentId,
      };
      this.paymentId.emit(input)
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

}
