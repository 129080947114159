<div class="shaded-screen"></div>
<div class="confirmation-popup">
  <div class="white-card">
    <p class="popup-close"><i (click)="cancelClicked.emit()" class="fa-solid fa-x"></i></p>
    <p class="message-heading">{{heading}}</p>
    <span class="message-heading" *ngIf="checkbox">
      <mat-checkbox (change)="checkedState = !checkedState">{{checkboxText}}</mat-checkbox>
    </span>
    <div class="footer-buttons">
      <cancel-button class="cancel" [cancelButtonText]="negativeButtonText" (cancelClicked)="cancelClicked.emit(true)"></cancel-button>
      <cancel-button class="delete" [cancelButtonText]="positiveButtonText" (cancelClicked)="checkbox ? checkboxClicked.emit(checkedState) : confirmClicked.emit(true)"></cancel-button>
    </div>
  </div>
</div>