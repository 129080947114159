import { Component, Input, OnInit } from '@angular/core';
import { UserStatus } from 'src/app/constants';

@Component({
  selector: 'expiry-icon',
  templateUrl: './expiry-icon.component.html',
  styleUrls: ['./expiry-icon.component.css']
})
export class ExpiryIconComponent implements OnInit {

  @Input() name!: string | undefined;
  @Input() date!: Date | undefined;
  @Input() status = '';
  userStatus = UserStatus;
  color = [
    'var(--primary-color)',
    '#E6BA45',
    '#EC2222'
  ]
  colorIndex!: number;
  strokeLength!: number;
  styleObject = {
    'stroke': '',
    'stroke-dasharray' : '0'
  }

  constructor() { }

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.colorIndex = this.getDifferenceInHours();
    this.strokeLength = this.getStrokeLength();
    this.styleObject.stroke = this.colorIndex == -1 ? 'transparent' : this.color[this.colorIndex];
    this.styleObject['stroke-dasharray'] = this.strokeLength+ ' 162';
  }

  getFirstLetter(){
    if(this.name == undefined) return '';
    return this.name.charAt(0).toUpperCase();
  }

  getDifferenceInHours(): number{
    if(this.date == undefined){
      return -1;
    }
    let today = new Date();
    let lastCustomerMsgAt = new Date(this.date)
    let difference = (today.getTime() - lastCustomerMsgAt.getTime())/(1000*60*60);
    if(difference > 24) return -1;
    else if(difference > 18){
      return 2;
    } else if(difference > 12 && difference < 18) {
      return 1;
    } else return 0;
  }

  getStrokeLength(){
    if(this.date == undefined){
      return 0;
    }
    let today = new Date();
    let lastCustomerMsgAt = new Date(this.date)
    let difference = (today.getTime() - lastCustomerMsgAt.getTime())/(1000*60*60);
    if(difference > 24) return 0;
    else {
      return 162 - Math.ceil((difference) * 6.6);
    }
  }
}
