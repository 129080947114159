import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'date-dropdown',
  templateUrl: './date-dropdown.component.html',
  styleUrls: ['./date-dropdown.component.css']
})
export class DateDropdownComponent implements OnInit{

  @Output() change = new EventEmitter<any>();
  @Input() filter: boolean = false;
  @Input() dateFilter: boolean = false;
  filterPopup: boolean = false;
  fromDate!: Date | null;
  toDate!: Date | null;
  prevFilterType: string = 'Max';
  filterType: string = 'Max';

  constructor() {
    
  }

  ngOnInit(): void {
    this.setDates(this.filterType);
  }

  setDates(type: string) {
    let today = new Date()
    if(type === 'Today'){
      this.filterType = type;
      this.toDate = today;
      this.fromDate = new Date(today.getTime() - 24*60*60*1000);
    } else if(type === 'Yesterday') {
      this.filterType = type;
      this.toDate = new Date(today.getTime() - 24*60*60*1000);
      this.fromDate = new Date(today.getTime() - 2*24*60*60*1000);
    } else if(type === 'Last 7 days') {
      this.filterType = type;
      this.toDate = today;
      this.fromDate = new Date(today.getTime() - 7*24*60*60*1000);
    } else if(type === 'Last 30 days') {
      this.filterType = type;
      this.toDate = today;
      this.fromDate = new Date(today.getTime() - 30*24*60*60*1000);
    } else if(type === 'Last 90 days') {
      this.filterType = type;
      this.toDate = today;
      this.fromDate = new Date(today.getTime() - 90*24*60*60*1000);
    } else if(type === 'Last year') {
      this.filterType = type;
      this.toDate = today;
      this.fromDate = new Date(today.getTime() - 365*24*60*60*1000);
    } else if(type === 'Max') {
      this.filterType = type;
      this.toDate = null;
      this.fromDate = null;
    }
  }

  cancelFilter() {
    this.filterPopup = false; 
    this.filterType = this.prevFilterType; 
    this.setDates(this.filterType);
  }

  applyTimeframe() {
    this.prevFilterType = this.filterType;
    if(this.fromDate && this.toDate){
      let fromString = new Date(this.fromDate.getTime() - (this.fromDate.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
      let toString = new Date(this.toDate.getTime() - (this.toDate.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
      this.change.emit([fromString,toString]);
    } else this.change.emit(['','']);
    this.filterPopup = false;
  }
}
