import { AudienceSet } from "./audience-set";
import { Template } from "./template";

export class Campaign {
  private _id: number;
  private _parms: Parm[];
  private _created_at: Date;
  private _note: string;
  private _total_audience: number;
  private _name: string;
  private _template_name: string;
  private _scheduled_on: Date;
  private _rule_name: string;
  private _target_id: number;
  private _added_by: number;
  private _status: string;
  private _started_at: Date;
  private _completed_at: Date;
  private _template: Template;
  private _audienceset: AudienceSet;
  private _reach_count: ReachCount;
  private _bic: number;  
  private _budget: any;
  private _refund: number;
  private _remark: string;
  private _charge_on_attempt: boolean;


    public get charge_on_attempt(): boolean {
        return this._charge_on_attempt;
    }

    public set charge_on_attempt(charge_on_attempt: boolean) {
        this._charge_on_attempt = charge_on_attempt;
    }

    public get remark(): string {
        return this._remark;
    }

    public set remark(remark: string) {
        this._remark = remark;
    }


    public get refund(): number {
        return this._refund;
    }

    public set refund(refund: number) {
        this._refund = refund;
    }


  public get reach_count(): ReachCount {
    return this._reach_count;
  }

  public set reach_count(reach_count: ReachCount) {
    this._reach_count = reach_count;
  }

  public get status(): string {
    return this._status;
  }

  public set status(status: string) {
    this._status = status;
  }

  public get started_at(): Date {
    return this._started_at;
  }

  public set started_at(started_at: Date) {
      this._started_at = started_at;
  }

  public get completed_at(): Date {
    return this._completed_at;
  }

  public set completed_at(completed_at: Date) {
    this._completed_at = completed_at;
  }

  public get template(): Template {
    return this._template;
  }

  public set template(template: Template) {
      this._template = template;
  }

  public get audienceset(): AudienceSet {
    return this._audienceset;
  }

  public set audienceset(audienceset: AudienceSet) {
    this._audienceset = audienceset;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get parms(): Parm[] {
    return this._parms;
  }

  public set parms(parms: Parm[]) {
    parms?.forEach((parmsElement) => {
      this._parms.push(new Parm(parmsElement));
    });
  }

  public get created_at(): Date {
    return this._created_at;
  }

  public set created_at(created_at: Date) {
    this._created_at = created_at;
  }

  public get note(): string {
    return this._note;
  }

  public set note(note: string) {
    this._note = note;
  }

  public get total_audience(): number {
    return this._total_audience;
  }

  public set total_audience(total_audience: number) {
    this._total_audience = total_audience;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get template_name(): string {
    return this._template_name;
  }

  public set template_name(template_name: string) {
    this._template_name = template_name;
  }

  public get scheduled_on(): Date {
    return this._scheduled_on;
  }

  public set scheduled_on(scheduled_on: Date) {
    this._scheduled_on = scheduled_on;
  }

  public get rule_name(): string {
    return this._rule_name;
  }

  public set rule_name(rule_name: string) {
    this._rule_name = rule_name;
  }

  public get target_id(): number {
    return this._target_id;
  }

  public set target_id(target_id: number) {
    this._target_id = target_id;
  }

  public get added_by(): number {
    return this._added_by;
  }

  public set added_by(added_by: number) {
    this._added_by = added_by;
  }

  public get bic(): number {
    return this._bic;
  }

  public set bic(bic: number) {
      this._bic = bic;
  }

  public get budget(): any {
    return this._budget;
  }

  public set budget(budget: any) {
      this._budget = budget;
  }


  constructor(campaignJson: any) {
    this._id = campaignJson?.id;
    this._parms = [];
    campaignJson?.parms?.forEach((parmsElement: any) => {
      this._parms?.push(new Parm(parmsElement));
    });
    this._created_at = campaignJson?.created_at;
    this._note = campaignJson?.note;
    this._total_audience = campaignJson?.total_audience;
    this._name = campaignJson?.name;
    this._template_name = campaignJson?.template_name;
    this._scheduled_on = campaignJson?.scheduled_on;
    this._rule_name = campaignJson?.rule_name;
    this._target_id = campaignJson?.target_id;
    this._added_by = campaignJson?.added_by;
    this._status = campaignJson?.status;
    this._started_at = campaignJson?.started_at;
    this._completed_at = campaignJson?.completed_at;
    this._template = new Template(campaignJson?.template);
    this._audienceset = new AudienceSet(campaignJson?.audienceset);
    this._reach_count = new ReachCount(campaignJson?.reach_count);
    this._bic = campaignJson?.bic;
    this._budget = campaignJson?.budget;
    this._refund = campaignJson?.refund;
    this._remark = campaignJson?.remark;
    this._charge_on_attempt = campaignJson?.charge_on_attempt ?? false;
  }
}
export class Parameter {
  private _type: string;
  private _text: string;
  private _image: Image;
  private _document: Document;
  private _video: Video;
  private _fixed: boolean;
  private _field_name: string;


    public get fixed(): boolean {
        return this._fixed;
    }

    public set fixed(fixed: boolean) {
        this._fixed = fixed;
    }

    public get field_name(): string {
        return this._field_name;
    }

    public set field_name(field_name: string) {
        this._field_name = field_name;
    }


  public get type(): string {
    return this._type;
  }

  public set type(type: string) {
    this._type = type;
  }

  public get text(): string {
    return this._text;
  }

  public set text(text: string) {
    this._text = text;
  }

  public get image(): Image {
    return this._image;
  }

  public set image(image: Image) {
    this._image = new Image(image);
  }

  public get document(): Document {
    return this._document;
  }

  public set document(document: Document) {
    this._document = new Document(document);
  }

  public get video(): Video {
    return this._video;
  }

  public set video(video: Video) {
    this._video = new Video(video);
  }

  constructor(parameterJson: any) {
    this._type = parameterJson?.type;
    this._text = parameterJson?.text;
    this._image = parameterJson?.image;
    this._document = parameterJson?.document;
    this._video = parameterJson?.video;
    this._field_name = parameterJson?.field_name;
    this._fixed = parameterJson?.fixed;
  }
}

export class Parm {
  private _type: string;
  private _parameters: Parameter[];

  public get type(): string {
    return this._type;
  }

  public set type(type: string) {
    this._type = type;
  }

  public get parameters(): Parameter[] {
    return this._parameters;
  }

  public set parameters(parameters: Parameter[]) {
    parameters?.forEach((parameter: Parameter) => {
      this._parameters.push(parameter);
    });
  }

  constructor(parmJson: Parm) {
    this._type = parmJson?.type;
    this._parameters = parmJson?.parameters;
  }
}

export class Image {
  private _link: string;

  public get link(): string {
    return this._link;
  }

  public set link(link: string) {
    this._link = link;
  }

  constructor(imageJson: any) {
    this._link = imageJson?.link;
  }
}

export class Video {
  private _link: string;

  public get link(): string {
    return this._link;
  }

  public set link(link: string) {
    this._link = link;
  }

  constructor(imageJson: any) {
    this._link = imageJson?.link;
  }
}

export class Document {
  private _link: string;
  private _filename: string;

  public get filename(): string {
    return this._filename;
  }

  public set filename(filename: string) {
    this._filename = filename;
  }

  public get link(): string {
    return this._link;
  }

  public set link(link: string) {
    this._link = link;
  }

  constructor(imageJson: any) {
    this._link = imageJson?.link;
    this._filename = imageJson?.filename;
  }
}

export class ReachCount {
  private _sent: number;
  private _read: number;
  private _delivered: number;
  private _failed: number;
  private _total: number;
  private _queued: number;

    public get queued(): number {
        return this._queued;
    }

    public set queued(queued: number) {
        this._queued = queued;
    }


  public get sent(): number {
    return this._sent;
  }

  public set sent(sent: number) {
    this._sent = sent;
  }

  public get read(): number {
    return this._read;
  }

  public set read(read: number) {
    this._read = read;
  }

  public get delivered(): number {
    return this._delivered;
  }

  public set delivered(delivered: number) {
    this._delivered = delivered;
  }

  public get failed(): number {
    return this._failed;
  }

  public set failed(failed: number) {
    this._failed = failed;
  }

  public get total(): number {
    return this._total;
  }

  public set total(total: number) {
    this._total = total;
  }

  constructor(reachCountJson: any) {
    this._sent = reachCountJson?.sent;
    this._delivered = reachCountJson?.delivered;
    this._failed = reachCountJson?.failed;
    this._read = reachCountJson?.read;
    this._total = reachCountJson?.total;
    this._queued = reachCountJson?.queued ?? 0;
  }
}