<div class="customer-profile-side-window" id="side-window">
  <div class="white-card">
    <div class="white-card-heading">
      <i class="fas fa-xmark" (click)="closeProfile()"></i>
      <span class="customer-icon">{{ (customer | name).charAt(0).toUpperCase() }}</span>
      <div class="customer-name-number">
        <p class="customer-name">{{ customer | name }}</p>
        <p class="customer-number">{{ customer?.mobile | numberFormat}}</p>
      </div>
      <button class="action-button" (click)="openConversation()">
        <mat-icon svgIcon="whatsapp_grey"></mat-icon>
      </button>
      <!-- <button class="action-button" (click)="callCustomer()">
        <mat-icon svgIcon="calls"></mat-icon>
      </button> -->
      <div class="dropdown">
        <button
          class="btn btn-custom-dropdown dropdown-toggle actions-dropdown"
          type="button"
          id="actionsDropdownMenu"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        ><i class="fa-solid fa-ellipsis-vertical"></i>
        </button>
        <ul
          class="dropdown-menu custom-dropdown-menu actions-dropdown-menu"
          aria-labelledby="actionsDropdownMenu"
        >
          <!-- <li class="actions-dropdown-item" 
          (click)="closeConfirm()"
          >
            <mat-icon svgIcon="close_conversation_black"></mat-icon>
            <span>Close Chat</span>
          </li> -->
          <li class="actions-dropdown-item" 
          (click)="!customer.is_block ? (isBlockCustomer = true) : blockCustomer()"
          >
            <mat-icon svgIcon="block_red"></mat-icon>
            <span *ngIf="!customer?.is_block" class="block-customer">Block Contact</span>
            <span *ngIf="customer?.is_block" class="block-customer">Unblock Contact</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="white-card-body">
      <div class="labels-card">
        <p class="card-heading">Labels</p>
        <div class="labels-list">
          <div *ngFor="let label of customer?.labels">
            <label-component [labelElement]="label | label"></label-component>
          </div>
          <span style="cursor: pointer;" (click)="addLabel()"><mat-icon svgIcon="circle_plus_green"></mat-icon></span>
        </div>
      </div>
      <div class="info-card">
        <div class="card-heading gap">
          <span>Info</span>
          <span class="customer-details-edit-button" (click)="editCustomer()"><i class="fa-solid fa-pen"></i></span>
        </div>
        <div class="card-fields">
          <p class="card-fields-heading">Name</p>
          <p class="card-fields-value">{{ customer | name }}</p>
        </div>
        <div class="card-fields">
          <p class="card-fields-heading">Email Address</p>
          <p class="card-fields-value">{{ customer?.email ? customer?.email : '-' }}</p>
        </div>
        <div class="card-fields">
          <p class="card-fields-heading">Date of Birth</p>
          <p class="card-fields-value">{{ customer?.date_of_birth ? (customer?.date_of_birth | ddmmyyyy) : '-' }}</p>
        </div>
        <div class="card-fields">
          <p class="card-fields-heading">Gender</p>
          <p class="card-fields-value">{{ customer?.gender ? (customer?.gender | gender)  : '-'}}</p>
        </div>
        <div class="card-fields">
          <p class="card-fields-heading">Bio</p>
          <p class="card-fields-value">{{ customer?.bio ?? '-'}}</p>
        </div>
        <div class="card-fields" *ngIf="customer?.fb_profile || customer?.linkedin_profile || customer?.twitter_profile">
          <p class="card-fields-heading" style="margin-bottom: 10px;">Social Links</p>
          <span class="customer-social-links-field">
            <mat-icon *ngIf="customer?.fb_profile" svgIcon="facebook"></mat-icon>
            <mat-icon *ngIf="customer?.linkedin_profile" svgIcon="linkedin"></mat-icon>
            <mat-icon *ngIf="customer?.twitter_profile" svgIcon="twitter"></mat-icon>
          </span>
        </div>
        <div class="card-fields" *ngFor="let custom of sharedService.customFields">
          <p class="card-fields-heading">{{custom.replaceAll('_',' ')}}</p>
          <p class="card-fields-value">{{ customer?.custom_field[custom] ? customer?.custom_field[custom] : '-' }}</p>
        </div>
      </div>
      <div class="address-card">
        <p class="card-heading">Addresses
          <span style="cursor: pointer;margin-left: 10px;" (click)="addAddress()"><mat-icon svgIcon="circle_plus_green"></mat-icon></span>
        </p>
        <div class="address-list">
          <div class="address-item" *ngFor="let address of customer?.address, let i = index">
            <span>{{ address.line_1 }}, <span *ngIf="address.line_2">{{ address.line_2 }},</span> {{ address.city }},
            {{ address.state }}, {{ address.country }},
            {{ address.pin_code }}</span>
            <span>
              <div class="dropdown">
                <button
                  class="btn btn-custom-dropdown dropdown-toggle actions-dropdown"
                  type="button"
                  id="actionsDropdownMenu"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                ><i class="fa-solid fa-ellipsis-vertical"></i>
                </button>
                <ul
                  class="dropdown-menu custom-dropdown-menu actions-dropdown-menu"
                  aria-labelledby="actionsDropdownMenu"
                >
                  <li class="actions-dropdown-item" 
                  (click)="editAddress(i)"
                  > Edit
                  </li>
                  <li class="actions-dropdown-item" 
                  (click)="isDeleteConfirm = true; deleteAddressIndex = i"
                  > Delete
                  </li>
                </ul>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <confirmation-popup
    *ngIf="isBlockCustomer"
    [heading]="blockCustomerMessage"
    [negativeButtonText]="'Cancel'"
    [positiveButtonText]="customer?.is_block ? 'Unblock' :'Block'"
    (cancelClicked)="isBlockCustomer = false"
    (confirmClicked)="blockCustomer()"
  ></confirmation-popup>
  <confirmation-popup
    *ngIf="isDeleteConfirm"
    [heading]="deleteAddressMessage"
    [negativeButtonText]="'Cancel'"
    [positiveButtonText]="'Delete'"
    (cancelClicked)="isDeleteConfirm = false"
    (confirmClicked)="deleteAddress()"
  ></confirmation-popup>
  <assign-label 
    [assignedLabelList]="assingedLabelList"
    *ngIf="isEditLabel"
    (cancelLabelEvent)="isEditLabel = false"
    (assignLabelEvent)="assignLabel($event)"
    [customerId]="customer.id"
  ></assign-label>
  <edit-customer
    *ngIf="isEditCustomer"
    [customer]="customer"
    (cancelEditEvent)="cancelEditCustomer()"
    (updateCustomerEvent)="updatedCustomer($event)"
  ></edit-customer>
  <edit-address
    *ngIf="isAddAddress || isEditAddress"
    [addressIndex]="isEditAddress ? editedAddressIndex : -1"
    [customer]="customer"
    (cancelEditEvent)="cancelEditAddress()"
    (updateCustomerEvent)="updatedCustomer($event)"
  ></edit-address>
</div>


<div class="shaded-screen" (click)="closeProfile()"></div>