<div class="expiry-card">
  <div class="outer-circle">
    <div class="inner-circle">
      <span>{{ getFirstLetter() }}</span>
    </div>
  </div>
  <span *ngIf="status === userStatus.available || status === userStatus.away || status === userStatus.incall" [ngClass]="status" [matTooltip]="status">
    <span *ngIf="status === userStatus.away"></span>
  </span>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="60px" height="54px">
    <circle cx="27" cy="27" r="26" 
      stroke-linecap="round" 
      [ngStyle]="styleObject"
    />
  </svg>
</div>