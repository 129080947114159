import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.css']
})
export class ConfirmationPopupComponent implements OnInit {

  @Input() heading!: string;
  @Input() positiveButtonText!: string;
  @Input() negativeButtonText!: string;
  @Input() checkbox: boolean = false;
  @Input() checkboxText!: string;
  @Output() checkboxClicked = new EventEmitter<boolean>();
  @Output() confirmClicked = new EventEmitter<boolean>();
  @Output() cancelClicked = new EventEmitter<boolean>();
  checkedState: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}