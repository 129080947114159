export class Template {
  private _id: number;
  private _phone_numbers: PhoneNumber[];
  private _template: string;
  private _entity_name: string;
  private _category: string;
  private _template_id: string;
  private _header: string;
  private _header_type: string;
  private _template_type: string;
  private _sub_type: string;
  private _buttons: string[];
  private _footer: string;
  private _url: string;
  private _example: string;
  private _url_name: string;
  private _created_at: Date;
  private _updated_at: Date;
  private _status: number;
  private _rejected_reason: string;
  private _added_by: number;
  private _lang_code: string;
  private _cta_website: CTAWebsite[];
  private _quality_score: string;
  private _carousel_cards: Carousel[];
  private _limited_time_offer: LimitedTimeOffer[];
  private _coupon_code: CouponCode[];
  private _catalog_button_text: string;
  private _mpm_button_text: string;

    public get catalog_button_text(): string {
        return this._catalog_button_text;
    }

    public set catalog_button_text(catalog_button_text: string) {
        this._catalog_button_text = catalog_button_text;
    }

    public get mpm_button_text(): string {
        return this._mpm_button_text;
    }

    public set mpm_button_text(mpm_button_text: string) {
        this._mpm_button_text = mpm_button_text;
    }


    public get coupon_code(): CouponCode[] {
        return this._coupon_code;
    }

    public set coupon_code(coupon_code: CouponCode[]) {
      this._coupon_code = [];
      coupon_code?.forEach((element: any) => {
        this._coupon_code.push(new CouponCode(element))
      });
    }


    public get limited_time_offer(): LimitedTimeOffer[] {
        return this._limited_time_offer;
    }

    public set limited_time_offer(limited_time_offer: LimitedTimeOffer[]) {
      this._limited_time_offer = [];
      limited_time_offer?.forEach((element: any) => {
        this._limited_time_offer.push(new LimitedTimeOffer(element))
      })
    }


    public get carousel_cards(): Carousel[] {
        return this._carousel_cards;
    }

    public set carousel_cards(carousel_cards: Carousel[]) {
        this._carousel_cards = [];
        carousel_cards?.forEach(element => {
          this._carousel_cards.push(element)
        })
    }


    public get quality_score(): string {
        return this._quality_score;
    }

    public set quality_score(quality_score: string) {
        this._quality_score = quality_score;
    }


    public get cta_website(): CTAWebsite[] {
        return this._cta_website;
    }

    public set cta_website(cta_website: CTAWebsite[]) {
      this._cta_website = []
      cta_website?.forEach((website) => {
        this._cta_website.push(new CTAWebsite(website));
      });
    }


  public get lang_code(): string {
    return this._lang_code;
  }

  public set lang_code(lang_code: string) {
    this._lang_code = lang_code;
  }


  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get phone_numbers(): PhoneNumber[] {
    return this._phone_numbers;
  }

  public set phone_numbers(phone_numbers: PhoneNumber[]) {
    this._phone_numbers = [];
    phone_numbers?.forEach((phoneNumber) => {
      this._phone_numbers.push(new PhoneNumber(phoneNumber));
    });
  }

  public get template(): string {
    return this._template;
  }

  public set template(template: string) {
    this._template = template;
  }

  public get entity_name(): string {
    return this._entity_name;
  }

  public set entity_name(entity_name: string) {
    this._entity_name = entity_name;
  }

  public get category(): string {
    return this._category;
  }

  public set category(category: string) {
    this._category = category;
  }

  public get template_id(): string {
    return this._template_id;
  }

  public set template_id(template_id: string) {
    this._template_id = template_id;
  }

  public get header(): string {
    return this._header;
  }

  public set header(header: string) {
    this._header = header;
  }

  public get header_type(): string {
    return this._header_type;
  }

  public set header_type(header_type: string) {
    this._header_type = header_type;
  }

  public get template_type(): string {
    return this._template_type;
  }

  public set template_type(template_type: string) {
    this._template_type = template_type;
  }

  public get sub_type(): string {
    return this._sub_type;
  }

  public set sub_type(sub_type: string) {
    this._sub_type = sub_type;
  }

  public get buttons(): string[] {
    return this._buttons;
  }

  public set buttons(buttons: string[]) {
    this._buttons = buttons;
  }

  public get footer(): string {
    return this._footer;
  }

  public set footer(footer: string) {
    this._footer = footer;
  }

  public get url(): string {
    return this._url;
  }

  public set url(url: string) {
    this._url = url;
  }

  public get example(): string {
    return this._example;
  }

  public set example(example: string) {
    this._example = example;
  }

  public get url_name(): string {
    return this._url_name;
  }

  public set url_name(url_name: string) {
    this._url_name = url_name;
  }

  public get created_at(): Date {
    return this._created_at;
  }

  public set created_at(created_at: Date) {
    this._created_at = created_at;
  }

  public get updated_at(): Date {
    return this._updated_at;
  }

  public set updated_at(updated_at: Date) {
    this._updated_at = updated_at;
  }

  public get status(): number {
    return this._status;
  }

  public set status(status: number) {
    this._status = status;
  }

  public get rejected_reason(): string {
    return this._rejected_reason;
  }

  public set rejected_reason(rejected_reason: string) {
    this._rejected_reason = rejected_reason;
  }

  public get added_by(): number {
    return this._added_by;
  }

  public set added_by(added_by: number) {
    this._added_by = added_by;
  }

  constructor(templateJson: any) {
    this._id = templateJson?.id;
    this._phone_numbers = [];
    templateJson?.phone_numbers?.forEach((phoneNumber: any) => {
      this._phone_numbers.push(new PhoneNumber(phoneNumber));
    });
    this._template = templateJson?.template;
    this._entity_name = templateJson?.entity_name;
    this._category = templateJson?.category;
    this._template_id = templateJson?.template_id;
    this._header = templateJson?.header;
    this._header_type = templateJson?.header_type;
    this._template_type = templateJson?.template_type;
    this._sub_type = templateJson?.sub_type;
    this._buttons = templateJson?.buttons;
    this._footer = templateJson?.footer;
    this._url = templateJson?.url;
    this._example = templateJson?.example;
    this._url_name = templateJson?.url_name;
    this._created_at = templateJson?.created_at;
    this._updated_at = templateJson?.updated_at;
    this._status = templateJson?.status;
    this._rejected_reason = templateJson?.rejected_reason;
    this._added_by = templateJson?.added_by;
    this._lang_code = templateJson?.lang_code;
    this._cta_website = [];
    templateJson?.cta_website?.forEach((website: any) => {
      this._cta_website.push(new CTAWebsite(website));
    });
    this._quality_score = templateJson?.quality_score ?? 'none';
    this._carousel_cards = [];
    templateJson?.carousel_cards?.forEach((element: any) => {
      this._carousel_cards.push(new Carousel(element))
    })
    this._limited_time_offer = [];
    templateJson?.limited_time_offer?.forEach((element: any) => {
      this._limited_time_offer.push(new LimitedTimeOffer(element))
    });
    this._coupon_code = [];
    templateJson?.coupon_code?.forEach((element: any) => {
      this._coupon_code.push(new CouponCode(element))
    });
    this._catalog_button_text = templateJson?.catalog_button_text;
    this._mpm_button_text = templateJson?.mpm_button_text;
  }
}

export class CTAWebsite {
  private _url: string
  private _text: string
  private _example: string

  constructor(ctaWebsiteJson: any) {
    this._url = ctaWebsiteJson?.url;
    this._text = ctaWebsiteJson?.text;
    this._example = ctaWebsiteJson?.example;
    
  }
    public get url(): string {
        return this._url;
    }

    public set url(url: string) {
        this._url = url;
    }

    public get text(): string {
        return this._text;
    }

    public set text(text: string) {
        this._text = text;
    }

    public get example(): string {
        return this._example;
    }

    public set example(example: string) {
        this._example = example;
    }

}

export class PhoneNumber {
  private _text: string;
  private _phone_number: string;

  public get text(): string {
    return this._text;
  }

  public set text(text: string) {
    this._text = text;
  }

  public get phone_number(): string {
    return this._phone_number;
  }

  public set phone_number(phone_number: string) {
    this._phone_number = phone_number;
  }

  constructor(phoneNumberJson: any) {
    this._phone_number = phoneNumberJson?.phone_number;
    this._text = phoneNumberJson?.text;
  }
}

export class Carousel {
  private _header_type: string
  private _example: string
  private _buttons: CarouselButton[]
  private _body_text: string
  private _body_text_example: string[]

  constructor(carouselJson: any) {
    this._header_type = carouselJson?.header_type;
    this._example = carouselJson?.example;
    this._buttons = [];
    carouselJson?.buttons?.forEach((element: any) => {
      this._buttons.push(new CarouselButton(element));
    })
    this._body_text = carouselJson?.body_text;
    this._body_text_example = carouselJson?.body_text_example;
  }

    public get header_type(): string {
        return this._header_type;
    }

    public set header_type(header_type: string) {
        this._header_type = header_type;
    }

    public get example(): string {
        return this._example;
    }

    public set example(example: string) {
        this._example = example;
    }

    public get buttons(): CarouselButton[] {
        return this._buttons;
    }

    public set buttons(buttons: CarouselButton[]) {
        this._buttons = [];
        buttons?.forEach((element) => {
          this._buttons.push(element);
        })
    }

    public get body_text(): string {
        return this._body_text;
    }

    public set body_text(body_text: string) {
        this._body_text = body_text;
    }

    public get body_text_example(): string[] {
        return this._body_text_example;
    }

    public set body_text_example(body_text_example: string[]) {
        this._body_text_example = body_text_example;
    }

}

export class CarouselButton {
  private _type: string
  private _text: string
  private _url: string
  private _example: string
  private _phone_number: string;

    public get phone_number(): string {
        return this._phone_number;
    }

    public set phone_number(phone_number: string) {
        this._phone_number = phone_number;
    }


  constructor(carouselButtonJson: any) {
    this._type = carouselButtonJson?.type;
    this._text = carouselButtonJson?.text;
    this._url = carouselButtonJson?.url;
    this._example = carouselButtonJson?.example;
    this._phone_number = carouselButtonJson?.phone_number;
  }
    public get type(): string {
        return this._type;
    }

    public set type(type: string) {
        this._type = type;
    }

    public get text(): string {
        return this._text;
    }

    public set text(text: string) {
        this._text = text;
    }

    public get url(): string {
        return this._url;
    }

    public set url(url: string) {
        this._url = url;
    }

    public get example(): string {
        return this._example;
    }

    public set example(example: string) {
        this._example = example;
    }

}

export class LimitedTimeOffer {
  private _text: string
  private _has_expiration: boolean
  private _coupon_code: string

  constructor(limitedTimeOfferJson: any) {
    this._text = limitedTimeOfferJson?.text;
    this._has_expiration = limitedTimeOfferJson?.has_expiration;
    this._coupon_code = limitedTimeOfferJson?.coupon_code;
  }

    public get text(): string {
        return this._text;
    }

    public set text(text: string) {
        this._text = text;
    }

    public get has_expiration(): boolean {
        return this._has_expiration;
    }

    public set has_expiration(has_expiration: boolean) {
        this._has_expiration = has_expiration;
    }

    public get coupon_code(): string {
        return this._coupon_code;
    }

    public set coupon_code(coupon_code: string) {
        this._coupon_code = coupon_code;
    }

}

export class CouponCode {
  private _coupon_code: string;

    public get coupon_code(): string {
        return this._coupon_code;
    }

    public set coupon_code(coupon_code: string) {
        this._coupon_code = coupon_code;
    }

    constructor(couponCodeJson: any) {
      this._coupon_code = couponCodeJson?.coupon_code;
    }
}