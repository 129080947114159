import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css'],
})

export class TimePickerComponent implements OnInit {

  @Input() hours!: string;
  @Input() minutes!: string;
  @Input() ampm!: string;

  @Output() timeChange = new EventEmitter<string>();

  time = '';

  ngOnInit(){
    console.log(this.hours);
    console.log(this.minutes);
    if(!this.hours || !this.minutes) {
      let today = new Date();
      console.log(today.getHours(),today.getMinutes());
      this.hours = this.convertToDouble((today.getHours() > 12) ? today.getHours()%12 : today.getHours());
      this.minutes = this.convertToDouble(today.getMinutes());
      if(today.getHours() >= 12) this.ampm = 'PM';
      else this.ampm = 'AM'
    } else {
      if(Number(this.hours) > 12) this.ampm = 'PM'
      else this.ampm = 'AM';
      this.hours = this.convertToDouble(Number(this.hours) > 12 ? Number(this.hours)%12 : Number(this.hours));
      this.minutes = this.convertToDouble(Number(this.minutes));
    }
    console.log(this.hours);
    console.log(this.minutes);
    this.emitTime();
  }

  decreaseHours() {
    console.log(this.hours)
    if(Number(this.hours) === 1) this.hours = '12';
    else if(Number(this.hours) === 0) this.hours = '12';
    else {
      let value = Number(this.hours);
      console.log(value)
      value = value - 1;
      this.hours = this.convertToDouble(value)
    }
    this.emitTime();
  }

  increaseHours() {
    if(Number(this.hours) === 12) this.hours = '01' ;
    else {
      let value = Number(this.hours);
      value = value + 1;
      this.hours = this.convertToDouble(value)
    }
    this.emitTime();
  }

  decreaseMinutes() {
    if(this.minutes === '00') this.minutes = '59';
    else {
      let value = Number(this.minutes);
      value = value - 1;
      this.minutes =  this.convertToDouble(value)
    }
    this.emitTime();
  }

  increaseMinutes() {
    if(this.minutes === '59') this.minutes = '00' ;
    else {
      let value = Number(this.minutes);
      value = value + 1;
      this.minutes = this.convertToDouble(value)
    }
    this.emitTime();
  }

  convertToDouble(value: number) {
    if(value > 9) return value.toString();
    else return '0'+ value;
  }

  emitTime() {
    console.log(this.hours);
    console.log(this.minutes);
    let today = new Date()
    let date  = new Date(today.getFullYear() +'-'+(today.getMonth()+1)+'-'+today.getDate()+ ' ' + this.hours+':'+this.minutes+' '+ this.ampm)
    console.log(date);
    this.timeChange.emit(date.getHours()+':'+date.getMinutes());
  }

  // hoursList = Array(12).fill(0).map((x,i)=>i+1);
  // minutesList = Array(59).fill(0).map((x,i)=>i+1);

  // changeHour(hour: number){
  //   this.hours = hour;
  //   console.log(hour);
  //   this.hourChange.emit(this.hours);
  // }
  
  // changeMinutes(minutes: number){
  //   this.minutes = minutes;
  //   console.log(minutes);
  //   this.minuteChange.emit(minutes);
  // }
}