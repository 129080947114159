<div class="hover-container">
  <span class="audience-type-info-icon"><mat-icon svgIcon="info_icon_grey"></mat-icon></span>
  <span class="info-icon-hover-details" [ngClass]="[position ?? '',bottom ?? '']">
    <p class="hover-heading" *ngIf="hoverHeading">{{hoverHeading}}</p>
    {{hoverInfo}}
    <p *ngIf="hoverLink" class="hover-link">  
      <a [href]="hoverLink" class="video-link" target="_blank">
        <mat-icon svgIcon="hover_video_green"></mat-icon> Watch video
      </a>
    </p>
  </span>
</div>