<div class="main-container">
  <div class="container-body" *ngIf="!sharedDataService.isWabaEnabled && sharedDataService.isLoggedIn">
    <span>Connect your WhatsApp account</span>
    <button routerLink="/settings/channels">
      <mat-icon svgIcon="whatsapp_logo"></mat-icon>
      Connect with WhatsApp
    </button>
  </div>
  <div class="container-body" *ngIf="sharedDataService.newVersionEnabled">
    <span>New Version Available! Click 'Refresh' to access exciting new features</span>
    <button (click)="reload()">
      <mat-icon svgIcon="refresh"></mat-icon>
      Refresh
    </button>
  </div>
  <div class="container-body dismissable" *ngIf="sharedDataService.remoteConfig.downtime">
    <span>{{sharedDataService.remoteConfig.downtime_text}}</span>
    <!-- <button (click)="sharedDataService.remoteConfig.downtime= false">Dismiss</button> -->
  </div>
  <div class="container-body subscription-expired" *ngIf="sharedDataService.business.subscription.is_expired">
    <span>Your subscription has expired, renew now to continue conversations! </span>
    <button (click)="routeToSubscription()">Renew</button>
  </div>
  <div class="container-body subscription-expired" *ngIf="!sharedDataService.business.subscription.is_expired && sharedDataService.subscriptionDaysLeft < 4">
    <span>Your subscription is expiring {{sharedDataService.subscriptionDaysLeft > 0 ? 'in '+ sharedDataService.subscriptionDaysLeft + ' days' : 'Today'}} – renew now to avoid any interruption in conversations! </span>
    <button (click)="routeToSubscription()">Renew</button>
    <button class="dismiss-button" (click)="sharedDataService.subscriptionDaysLeft = 4">Dismiss</button>
  </div>
  <div class="container-body messaging-blocked" *ngIf="sharedDataService.whatsappDetails?.health_status === healthStatus.blocked">
    <span class="info">WhatsApp Messaging Status: <span style="font-weight: 500;color: #EC2222;">{{sharedDataService.whatsappDetails.health_status}}</span></span>
    <span class="text">You are not allowed to send messages</span>
    <button [disabled]="loading" (click)="checkHealthStatus()"><mat-icon style="height: 20px;" svgIcon="refresh"></mat-icon> Check Status</button>
  </div>
</div>