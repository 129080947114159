import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'audio-stream',
  templateUrl: './audio-stream.component.html',
  styleUrls: ['./audio-stream.component.css']
})
export class AudioStreamComponent implements OnInit {

  constructor(
    public sharedDataService: SharedDataService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
  }

  @Input() audioUrl: string = '';
  @ViewChild('audioPlayer') audioPlayer!: ElementRef;
  @ViewChild('rangeInput') rangeInput!: ElementRef;

  isPlaying: boolean = false;
  currentTime: number = 0;
  duration: number = 0;

  toggleAudio() {
    if (this.audioPlayer.nativeElement.paused) {
      this.audioPlayer.nativeElement.play();
      this.isPlaying = true;
    } else {
      this.audioPlayer.nativeElement.pause();
      this.isPlaying = false;
    }
  }

  updateTime(event: any) {
    this.currentTime = event.target.currentTime;
    this.duration = event.target.duration;
    this.updateGradient();
  }

  seek(event: any) {
    const targetTime = event.target.value;
    this.audioPlayer.nativeElement.currentTime = targetTime;
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${this.pad(minutes)}:${this.pad(remainingSeconds)}`;
  }

  pad(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  updateGradient() {
    const percentage = (this.currentTime / this.duration) * 100;
    const gradient = `linear-gradient(to right, var(--primary-color) 0%, var(--primary-color) ${percentage}%, #d3d3d3 ${percentage}%, #d3d3d3 100%)`;
    this.renderer.setStyle(this.rangeInput.nativeElement, 'background', gradient);
  }
}
