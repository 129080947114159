import { Campaign } from "./campaign"
import { Template } from "./template"

export class Message {
    private _id: number
    private _message: string
    private _fb_msg_type: string
    private _header: string
    private _caption: string
    private _url: string
    private _msg_type: string
    private _sender: string
    private _receiver: string
    private _message_status: string
    private _fb_cloud_msg_id: string
    private _parms: Parms[];
    private _media_id: string
    private _mime_type: string
    private _created_at: string
    private _conversation_id: number
    private _campaign: Campaign
    private _added_by: AddedBy
    private _template: Template
    private _filename: string
    private _buttons: string[];
    private _sub_msg_type: string
    private _list_obj: ListObject
    private _cart: Cart[]
    private _section_title: string
    private _product_list: ProductList
    private _failed_reason: string;
    private _contacts: Contacts[];
    private _address_params: AddressParams;

    public get address_params(): AddressParams {
        return this._address_params;
    }

    public set address_params(address_params: AddressParams) {
        this._address_params = new AddressParams(address_params);
    }


    public get contacts(): Contacts[] {
        return this._contacts;
    }

    public set contacts(contacts: Contacts[]) {
        this._contacts = [];
        contacts?.forEach((element:Contacts)=>{
            this._contacts.push(element);
        });
    }


    constructor(messageJson:any){
        this._id = messageJson?.id;
        this._message = messageJson?.message;
        this._fb_msg_type = messageJson?.fb_msg_type;
        this._header = messageJson?.header;
        this._caption = messageJson?.caption;
        this._msg_type = messageJson?.msg_type;
        this._receiver = messageJson?.receiver;
        this._sender = messageJson?.sender;
        this._url = messageJson?.url;
        this._message_status = messageJson?.message_status;
        this._fb_cloud_msg_id = messageJson?.fb_cloud_msg_id;
        this._media_id = messageJson?.media_id;
        this._mime_type = messageJson?.mime_type;
        this._created_at = messageJson?.created_at;
        this._conversation_id = messageJson?.conversation_id;
        this._campaign = new Campaign(messageJson?.campaign);
        this._added_by = new AddedBy(messageJson?.added_by);
        this._template = new Template(messageJson?.template);
        this._filename = messageJson?.filename;
        this._buttons = messageJson?.buttons;
        this._sub_msg_type = messageJson?.sub_msg_type;
        this._list_obj = new ListObject(messageJson?.list_obj);
        this._cart = [];
        messageJson?.cart?.forEach((element: any)=>{
            this._cart.push(new Cart(element));
        });
        this._section_title = messageJson?.section_title;
        this._product_list = messageJson?.product_list;
        this._parms = [];
        messageJson?.parms?.forEach((element: any)=>{
            this._parms.push(new Parms(element));
        });
        this._added_by = new AddedBy(messageJson?.added_by);
        this._failed_reason = messageJson?.failed_reason;
        this._contacts = [];
        messageJson?.contacts?.forEach((element: any)=>{
            this._contacts.push(new Contacts(element));
        });
        this._address_params = new AddressParams(messageJson?.address_params);
    }

    public get failed_reason(): string {
        return this._failed_reason;
    }

    public set failed_reason(failed_reason: string) {
        this._failed_reason = failed_reason;
    }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get message(): string {
        return this._message;
    }

    public set message(message: string) {
        this._message = message;
    }

    public get fb_msg_type(): string {
        return this._fb_msg_type;
    }

    public set fb_msg_type(fb_msg_type: string) {
        this._fb_msg_type = fb_msg_type;
    }

    public get header(): string {
        return this._header;
    }

    public set header(header: string) {
        this._header = header;
    }

    public get caption(): string {
        return this._caption;
    }

    public set caption(caption: string) {
        this._caption = caption;
    }

    public get url(): string {
        return this._url;
    }

    public set url(url: string) {
        this._url = url;
    }

    public get msg_type(): string {
        return this._msg_type;
    }

    public set msg_type(msg_type: string) {
        this._msg_type = msg_type;
    }

    public get sender(): string {
        return this._sender;
    }

    public set sender(sender: string) {
        this._sender = sender;
    }

    public get receiver(): string {
        return this._receiver;
    }

    public set receiver(receiver: string) {
        this._receiver = receiver;
    }

    public get message_status(): string {
        return this._message_status;
    }

    public set message_status(message_status: string) {
        this._message_status = message_status;
    }

    public get fb_cloud_msg_id(): string {
        return this._fb_cloud_msg_id;
    }

    public set fb_cloud_msg_id(fb_cloud_msg_id: string) {
        this._fb_cloud_msg_id = fb_cloud_msg_id;
    }

    public get parms(): Parms[] {
        return this._parms;
    }

    public set parms(parms: Parms[]) {
        parms?.forEach((element:Parms)=>{
            this._parms.push(element);
        });
    }

    public get media_id(): string {
        return this._media_id;
    }

    public set media_id(media_id: string) {
        this._media_id = media_id;
    }

    public get mime_type(): string {
        return this._mime_type;
    }

    public set mime_type(mime_type: string) {
        this._mime_type = mime_type;
    }

    public get created_at(): string {
        return this._created_at;
    }

    public set created_at(created_at: string) {
        this._created_at = created_at;
    }

    public get conversation_id(): number {
        return this._conversation_id;
    }

    public set conversation_id(conversation_id: number) {
        this._conversation_id = conversation_id;
    }

    public get campaign(): Campaign {
        return this._campaign;
    }

    public set campaign(campaign: Campaign) {
        this._campaign = campaign;
    }

    public get template(): Template {
        return this._template;
    }

    public set template(template: Template) {
        this._template = template;
    }

    public get filename(): string {
        return this._filename;
    }

    public set filename(filename: string) {
        this._filename = filename;
    }

    public get buttons(): any {
        return this._buttons;
    }

    public set buttons(buttons: any) {
        this._buttons = buttons;
    }

    public get sub_msg_type(): string {
        return this._sub_msg_type;
    }

    public set sub_msg_type(sub_msg_type: string) {
        this._sub_msg_type = sub_msg_type;
    }

    public get list_obj(): ListObject {
        return this._list_obj;
    }

    public set list_obj(list_obj: ListObject) {
        this._list_obj = new ListObject(list_obj);
    }

    public get cart(): Cart[] {
        return this._cart;
    }

    public set cart(cart: Cart[]) {
        this._cart = [];
        cart?.forEach((element: any) => {
            this._cart.push(new Cart(element));
        })
    }

    public get section_title(): string {
        return this._section_title;
    }

    public set section_title(section_title: string) {
        this._section_title = section_title;
    }

    public get product_list(): ProductList {
        return this._product_list;
    }

    public set product_list(product_list: ProductList) {
        this._product_list = new ProductList(product_list);
    }

    public get added_by(): AddedBy {
        return this._added_by;
    }

    public set added_by(added_by: AddedBy) {
        this._added_by = added_by;
    }

  }

  export class Parms {
    private _type: string;
    private _parameters: Parameters[];

    public get type(): string {
        return this._type;
    }

    public set type(type: string) {
        this._type = type;
    }

    public get parameters(): Parameters[] {
        return this._parameters;
    }

    public set parameters(parameters: Parameters[]) {
        this._parameters = [];
        parameters?.forEach((element)=>{
            this._parameters.push(new Parameters(element));
        })
    }

    constructor(parmJson: any){
        this._type = parmJson?.type;
        this._parameters = parmJson?.parameters
    }

  }

  export class Parameters {
    private _type: string;
    private _text: string;
    private _image: string;
    private _document: string;
    private _video: string;
    private _fixed: boolean;
    private _field_name: string;

    constructor(parametersJson: any){
        this._type = parametersJson?.type;
        this._text = parametersJson?.text;
        this._image = parametersJson?.image;
        this._document = parametersJson?.document;
        this._video = parametersJson?.video;
        this._fixed = parametersJson?.fixed;
        this._field_name = parametersJson?.field_name;
    }

    public get type(): string {
        return this._type;
    }

    public set type(type: string) {
        this._type = type;
    }

    public get text(): string {
        return this._text;
    }

    public set text(text: string) {
        this._text = text;
    }

    public get image(): string {
        return this._image;
    }

    public set image(image: string) {
        this._image = image;
    }

    public get document(): string {
        return this._document;
    }

    public set document(document: string) {
        this._document = document;
    }

    public get video(): string {
        return this._video;
    }

    public set video(video: string) {
        this._video = video;
    }

    public get fixed(): boolean {
        return this._fixed;
    }

    public set fixed(fixed: boolean) {
        this._fixed = fixed;
    }

    public get field_name(): string {
        return this._field_name;
    }

    public set field_name(field_name: string) {
        this._field_name = field_name;
    }

  }

  export class Cart {
    private _currency: string
    private _quantity: number
    private _item_price: number
    private _product_retailer_id: string

    constructor(cartJson: any){
        this._currency = cartJson?.currency;
        this._quantity = cartJson?.quantity;
        this._item_price = cartJson?.item_price;
        this._product_retailer_id = cartJson?.product_retailer_id;
    }

    public get currency(): string {
        return this._currency;
    }

    public set currency(currency: string) {
        this._currency = currency;
    }

    public get quantity(): number {
        return this._quantity;
    }

    public set quantity(quantity: number) {
        this._quantity = quantity;
    }

    public get item_price(): number {
        return this._item_price;
    }

    public set item_price(item_price: number) {
        this._item_price = item_price;
    }

    public get product_retailer_id(): string {
        return this._product_retailer_id;
    }

    public set product_retailer_id(product_retailer_id: string) {
        this._product_retailer_id = product_retailer_id;
    }

  }

  export class AddedBy {
    private _name: string;
    private _id: number;

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    constructor(addedByJson: any) {
        this._id = addedByJson?.id;
        this._name = addedByJson?.name;
    }
  }

export class Row {
    private _id: string;
    private _title: string;
    private _description: string;

    public get description(): string {
        return this._description;
    }

    public set description(description: string) {
        this._description = description;
    }

    public get id(): string {
        return this._id;
    }

    public set id(id: string) {
        this._id = id;
    }

    public get title(): string {
        return this._title;
    }

    public set title(title: string) {
        this._title = title;
    }

    constructor(rowJson: any) {
        this._id = rowJson?.id;
        this._title = rowJson?.title;
        this._description = rowJson?.description;
    }

}

export class Section {
    private _rows: Row[];
    private _title: string;

    public get rows(): Row[] {
        return this._rows;
    }

    public set rows(rows: Row[]) {
        this._rows = [];
        rows?.forEach((row)=>{
            this._rows.push(new Row(row));
        });
    }

    public get title(): string {
        return this._title;
    }

    public set title(title: string) {
        this._title = title;
    }

    constructor(sectionJson: any){
        this._rows = [];
        sectionJson?.rows?.forEach((row: any)=>{
            this._rows.push(new Row(row));
        });
        this._title = sectionJson?.title;
    }

}

export class ListObject {
    private _button: string;
    private _sections: Section[];

    public get sections(): Section[] {
        return this._sections;
    }

    public set sections(sections: Section[]) {
        this._sections = [];
        sections?.forEach((section)=>{
            this._sections.push(new Section(section));
        });
    }

    public get button(): string {
        return this._button;
    }

    public set button(button: string) {
        this._button = button;
    }

    constructor(listObjectJson: any){
        this._sections = [];
        listObjectJson?.sections?.forEach((section: any)=>{
            this._sections.push(new Section(section));
        });
        this._button = listObjectJson?.button;
    }
}

export class ProductItem {
    private _product_retailer_id: string;

    public get product_retailer_id(): string {
        return this._product_retailer_id;
    }

    public set product_retailer_id(product_retailer_id: string) {
        this._product_retailer_id = product_retailer_id;
    }

    constructor(productItemJson: any){
        this._product_retailer_id =  productItemJson?.product_retailer_id;
    }

}

export class ProductSection {
    private _title: string;
    private _product_items: ProductItem[];

    public get title(): string {
        return this._title;
    }

    public set title(title: string) {
        this._title = title;
    }

    public get product_items(): ProductItem[] {
        return this._product_items;
    }

    public set product_items(product_items: ProductItem[]) {
        this._product_items = [];
        product_items?.forEach((productItem: any)=>{
            this._product_items.push(new ProductItem(productItem));
        });
    }

    constructor(productSectionJson: any){
        this._title = productSectionJson?.title;
        this._product_items = [];
        productSectionJson?.product_items?.forEach((element: any)=>{
            this._product_items.push(new ProductItem(element));
        });
    }

}

export class ProductList {
    private _sections: ProductSection[];
    private _catalog_id: string;
    private _product_retailer_id: string;

    public get sections(): ProductSection[] {
        return this._sections;
    }

    public set sections(sections: ProductSection[]) {
        this._sections = [];
        sections?.forEach((section: any)=>{
            this._sections.push(new ProductSection(section));
        });
    }

    public get catalog_id(): string {
        return this._catalog_id;
    }

    public set catalog_id(catalog_id: string) {
        this._catalog_id = catalog_id;
    }

    public get product_retailer_id(): string {
        return this._product_retailer_id;
    }

    public set product_retailer_id(product_retailer_id: string) {
        this._product_retailer_id = product_retailer_id;
    }

    constructor(productListJson : any){
        this._product_retailer_id = productListJson?.product_retailer_id;
        this._catalog_id = productListJson?.catalog_id;
        this._sections = [];
        productListJson?.sections?.forEach((element: any)=>{
            this._sections.push(new ProductSection(element))
        });
    }
}

export class Contacts {
  private _phones: Phone[]
  private _name: Name

    public get phones(): Phone[] {
        return this._phones;
    }

    public set phones(phones: Phone[]) {
        this._phones = phones;
    }

    public get name(): Name {
        return this._name;
    }

    public set name(name: Name) {
        this._name = name;
    }

    constructor(contactJson: any) {
        this._name = contactJson?.name;
        this._phones = [];
        contactJson?.phones?.forEach((element: any) => {
            this._phones.push(new Phone(element));
        })
    }

}

export class Phone {
  private _phone: string
  private _type: string

    public get phone(): string {
        return this._phone;
    }

    public set phone(phone: string) {
        this._phone = phone;
    }

    public get type(): string {
        return this._type;
    }

    public set type(type: string) {
        this._type = type;
    }

    constructor(phoneJson: any) {
        this._phone = phoneJson?.phone;
        this._type = phoneJson?.type;
    }

}

export class Name {
  private _first_name: string
  private _formatted_name: string

    public get first_name(): string {
        return this._first_name;
    }

    public set first_name(first_name: string) {
        this._first_name = first_name;
    }

    public get formatted_name(): string {
        return this._formatted_name;
    }

    public set formatted_name(formatted_name: string) {
        this._formatted_name = formatted_name;
    }

    constructor(nameJson: any) {
        this._first_name = nameJson?.first_name;
        this._formatted_name = nameJson?.formatted_name;
    }

}

export class AddressParams {
    private _values: Values
    private _country: string

    public get values(): Values {
        return this._values;
    }

    public set values(values: Values) {
        this._values = new Values(values);
    }

    public get country(): string {
        return this._country;
    }

    public set country(country: string) {
        this._country = country;
    }

    constructor(addressParametersJson: any) {
        this._values = addressParametersJson?.values;
        this._country = addressParametersJson?.country;
    }
  }
  
  export class Values {
    private _city: string
    private _name: string
    private _state: string
    private _address: string
    private _in_pin_code: string
    private _floor_number: string
    private _house_number: string
    private _phone_number: string
    private _tower_number: string
    private _building_name: string
    private _landmark_area: string

    public get city(): string {
        return this._city;
    }

    public set city(city: string) {
        this._city = city;
    }

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get state(): string {
        return this._state;
    }

    public set state(state: string) {
        this._state = state;
    }

    public get address(): string {
        return this._address;
    }

    public set address(address: string) {
        this._address = address;
    }

    public get in_pin_code(): string {
        return this._in_pin_code;
    }

    public set in_pin_code(in_pin_code: string) {
        this._in_pin_code = in_pin_code;
    }

    public get floor_number(): string {
        return this._floor_number;
    }

    public set floor_number(floor_number: string) {
        this._floor_number = floor_number;
    }

    public get house_number(): string {
        return this._house_number;
    }

    public set house_number(house_number: string) {
        this._house_number = house_number;
    }

    public get phone_number(): string {
        return this._phone_number;
    }

    public set phone_number(phone_number: string) {
        this._phone_number = phone_number;
    }

    public get tower_number(): string {
        return this._tower_number;
    }

    public set tower_number(tower_number: string) {
        this._tower_number = tower_number;
    }

    public get building_name(): string {
        return this._building_name;
    }

    public set building_name(building_name: string) {
        this._building_name = building_name;
    }

    public get landmark_area(): string {
        return this._landmark_area;
    }

    public set landmark_area(landmark_area: string) {
        this._landmark_area = landmark_area;
    }

    constructor(valuesJson: any) {
        this._city = valuesJson?.city
        this._name = valuesJson?.name
        this._state = valuesJson?.state
        this._address = valuesJson?.address
        this._in_pin_code = valuesJson?.in_pin_code
        this._floor_number = valuesJson?.floor_number
        this._house_number = valuesJson?.house_number
        this._phone_number = valuesJson?.phone_number
        this._tower_number = valuesJson?.tower_number
        this._building_name = valuesJson?.building_name
        this._landmark_area = valuesJson?.landmark_area
    }
  }
  