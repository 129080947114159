<div style="position: relative;">
  <div class="template-preview-container">
    <div class="container-heading">
      <div class="status-bar">
        <span>9:30</span>
        <span><mat-icon svgIcon="mobile_icons"></mat-icon></span>
      </div>
      <div class="info-bar">
        <span>
          <i class="fa-solid fa-arrow-left"></i>
          <img [src]="sharedDataService.whatsappDetails?.profile_picture_url" uiImageLoader alt="">
          <span>{{sharedDataService?.whatsappDetails?.display_name}}</span>
        </span>
        <i class="fa-solid fa-ellipsis-vertical"></i>
      </div>
    </div>
    <div class="container-body">
      <div class="today">
        <span>Today</span>
      </div>
      <div class="template-body">
        <div class="preview-white-box" *ngIf="!template?.carousel_cards || template?.carousel_cards?.length == 0">
          <div class="products-container" *ngIf="template?.catalog_button_text && template.catalog_button_text.length > 0">
            <span class="icon"><mat-icon svgIcon="catalog"></mat-icon></span>
            <div class="product-details">
              <span class="title">{{sharedDataService?.whatsappDetails?.display_name}}</span>
              <span class="description">Browse pictures and details</span>
            </div>
          </div>
          <div class="products-container" *ngIf="template?.mpm_button_text && template.mpm_button_text.length > 0">
            <span class="icon"><mat-icon svgIcon="products_light"></mat-icon></span>
            <div class="product-details">
              <span class="title">{{template?.header}}</span>
              <span class="description">{{productsCount}} items</span>
            </div>
          </div>
          <div class="template-image-container" *ngIf="(template?.example !== '' && template?.example) && template?.header_type === 'IMAGE'">
            <img uiImageLoader [src]="template?.example" />
            <!-- <mat-icon svgIcon="broken_image_grey"></mat-icon> -->
          </div>
          <div class="template-location-container" *ngIf="(template?.example === '' || !template?.example) && template?.header_type === 'IMAGE'">
            <mat-icon svgIcon="photo_camera"></mat-icon>
          </div>
          <div class="template-location-container" *ngIf="(template?.example === '' || !template?.example) && template?.header_type === 'VIDEO'">
            <mat-icon svgIcon="video_camera_back"></mat-icon>
          </div>
          <div class="template-location-container" *ngIf="(template?.example === '' || !template?.example) && template?.header_type === 'DOCUMENT'">
            <mat-icon svgIcon="article"></mat-icon>
          </div>
          <div
            class="preview-header-document"
            *ngIf="
            template?.header_type === 'DOCUMENT' && (template?.example !== '' && template?.example)
            "
          >
            <i
              class="fa-solid fa-file-lines"
              style="color: #167ee6"
              *ngIf="(template?.example | afterDot) === 'docx'"
            ></i>
            <i
              class="fa-solid fa-file-pdf"
              style="color: #e2574c"
              *ngIf="(template?.example | afterDot) === 'pdf'"
            ></i>
            <i
              class="fa-solid fa-file-powerpoint"
              style="color: #ffd500"
              *ngIf="(template?.example | afterDot) === 'pptx'"
            ></i>
            <span class="preview-header-document-name">{{
              template?.example | afterSlash
            }}</span>
          </div>
          <div
            class="preview-header-video"
            *ngIf="template?.header_type === 'VIDEO' && (template?.example !== '' && template?.example)"
          >
            <video
              controls
              #videoPlayer
              [src]="template?.example"
              type="video/mp4"
            ></video>
          </div>
          <ng-container *ngIf="template?.header_type === 'LOCATION'">
            <div class="template-location-container" *ngIf=" !location || ((location.latitude === '' || location.longitude === ''))">
              <mat-icon svgIcon="location_light"></mat-icon>
            </div>
            <div class="template-location-send-container" *ngIf="location && !(location.latitude === '' || location.longitude === '')">
              <map-preview [latitude]="location.longitude" [longitude]="location.latitude"></map-preview>
            </div>
          </ng-container>
  
          <div class="lto-offer-container" *ngIf="template?.limited_time_offer && template.limited_time_offer.length > 0">
            <span class="lto-icon">
              <mat-icon svgIcon="redeem"></mat-icon>
            </span>
            <div class="lto-body">
              <div class="lto-heading">{{template.limited_time_offer[0]?.text}}</div>
              <div class="lto-end-time" *ngIf="template.limited_time_offer[0]?.has_expiration">Offer ends on {{expiryDate | ddmmyyyy}}</div>
              <div class="lto-coupon">Code {{template.limited_time_offer[0].coupon_code}}</div>
            </div>
          </div>
  
          <div class="header-preview" *ngIf="template?.header && (template?.mpm_button_text?.length === 0)">
            <span class="header-preview-text">  {{getHeaderText()}}
            </span>
          </div>
          <div
            class="body-preview"
            [innerHTML]="(getBodyText() | boldItalic)"
          ></div>
          <div class="footer-preview" *ngIf="template?.footer">
            <span class="footer-preview-text"
              ><small>{{ template?.footer }}</small></span
            >
          </div>
          <div class="w-100" style="margin-top: 4px;" 
            *ngIf="
            template?.limited_time_offer && template.limited_time_offer.length > 0 || 
            template?.cta_website && template.cta_website.length > 0 ||
            template?.phone_numbers && template.phone_numbers.length > 0 || 
            template?.coupon_code && template.coupon_code.length > 0 ||
            template?.buttons && template.buttons.length > 0 ||
            template?.catalog_button_text && template.catalog_button_text.length > 0 ||
            template?.mpm_button_text && template.mpm_button_text.length > 0
          ">
            <div
              class="visit-preview"
              *ngFor="let button of getButtonsList();let i = index"
              [ngStyle]="{'cursor' : button.type === 'SEE_MORE' ? 'pointer' : 'default'}"
              (click)="button.type === 'SEE_MORE' ? (buttonsPreview = true) : ''"
            >
              <ng-container *ngIf="button.type !== 'SEE_MORE'">
                <i *ngIf="button.type === 'URL'" class="fa-solid fa-arrow-up-right-from-square"></i>
                <i *ngIf="button.type === 'PHONE_NUMBER'" class="fa-solid fa-phone"></i>
                <ng-container *ngIf="button.type === 'COUPON_CODE'">
                  <mat-icon svgIcon="copy_light"></mat-icon>
                </ng-container>
                <ng-container *ngIf="button.type === 'QUICK_REPLY'">
                  <mat-icon svgIcon="quick_reply"></mat-icon>
                </ng-container>
                <span class="visit-preview-text">
                  {{ button?.text }}
                </span>
              </ng-container>
              <ng-container *ngIf="button.type === 'SEE_MORE'">
                <mat-icon *ngIf="button.type === 'SEE_MORE'" svgIcon="list"></mat-icon>
                <span class="visit-preview-text">
                  {{ button?.text }}
                </span>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="carousel-container" *ngIf="template?.carousel_cards && template.carousel_cards.length > 0 ">
          <div class="preview-white-box">
            <div
              class="body-preview"
              [innerHTML]="(getBodyText() | boldItalic)"
            ></div>
          </div>
          <div class="carousel-preview-white-box">
            <div class="carousel-cards-container">
              <div class="carousel-card" *ngFor="let card of template.carousel_cards; let cardIndex = index">
                <div class="white-card">
                  <div class="template-carousel-image-container" *ngIf="(card.example !== '' && card?.example) && card?.header_type === 'IMAGE'">
                    <img uiImageLoader [src]="card?.example" />
                    <!-- <mat-icon svgIcon="broken_image_grey"></mat-icon> -->
                  </div>
                  <div class="template-location-container" *ngIf="(card?.example === '' || !card?.example) && card?.header_type === 'IMAGE'">
                    <mat-icon svgIcon="photo_camera"></mat-icon>
                  </div>
                  <div class="template-location-container" *ngIf="(card?.example === '' || !card?.example) && card?.header_type === 'VIDEO'">
                    <mat-icon svgIcon="video_camera_back"></mat-icon>
                  </div>
                  <div
                    class="template-carousel-image-container"
                    *ngIf="card?.header_type === 'VIDEO' && (card?.example !== '' && card?.example)"
                  >
                    <video
                      controls
                      #videoPlayer
                      [src]="card?.example"
                      type="video/mp4"
                    ></video>
                  </div>
                  <div
                    class="body-preview"
                    [innerHTML]="(getCarouselBodyText(cardIndex) | boldItalic)"
                  ></div>
                  <div
                    class="visit-preview"
                    *ngFor="let button of card.buttons"
                  >
                    <i *ngIf="button?.url" class="fa-solid fa-arrow-up-right-from-square"></i>
                    <mat-icon *ngIf="!(button?.url || button?.phone_number)" svgIcon="quick_reply"></mat-icon>
                    <i *ngIf="button?.phone_number" class="fa-solid fa-phone"></i>
                    <span class="visit-preview-text">
                      {{ button?.text }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="template-footer">
        <img src="../../../../assets/images/template-footer.png" alt="">
      </div>
    </div>
  </div>
  
  <ng-container *ngIf="buttonsPreview">
    <div class="shaded-screen" (click)="buttonsPreview = false"></div>
    <div class="buttons-preview">
      <span class="bar"></span>
      <div class="buttons-preview-heading">
        <span style="cursor: pointer;" (click)="buttonsPreview = false"><i class="fas fa-xmark"></i></span>
        <span>All options</span>
        <span><i style="color: #FFFFFF;" class="fas fa-xmark"></i></span>
      </div>
      <div class="buttons-preview-body">
        <div class="button-text" *ngFor="let button of (template | templateButtons : true)">
          <i *ngIf="button.type === 'URL'" class="fa-solid fa-arrow-up-right-from-square"></i>
          <i *ngIf="button.type === 'PHONE_NUMBER'" class="fa-solid fa-phone"></i>
          <mat-icon *ngIf="button.type === 'COUPON_CODE'" svgIcon="copy_light"></mat-icon>
          <mat-icon *ngIf="button.type === 'QUICK_REPLY'" svgIcon="quick_reply"></mat-icon>
          <span>
            {{ button?.text }}
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</div>