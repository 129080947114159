import { Component, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { WebSocketService } from './services/web-socket.service';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
import { ToasterService } from './services/toastr.service';
import { SharedDataService } from './services/shared-data.service';
import { LoggedUser } from './models/logged-user';
import { SubscriptionService } from './services/subscription.service';
import { Business } from './models/business';
import { environment } from 'src/environments/environment';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { CommonService } from './services/common.service';
import { Title } from '@angular/platform-browser';
import { WorkerService } from './services/worker.service';

import posthog from 'posthog-js'

posthog.init(environment.posthog, { api_host: 'https://app.posthog.com' })

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  build = environment.configuration
  title = 'sobot-app';
  status!: OnlineStatusType;
  onlineStatusCheck: any = OnlineStatusType;
  user: LoggedUser = new LoggedUser(JSON.parse(localStorage.getItem('user')!));
  business: Business = new Business(JSON.parse(localStorage.getItem('business')!));

  constructor(
    private websocketService: WebSocketService,
    private router: Router,
    private onlineStatusService: OnlineStatusService,
    public toastr: ToasterService,
    public sharedService: SharedDataService,
    public subscriptionService: SubscriptionService,
    private _commonService: CommonService,
    private remoteConfig: AngularFireRemoteConfig,
    private renderer: Renderer2,
    public titleService: Title,
    public workerService: WorkerService, //Don't remove this line
  ) {
    titleService.setTitle(this.build);
    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      // Retrieve Online status Type
      console.log('Internet Connection ' + status);
      this.status = status;
      if (status) {
        websocketService.setInternetConnection(true);
      } else {
        websocketService.setInternetConnection(false);
      }
    });
  }

  ngOnInit(): void {
    this.subscriptionService.setSubscription(this.business);
    setInterval(() => {
      let token = localStorage.getItem('token');
      if (token && this.websocketService.websocketOpened)
        this.checkWebsocketConnection();
    }, 15 * 1000);
    if(localStorage.getItem('token')) {
      this._commonService.getBusiness();
      this._commonService.getWhatsAppDetails();
      // this._commonService.getInboxes();
    }
    this.remoteConfig.fetchAndActivate().then(() => {
      console.log('Remote Config activated');

      //For downtime of system
      this.getValueByKey('is_downtime_now').then(resp => {
        console.log(typeof resp?._value)
        console.log("is downtime now: "+ resp?._value);
        if(resp?._value === 'true') {
          this.sharedService.remoteConfig.downtime = true;
          this.getValueByKey('downtime_text').then(resp => {
            if(resp?._value) {
              this.sharedService.remoteConfig.downtime_text = resp?._value;
            }
          }).catch( error => {
            console.log(error);
          })
        }
      }).catch( error => {
        console.log(error);
      })

      //Frill widget enabling and disabling
      this.getValueByKey('frill').then(resp => {
        console.log(typeof resp?._value)
        console.log("frill: "+ resp?._value);
        if(resp?._value === 'true') {
          this.sharedService.remoteConfig.frill = true;
        } else {
          this.sharedService.remoteConfig.frill = false;
        }
      }).catch( error => {
        console.log(error);
        this.sharedService.remoteConfig.frill = false;
      })
    });

    this.changeFavicon(environment.favicon);
  }

  public async getValueByKey(key: string): Promise<any> {
    return this.remoteConfig.getValue(key).then((value) => {
      return value;
    }).catch((err) => {
      return err;
    });
   }

  checkWebsocketConnection() {
    if (
      this.router.url.indexOf('chats') > -1 ||
      this.router.url.indexOf('templates') > -1 ||
      this.router.url.indexOf('customers') > -1 ||
      this.router.url.indexOf('analytics') > -1 ||
      this.router.url.indexOf('campaigns') > -1 ||
      this.router.url.indexOf('settings') > -1
    ) {
      this.websocketService.websocketPingPong();
    }
  }

  getLeftPadding() {
    if(this.sharedService.hoverActive) return 'padding-left: 30px';
    else {
      if(this.sharedService.responsiveMenuBarOpen) return 'padding-left: 300px';
      else return 'padding-left: 30px';
    }
  }

  changeFavicon(iconPath: string): void {
    // const head = this.renderer.selectRootElement('head');
    const existingFavicon = this.renderer.selectRootElement('link[rel="icon"]');
    
    if (existingFavicon) {
      // Update the existing favicon
      this.renderer.setAttribute(existingFavicon, 'href', iconPath);
    } else {
      // Create a new link element for the new favicon
      const newFavicon = this.renderer.createElement('link');
      this.renderer.setAttribute(newFavicon, 'rel', 'icon');
      this.renderer.setAttribute(newFavicon, 'type', 'image/x-icon');
      this.renderer.setAttribute(newFavicon, 'href', iconPath);
    
      // Append the new favicon to the head
      this.renderer.appendChild(this.renderer.selectRootElement('head'), newFavicon);
    }
  }

}
