import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TeamMember } from 'src/app/models/team-member';
import { AgentService } from 'src/app/services/agent.service';

@Component({
  selector: 'agent-dropdown',
  templateUrl: './agent-dropdown.component.html',
  styleUrls: ['./agent-dropdown.component.css']
})
export class AgentDropdownComponent implements OnInit {
  
  @Input() id!: number;
  @Output() change = new EventEmitter<TeamMember>();
  teamMembersSet = new Set<string>();
  loaderTeamMemberList: boolean = false;
  teamMemberList: TeamMember[] = [];
  loaderTeamMemberPagination: boolean = false;
  pageNumber: number = 1;
  pageNext: boolean = false;
  selectedTeamMember!: TeamMember;

  constructor(
    private teamMemberService: AgentService, 
  ) { }

  ngOnInit(): void {
    this.teamMembersSet.clear();
    this.loaderTeamMemberList = true;
    this.pageNumber = 1;
    this.getTeamMembers(1);
  }

  getTeamMembers(pageNumber:number){
    this.loaderTeamMemberPagination = true;
    let response = this.teamMemberService.getActiveAgents(pageNumber);
    response.subscribe((resp)=>{
      try {
        if(resp.body.code === 200) {
          if(resp.body.next) this.pageNext = true;
          else this.pageNext = false;
          if(pageNumber === 1) this.teamMemberList = [];
          for (var index = 0; index < resp.body.data?.length; index++) {
            this.teamMemberList.push(new TeamMember(resp.body.data[index]));
            if(this.teamMemberList[index].id === this.id) this.selectedTeamMember = this.teamMemberList[index];
          }
          this.loaderTeamMemberList = false;
          this.loaderTeamMemberPagination = false;
          this.pageNumber++;
        }
      } catch (exception) {
      }
    },(error)=>{
      console.log(error);
      this.loaderTeamMemberList = false;
      this.loaderTeamMemberPagination = false;
    });
  }

  onTeamMemberScroll(event: any) {
    if (
      event.target.scrollHeight -
        event.target.offsetHeight -
        event.target.scrollTop <
      50
    ) {
      if (this.pageNext && !this.loaderTeamMemberPagination) {
        this.getTeamMembers(this.pageNumber);
      }
    }
  }

  selectMember(member: TeamMember | null) {
    this.selectedTeamMember = new TeamMember(member);
    this.change.emit(this.selectedTeamMember);
  }
}
