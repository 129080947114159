<div class="main-container" *ngIf="transferState === 'dialing'">
  <div class="container-heading">
    <span>Transfer call</span>
    <i class="fas fa-xmark" (click)="close.emit(true)"></i>
  </div>
  <search-input style="width: 100%" [(searchInput)]="teamMemberSearchTag" (searchInputChange)="searchTeamMembers($event)"></search-input>
  <div class="user-list" (scroll)="onTeamMemberScroll($event)" *ngIf="teamMemberList?.length !== 0">
    <div 
      class="user-item" 
      (click)="selectTeamMember(teamMember)" 
      *ngFor="let teamMember of teamMemberList"
      [ngClass]="{active: teamMember?.id === selectedTeamMember?.id}"
    >
      <span><expiry-icon [name]="teamMember?.name"></expiry-icon></span>
      <span>{{teamMember?.name}}</span>
    </div>
  </div>
  <div *ngIf="loaderTeamMember" class="loader-container">
    <ngx-skeleton-loader count="5" appearance="line"></ngx-skeleton-loader>
  </div>
  <div class="empty-list-container" *ngIf="!loaderTeamMember && teamMemberList?.length === 0">{{searchEmptyList}}</div>
  <div class="footer-buttons">
    <cancel-button [cancelButtonText]="'Ask first'" [disableCancel]="!selectedTeamMember?.id || loaderTransfer" (cancelClicked)="askFirst()"></cancel-button>
    <save-button [saveButtonText]="'Transfer now'" [disableSave]="!selectedTeamMember?.id || loaderTransfer" (saveClicked)="transferNow()"></save-button>
  </div>
</div>
<div class="call-container" *ngIf="transferState === 'in-call'">
  <div class="user-details">
    <span>
      <mat-icon svgIcon="pic_background"></mat-icon>
      <expiry-icon [name]="selectedTeamMember?.name"></expiry-icon>
    </span>
    <span>{{selectedTeamMember?.name}}</span>
  </div>
  <div class="call-status-timer">
    <span class="timer">00:00</span>
    <span class="status">{{callStatus}}</span>
  </div>
  <div class="call-description">
    <span>Connecting with teammate, have a conversation, and then transfer the call.</span>
  </div>
  <div class="active-buttons">
    <cancel-button [cancelButtonText]="'Cancel'" (cancelClicked)="cutCall()"></cancel-button>
    <save-button [saveButtonText]="'Transfer now'" (saveClicked)="transferUser()"></save-button>
  </div>
</div>