import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private http: HttpClient) {}

  sendOtp(loginDetl: any): Observable<any> {
    return this.http.post<any>('/otp-login/', loginDetl, {
      observe: 'response',
    });
  }

  //For Signup
  sendUserOtp(loginDetl: any): Observable<any> {
    return this.http.post<any>('/user/otp', loginDetl, {
      observe: 'response',
    });
  }

  verifyOtp(obj: any): Observable<any> {
    return this.http.put<any>('/otp-login/', obj, { observe: 'response' });
  }

  //For Signup
  verifyUserOtp(obj: any): Observable<any> {
    return this.http.put<any>('/user/otp', obj, { observe: 'response' });
  }

  userProfile(obj: any): Observable<any> {
    return this.http.put<any>('/user/me/', obj, { observe: 'response' });
  }

  getUserProfile() :Observable<any> {
    return this.http.get<any>('/user/me', { observe: 'response' });
  }

  logout(obj: any): Observable<any> {
    return this.http.post<any>('/user/logout/', obj, {
      observe: 'response',
    });
  }

  signup(obj: any): Observable<any> {
    return this.http.post<any>('/signup',obj, {
      observe: 'response',
    });
  }

  onboard(obj: any): Observable<any> {
    return this.http.post<any>('/channel/whatsapp/on-boarding', obj, {
      observe: 'response',
    });
  }

  updateProfile(obj: any): Observable<any> {
    return this.http.post<any>('/business/on-boarding', obj, {
      observe: 'response',
    });
  }

  verifyEmailToken(token: string): Observable<any> {
    return this.http.get<any>('/user/verify/verify-email?token='+token, {
      observe: 'response',
    })
  } 

  verifyResetPasswordToken(token: string): Observable<any> {
    return this.http.get<any>('/user/verify/forgot-password?token='+token, {
      observe: 'response',
    })
  } 
  
  passwordLogin(obj: any) :Observable<any> {
    return this.http.post<any>('/login',obj, { 
      observe: 'response'
    });
  }

  setPassword(obj: any) :Observable<any> {
    return this.http.post<any>('/user/change-password',obj, { 
      observe: 'response'
    });
  }

  forgotPassword(obj: any) :Observable<any> {
    return this.http.put<any>('/user/forgot-password',obj, {
      observe: 'response',
    })
  }

  resetPassword(obj: any) :Observable<any> {
    return this.http.post<any>('/user/forgot-password',obj, {
      observe: 'response',
    })
  }

  changePassword(obj: any) :Observable<any> {
    return this.http.post<any>('/user/change-password',obj, {
      observe: 'response',
    })
  }

  resendSignupVerificationEmail(obj: any) :Observable<any> {
    return this.http.post<any>('/user/resend-email',obj, { 
      observe: 'response'
    });
  }

  dialog360Onboarding(obj: any) :Observable<any> {
    return this.http.post('/360/on-boarding',obj, {
      observe: 'response',
    })
  }
}
