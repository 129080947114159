import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { BoldItalicPipe } from '../../pipes/bold-italic.pipe';
import { ClickOutsideDirective } from '../../directives/clickOutside.directive';

import { ImageLoaderDirective } from '../../directives/imageLoader.directive';
import { FormsModule } from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// import { LeftNavigationComponent } from './left-navigation/left-navigation.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { LabelComponent } from './label/label.component';
import { FooterCancelSaveComponent } from './footer-cancel-save/footer-cancel-save.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { SaveButtonComponent } from './save-button/save-button.component';
import { TagComponent } from './tag/tag.component';
import { TextInputComponent } from './text-input/text-input.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { CancelButtonComponent } from './cancel-button/cancel-button.component';
import { DocumentPreviewComponent } from './document-preview/document-preview.component';
import { SmallImageComponent } from './small-image/small-image.component';
import { SmallVideoComponent } from './small-video/small-video.component';
import { SmallDocumentComponent } from './small-document/small-document.component';
import { ConversationComponent } from './conversation/conversation.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { WebsiteInputComponent } from './website-input/website-input.component';
import { EmailInputComponent } from './email-input/email-input.component';
import { AssignLabelComponent } from './assign-label/assign-label.component';
import { EditAddressComponent } from './edit-address/edit-address.component';
import { EditCustomerComponent } from './edit-customer/edit-customer.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NumberFormatPipe } from '../../pipes/number-format.pipe';
import { GenderPipe } from '../../pipes/gender.pipe';
import { ExpiryIconComponent } from './expiry-icon/expiry-icon.component';
import { MapPipe } from '../../pipes/map.pipe';
import { MapPreviewComponent } from './map-preview/map-preview.component';
import { AssignMemberComponent } from './assign-member/assign-member.component';
import { TemplateListComponent } from './template-list/template-list.component';
import { TextareaInputComponent } from './textarea-input/textarea-input.component';
import { TertiaryButtonComponent } from './tertiary-button/tertiary-button.component';
import { VariableDropdownComponent } from './variable-dropdown/variable-dropdown.component';
import { SearchPipe } from '../../pipes/search.pipe';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ToastrComponent } from './toastr/toastr.component';
import { HoverInformationComponent } from './hover-information/hover-information.component';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PaiseToRupeePipe } from '../../pipes/paise-to-rupee.pipe';
import { NamePipe } from '../../pipes/name.pipe';
import { DatePipe } from '../../pipes/dd-mm-yyyy.pipe';
import { PaginationComponent } from './pagination/pagination.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { RazorpayComponent } from './razorpay/razorpay.component';
import { CustomerProfileComponent } from './customer-profile/customer-profile.component';
import { LabelPipe } from '../../pipes/label.pipe';
import { BarInformationComponent } from './bar-information/bar-information.component';
import { MenuComponent } from './menu/menu.component';
import { TemplateViewComponent } from './template-view/template-view.component';
import { AfterSlashPipe } from '../../pipes/after-slash.pipe';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationBarComponent } from './notification-bar/notification-bar.component';
import { BillingFormComponent } from './billing-form/billing-form.component';
import { RateTableComponent } from './rate-table/rate-table.component';
import { CurrencyConversationPipe } from '../../pipes/currency-conversation.pipe';
import { AudioStreamComponent } from './audio-stream/audio-stream.component';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { ChangeNumberComponent } from './change-number/change-number.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { DailerComponent } from './dialer/dailer.component';
import { InboxDropdownComponent } from './inbox-dropdown/inbox-dropdown.component';
import { ProfilePicComponent } from './profile-pic/profile-pic.component';
import { LabelFilterComponent } from './label-filter/label-filter.component';
import { TimerPipe } from 'src/app/pipes/timer.pipe';
import { DialerLabelComponent } from './dialer/dialer-label/dialer-label.component';
import { CallAddNoteComponent } from './call-add-note/call-add-note.component';
import { TransferCallComponent } from './dialer/transfer-call/transfer-call.component';
import { CallAssignLabelComponent } from './call-assign-label/call-assign-label.component';
import { DialerNoteComponent } from './dialer/dialer-note/dialer-note.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AgentDropdownComponent } from './agent-dropdown/agent-dropdown.component';
import { DateDropdownComponent } from './date-dropdown/date-dropdown.component';
import { TemplatePreviewComponent } from './template-preview/template-preview.component';
import { AfterDotPipe } from 'src/app/pipes/after-dot.pipe';
import { MessageInputComponent } from './message-input/message-input.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { HoursPipe } from 'src/app/pipes/hours.pipe';
import { MinutesPipe } from 'src/app/pipes/minutes.pipe';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { PopupComponent } from './popup/popup.component';
import { PascalCasePipe } from 'src/app/pipes/pascal-case.pipe';
import { TemplateTypePipe } from 'src/app/pipes/template-type.pipe';
import { TemplateButtonsPipe } from 'src/app/pipes/template-buttons.pipe';

@NgModule({
  declarations: [
    BoldItalicPipe,
    ClickOutsideDirective,
    ImageLoaderDirective,
    SearchInputComponent,
    ToggleButtonComponent,
    LabelComponent,
    FooterCancelSaveComponent,
    DatePickerComponent,
    SaveButtonComponent,
    TagComponent,
    TextInputComponent,
    TimePickerComponent,
    CancelButtonComponent,
    DocumentPreviewComponent,
    SmallImageComponent,
    SmallVideoComponent,
    SmallDocumentComponent,
    ConversationComponent,
    NumberInputComponent,
    WebsiteInputComponent,
    EmailInputComponent,
    AssignLabelComponent,
    EditAddressComponent,
    EditCustomerComponent,
    ConfirmationPopupComponent,
    NumberFormatPipe,
    GenderPipe,
    ExpiryIconComponent,
    MapPipe,
    MapPreviewComponent,
    AssignMemberComponent,
    TemplateListComponent,
    TextareaInputComponent,
    TertiaryButtonComponent,
    VariableDropdownComponent,
    SearchPipe,
    PageNotFoundComponent,
    ToastrComponent,
    HoverInformationComponent,
    PaiseToRupeePipe,
    NamePipe,
    DatePipe,
    PaginationComponent,
    RazorpayComponent,
    CustomerProfileComponent,
    LabelPipe,
    BarInformationComponent,
    MenuComponent,
    TemplateViewComponent,
    AfterSlashPipe,
    NotificationBarComponent,
    BillingFormComponent,
    RateTableComponent,
    CurrencyConversationPipe,
    AudioStreamComponent,
    ChangeEmailComponent,
    ChangeNumberComponent,
    DailerComponent,
    InboxDropdownComponent,
    ProfilePicComponent,
    LabelFilterComponent,
    TimerPipe,
    DialerLabelComponent,
    CallAddNoteComponent,
    TransferCallComponent,
    CallAssignLabelComponent,
    DialerNoteComponent,
    AgentDropdownComponent,
    DateDropdownComponent,
    TemplatePreviewComponent,
    AfterDotPipe,
    MessageInputComponent,
    SafePipe,
    HoursPipe,
    MinutesPipe,
    ConfirmationComponent,
    PopupComponent,
    PascalCasePipe,
    TemplateTypePipe,
    TemplateButtonsPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    NgbModule,
    NgbTooltipModule,
    NgxPaginationModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'Loading...' }),
    NgOtpInputModule,
    NgxSkeletonLoaderModule,
    MatTooltipModule,
    PickerModule
  ],
  exports: [
    BoldItalicPipe,
    ClickOutsideDirective,
    ImageLoaderDirective,
    SearchInputComponent,
    ToggleButtonComponent,
    LabelComponent,
    FooterCancelSaveComponent,
    DatePickerComponent,
    SaveButtonComponent,
    TagComponent,
    TextInputComponent,
    TimePickerComponent,
    CancelButtonComponent,
    DocumentPreviewComponent,
    SmallImageComponent,
    SmallVideoComponent,
    SmallDocumentComponent,
    ConversationComponent,
    NumberInputComponent,
    WebsiteInputComponent,
    EmailInputComponent,
    AssignLabelComponent,
    EditCustomerComponent,
    EditAddressComponent,
    ConfirmationPopupComponent,
    NumberFormatPipe,
    GenderPipe,
    ExpiryIconComponent,
    MapPipe,
    MapPreviewComponent,
    AssignMemberComponent,
    TemplateListComponent,
    TextareaInputComponent,
    TertiaryButtonComponent,
    VariableDropdownComponent,
    SearchPipe,
    PageNotFoundComponent,
    ToastrComponent,
    HoverInformationComponent,
    PaiseToRupeePipe,
    NamePipe,
    DatePipe,
    PaginationComponent,
    RazorpayComponent,
    CustomerProfileComponent,
    LabelPipe,
    BarInformationComponent,
    MenuComponent,
    AfterSlashPipe,
    TemplateViewComponent,
    NotificationBarComponent,
    BillingFormComponent,
    RateTableComponent,
    CurrencyConversationPipe,
    AudioStreamComponent,
    ChangeEmailComponent,
    ChangeNumberComponent,
    DailerComponent,
    InboxDropdownComponent,
    ProfilePicComponent,
    LabelFilterComponent,
    TimerPipe,
    DialerLabelComponent,
    CallAddNoteComponent,
    TransferCallComponent,
    CallAssignLabelComponent,
    DialerNoteComponent,
    AgentDropdownComponent,
    DateDropdownComponent,
    TemplatePreviewComponent,
    AfterDotPipe,
    MessageInputComponent,
    SafePipe,
    HoursPipe,
    MinutesPipe,
    ConfirmationComponent,
    PopupComponent,
    PascalCasePipe,
    TemplateTypePipe,
    TemplateButtonsPipe,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: null }
  ]
})
export class SharedModule {}
