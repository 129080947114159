export class AudienceSet {
    private _name: string;
    private _from_age: number;
    private _audience_count: number;
    private _created_at: Date;
    private _date_of_joining: string;
    private _gender: string;
    private _audience_type: string;
    private _labels: AudienceLabel[];
    private _business: number;
    private _id: number;
    private _to_age: number;
    private _status: string;
    private _date_of_joining_from: string;
    private _date_of_joining_to: string;
    private _updated_at: Date;
    private _is_system_gen: boolean;
    private _file_path: string;


    public get file_path(): string {
        return this._file_path;
    }

    public set file_path(file_path: string) {
        this._file_path = file_path;
    }

    public get is_system_gen(): boolean {
        return this._is_system_gen;
    }

    public set is_system_gen(is_system_gen: boolean) {
        this._is_system_gen = is_system_gen;
    }


    constructor(audienceSetJson: any){
        this._name = audienceSetJson?.name;
        this._from_age = audienceSetJson?.from_age;
        this._audience_count = audienceSetJson?.audience_count;
        this._created_at = audienceSetJson?.created_at;
        this._date_of_joining = audienceSetJson?.date_of_joining;
        this._gender = audienceSetJson?.gender;
        this._audience_type = audienceSetJson?.audience_type;
        this._labels = [];
        audienceSetJson?.labels?.forEach((label: any)=>{
            this._labels.push(new AudienceLabel(label));
        });
        this._business = audienceSetJson?.business;
        this._to_age = audienceSetJson?.to_age;
        this._id = audienceSetJson?.id;
        this._date_of_joining_from = audienceSetJson?.date_of_joining_from;
        this._status = audienceSetJson?.status;
        this._date_of_joining_to = audienceSetJson?.date_of_joining_to;
        this._updated_at = audienceSetJson?.updated_at;
        this._is_system_gen = audienceSetJson?.is_system_gen;
        this._file_path = audienceSetJson?.file_path;
    }

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get from_age(): number {
        return this._from_age;
    }

    public set from_age(from_age: number) {
        this._from_age = from_age;
    }

    public get audience_count(): number {
        return this._audience_count;
    }

    public set audience_count(audience_count: number) {
        this._audience_count = audience_count;
    }

    public get created_at(): Date {
        return this._created_at;
    }

    public set created_at(created_at: Date) {
        this._created_at = created_at;
    }

    public get date_of_joining(): string {
        return this._date_of_joining;
    }

    public set date_of_joining(date_of_joining: string) {
        this._date_of_joining = date_of_joining;
    }

    public get gender(): string {
        return this._gender;
    }

    public set gender(gender: string) {
        this._gender = gender;
    }

    public get audience_type(): string {
        return this._audience_type;
    }

    public set audience_type(audience_type: string) {
        this._audience_type = audience_type;
    }

    public get labels(): AudienceLabel[] {
        return this._labels;
    }

    public set labels(labels: AudienceLabel[]) {
        this._labels = [];
        labels?.forEach((label: AudienceLabel)=>{
            this._labels.push(label);
        })
    }

    public get business(): number {
        return this._business;
    }

    public set business(business: number) {
        this._business = business;
    }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get to_age(): number {
        return this._to_age;
    }

    public set to_age(to_age: number) {
        this._to_age = to_age;
    }

    public get status(): string {
        return this._status;
    }

    public set status(status: string) {
        this._status = status;
    }

    public get date_of_joining_from(): string {
        return this._date_of_joining_from;
    }

    public set date_of_joining_from(date_of_joining_from: string) {
        this._date_of_joining_from = date_of_joining_from;
    }

    public get date_of_joining_to(): string {
        return this._date_of_joining_to;
    }

    public set date_of_joining_to(date_of_joining_to: string) {
        this._date_of_joining_to = date_of_joining_to;
    }

    public get updated_at(): Date {
        return this._updated_at;
    }

    public set updated_at(updated_at: Date) {
        this._updated_at = updated_at;
    }

}

export class AudienceLabel {
    private _label_id: number;
    private _name: string;
    private _color: string;
    private _bg_color: string;

    public get bg_color(): string {
        return this._bg_color;
    }

    public set bg_color(bg_color: string) {
        this._bg_color = bg_color;
    }

  
    constructor(labelJson: any) {
      this._label_id = labelJson?.label_id;
      this._name = labelJson?.name;
      this._color = labelJson?.color;
      this._bg_color = labelJson?.bg_color;
    }
  
    public get label_id(): number {
      return this._label_id;
    }
  
    public set label_id(labelId: number) {
      this._label_id = labelId;
    }
  
    public get name(): string {
      return this._name;
    }
  
    public set name(name: string) {
      this._name = name;
    }
  
    public get color(): string {
      return this._color;
    }
  
    public set color(color: string) {
      this._color = color;
    }
  }
  