<div class="main-container">
  <div class="container-heading">
    <span>Note</span>
    <i class="fas fa-xmark"></i>
  </div>
  <div class="input-box">
    <textarea placeholder="Write text here ..." name="text-input" [(ngModel)]="message"></textarea>
  </div>
  <div class="footer-buttons">
    <cancel-button [cancelButtonText]="'Cancel'" (cancelClicked)="close.emit(true)"></cancel-button>
    <save-button [saveButtonText]="'Add note'" [disableSave]="message === ''" [loading]="isLoading" (saveClicked)="addNote()"></save-button>
  </div>
</div>