<div class="menu-container" 
  (mouseover)="sharedService.hoverActive ? hoverOpen() : ''" 
  (mouseleave)="sharedService.hoverActive ? hoverClose() : ''"
  #menu 
  [ngStyle]="{
    'margin-top' : sharedService.getNotificationBarSize()+ 'px',
    'height': 'calc(100% - '+sharedService.getNotificationBarSize()+'px)'
  }"
  [ngClass]="[expanded ? 'expanded' : 'collapsed', sharedService.responsiveMenuBarOpen ? 'responsive-open' : 'responsive-closed']"
>
  <div class="menu-wrap">
    <span class="menu-expand-container" (mouseover)="$event.stopPropagation()" (mouseleave)="$event.stopPropagation()">
      <mat-icon class="menu-expand-icon" *ngIf="!expanded" (click)="openClicked()" svgIcon="menu_expand"></mat-icon>
      <mat-icon class="menu-expand-icon" *ngIf="expanded" (click)="closeClicked()" svgIcon="menu_collapse"></mat-icon>
    </span>
    <!-- <img src="../../../../assets/images/sobot_logo.png" class="logo-img" /> -->
    <div class="sidebar-menu-item display-pic">
      <a
        href="javascript:void(0)"
        class="sidebar-menu-link"
        [ngStyle]="{'padding-left': sharedService.business.business_logo ? '4px' : '10px'}"
      >
        <span class="sidebar-icon">
          <img *ngIf="sharedService.business.business_logo" [src]="sharedService.business.business_logo" uiImageLoader class="logo-img" />
          <expiry-icon *ngIf="!sharedService.business.business_logo" [name]="sharedService.business.name"></expiry-icon>
          <!-- <span>{{ businessName }}</span> -->
          <span class="sidebar-company-name">{{sharedService.business.name}}</span>
        </span>
      </a>
    </div>
    <!-- <div class="sidebar-menu-item" [ngClass]="{ active: activeTab === 'home' }">
      <hr>
      <a
        href="javascript:void(0)"
        routerLink="/home"
        routerLinkActive="active"
        class="sidebar-menu-link"
        (click)="activeTab = 'home'"
      >
        <span class="sidebar-icon">
          <mat-icon svgIcon="home_grey"></mat-icon>
          <span>Home</span>
        </span>
      </a>
    </div> -->
    <div class="sidebar-menu-item" [ngClass]="{ active: activeTab === 'chats' }">
      <hr>
      <a
        href="javascript:void(0)"
        routerLink="/chats"
        routerLinkActive="active"
        class="sidebar-menu-link"
        (click)="activeTab = 'chats'"
      >
        <span class="sidebar-icon">
          <mat-icon svgIcon="chat_icon_grey"></mat-icon>
          <span>Chats</span>
        </span>
      </a>
    </div>
    <!-- <div class="sidebar-menu-item" [ngClass]="{ active: activeTab === 'calls' }">
      <hr>
      <a
        href="javascript:void(0)"
        routerLink="/calls"
        routerLinkActive="active"
        class="sidebar-menu-link"
        (click)="activeTab = 'calls'"
      >
        <span class="sidebar-icon">
          <mat-icon svgIcon="calls"></mat-icon>
          <span>Calls</span>
        </span>
      </a>
    </div> -->
    <div class="sidebar-menu-item" [ngClass]="{ active: activeTab === 'analytics' }">
      <hr>
      <a
        href="javascript:void(0)"
        routerLink="/analytics"
        routerLinkActive="active"
        class="sidebar-menu-link"
        
        (click)="activeTab = 'analytics'"
      >
        <span class="sidebar-icon">
          <mat-icon svgIcon="analytics_grey"></mat-icon>
          <span>Analytics</span>
        </span>
      </a>
    </div>
    <div 
      class="sidebar-menu-item"
      [ngClass]="{ active: activeTab === 'customers' || (activeTab === 'customer-set' && dropdownExpanded !== 'customers') }"
    >
      <hr [ngStyle]="{'transform': (expanded && activeTab === 'customers' && dropdownExpanded === 'customers') ? 'translateY(-28px)' : ''}">
      <hr [ngStyle]="{'transform': (expanded && activeTab === 'customers' && dropdownExpanded === 'customers') ? 'translateY(-28px)' : ''}">
      <div class="sub-menu">
        <a
          href="javascript:void(0)"
          routerLink="/customers"
          routerLinkActive="active"
          class="sidebar-menu-link"
          [ngStyle]="{width: expanded ? 'auto' : '56px'}"
          [ngClass]="dropdownExpanded === 'customers' ? 'open' : ''"
          (click)="activeTab = 'customers'"
        >
          <span class="sidebar-icon">
            <mat-icon svgIcon="person"></mat-icon>
            <span>Contacts</span>
          </span>
          <i *ngIf="expanded" class="dropdown-arrow fa-solid fa-angle-down" (click)="dropdownExpanded === 'customers' ? dropdownExpanded = '' : dropdownExpanded = 'customers'"></i>
        </a>
        <div *ngIf="expanded" class="sub-menu-list" [ngClass]="dropdownExpanded === 'customers' ? 'open' : ''">
          <ul>
            <li class="menu-item" [ngClass]="{ active: activeTab === 'customer-set' }">
              <hr>
              <a 
                href="javascript:void(0)"
                routerLink="/customers/customer-set"
                routerLinkActive="active"
                (click)="activeTab = 'customer-set'"
              >
                <span class="menu-title">Contact Sets</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="sidebar-menu-item" [ngClass]="{ active: activeTab === 'campaigns' }">
      <hr>
      <a
        href="javascript:void(0)"
        routerLink="/campaigns"
        routerLinkActive="active"
        class="sidebar-menu-link"
        (click)="activeTab = 'campaigns'"
      >
        <span class="sidebar-icon">
          <mat-icon svgIcon="campaign"></mat-icon>
          <span>Campaigns</span>
        </span>
      </a>
    </div>
    <div class="sidebar-menu-item" [ngClass]="{ active: activeTab === 'templates' }">
      <hr>
      <a
        href="javascript:void(0)"
        routerLink="/templates"
        routerLinkActive="active"
        class="sidebar-menu-link"
        (click)="activeTab = 'templates'"
      >
        <span class="sidebar-icon">
          <mat-icon svgIcon="template_solid"></mat-icon>
          <span>Templates</span>
        </span>
      </a>
    </div>
  </div>
  <div class="menu-account-wrap">
    <div class="sidebar-menu-item" *ngIf="user?.user_type === 1">
      <hr>
      <a
        href="javascript:void(0)"
        routerLink="/settings/wallet"
        routerLinkActive="active"
        class="sidebar-menu-link"
        (click)="activeTab = 'settings'"
      >
        <span class="sidebar-icon" style="width: 100%;">
          <mat-icon svgIcon="wallet_solid"></mat-icon>
          <span class="wallet-amount">
            Wallet
            <span *ngIf="walletService.walletBalance">{{walletService.walletBalance | currency: 4}}</span>
          </span>
        </span>
      </a>
    </div>
    <div class="sidebar-menu-item" [ngClass]="{ active: activeTab === 'settings' }">
      <hr>
      <a
        href="javascript:void(0)"
        routerLink="/settings/business"
        routerLinkActive="active"
        class="sidebar-menu-link web"
        (click)="activeTab = 'settings'"
      >
        <span class="sidebar-icon">
          <mat-icon svgIcon="settings"></mat-icon>
          <span>Settings</span>
        </span>
      </a>
      <a
        href="javascript:void(0)"
        routerLink="/settings"
        routerLinkActive="active"
        class="sidebar-menu-link mobile"
        (click)="activeTab = 'settings'"
      >
        <span class="sidebar-icon">
          <mat-icon svgIcon="settings"></mat-icon>
          <span>Settings</span>
        </span>
      </a>
    </div>
    <!-- <div class="sidebar-menu-item" [ngClass]="{ active: activeTab === 'notification' }">
      <hr>
      <a
        href="javascript:void(0)"
        routerLink="/notification"
        routerLinkActive="active"
        class="sidebar-menu-link"
        
        (click)="activeTab = 'notification'"
      >
        <span class="sidebar-icon">
          <mat-icon svgIcon="notifications"></mat-icon>
        </span>
      </a>
    </div> -->
    <div class="sidebar-menu-item" [ngClass]="{ active: activeTab === 'user' }">
      <hr>
      <a
        href="javascript:void(0)"
        routerLink="/user/profile"
        routerLinkActive="active"
        class="sidebar-menu-link web"
        (click)="activeTab = 'user'"
        style="padding-left: 10px;"
      >
        <span class="sidebar-icon">
          <expiry-icon *ngIf="!user?.profile_pic" [name]="user?.name"></expiry-icon>
          <img class="user-profile-pic" *ngIf="user?.profile_pic" [src]="user?.profile_pic" alt="" uiImageLoader>
          <span>{{user?.name}}</span>
        </span>
      </a>
      <a
        href="javascript:void(0)"
        routerLink="/user"
        routerLinkActive="active"
        class="sidebar-menu-link mobile"
        (click)="activeTab = 'user'"
      >
        <span class="sidebar-icon">
          <mat-icon svgIcon="account_grey"></mat-icon>
          <span>Profile</span>
        </span>
      </a>
    </div>
  </div>
</div>
<!-- <div
  class="notification-bar-container"
  *ngIf="
    !websocketService.websocketOpened &&
    !websocketService.internetConnectionLost 
  "
>
  <div class="notification-bar-container-body">
    <p>
      Connection lost
      <span style="cursor: pointer" (click)="connectWebsocket()"
        ><i class="fa-solid fa-rotate-right"></i> RETRY</span
      >
    </p>
  </div>
</div> -->
<div
  class="notification-bar-container"
  *ngIf="websocketService.internetConnectionLost"
>
  <div class="notification-bar-container-body">
    <p>
      Internet Connection lost
      <span style="cursor: pointer" (click)="connectInternet()"
        ><i class="fa-solid fa-rotate-right"></i> RETRY</span
      >
    </p>
  </div>
</div>