import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  build = environment.configuration;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  routeToChats(){
    this.router.navigate(['/chats']);
  }

}
