<div class="assign-label-popup">
  <div class="white-card">
    <div class="white-card-heading">
      <span class="card-heading-title">Change phone number</span>
      <span (click)="close.emit(true)"><i class="fa-solid fa-xmark"></i></span>
    </div>
    <div class="card-body" *ngIf="!otpSent">
      <span>We’ll send you 6-digit verification code to the new phone number to verify that you own it.</span>
      <number-input [fieldName]="'New phone number'" [inputNumber]="numberDetails.number" (numberChange)="numberDetails = $event"></number-input>
      <cancel-button [cancelButtonText]="'Send verification code'" (cancelClicked)="sendVerification()"></cancel-button>
    </div>
    <div class="card-body" *ngIf="otpSent">
      <span>We have sent 6-digit verification code to <span style="font-weight: 500;">{{numberDetails.callingCode + numberDetails.number}}</span> </span>
      <span>
        <span class="field-title">Verification code</span>
        <ng-otp-input
          (keyup.enter)="isValidOtp ? changeEmail() : ''"
          (onInputChange)="onMobileOtpChange($event)"
          [config]="otpConfig"
        ></ng-otp-input>    
        <span class="number-invalid-error" *ngIf="invalidOtp">{{ constants.invalidOtp }}</span>
      </span>
      <p class="resend-otp-wrong-number">
        <button class="resend-otp" *ngIf="retryOtpCount === 0" (click)="resendOtp()">Resend OTP</button>
        <span class="resend-otp" *ngIf="retryOtpCount !== 0">Retry in 00:{{retryOtpCount}}</span>
        <button class="resend-otp" (click)="wrongNumber()">Wrong number?</button>
      </p>
      </div>
    <div class="card-footers">
      <save-button [disableSave]="!(otpSent && isValidOtp && !isLoading)" [saveButtonText]="'Change phone number'" (saveClicked)="changeEmail()"></save-button>
    </div>
  </div>
</div>

<div class="shaded-screen"></div>