<div class="input-container">
  <input type="text" 
    appInputStyle
    matInput
    [ngStyle]="{'margin-right': maxChars ? '50px' : '0'}"
    [(ngModel)]="inputText" 
    (keypress)="checkLength($event)" 
    (keyup)="restrictLength()"
    [placeholder]="placeholder" 
    [disabled]="disable"
    (keyup.enter)="enterClicked.emit(inputText)"
    (ngModelChange)="textChange.emit(inputText)">
  <span class="text-count" *ngIf="maxChars">{{inputText?.length}}/{{maxChars}}</span>
</div>