import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';
import { GlobalStrings } from 'src/app/constants';
import { AgentService } from 'src/app/services/agent.service';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.css']
})
export class ChangeEmailComponent {

  constructor(
    private _agentService: AgentService,
    public constants: GlobalStrings,
    private _toast: ToasterService,
  ) {
  }

  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Output() success: EventEmitter<string> = new EventEmitter();
  email!: string;
  isValid : boolean = false;

  otpSent: boolean = false;
  isValidOtp: boolean = false;
  @ViewChild(NgOtpInputComponent, { static: false })
  ngOtpInput!: NgOtpInputComponent;
  otpConfig: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
  };
  mobileOtp!: string;
  invalidOtp: boolean = false;
  retryOtpCount: number = 0;
  isLoading: boolean = false;

  sendVerification() {
    this.isLoading = true;
    let input = {
      email: this.email,
    }
    this._agentService.updateEmailOtp(input).subscribe({
      next: resp => {
        if(resp.body.code === 200) {
          this.otpSent = true;
          this.isLoading = false;
        }
        else {
          console.log(resp.body.message);
          this._toast.error(resp.body.message);
          this.isLoading = false;
        }
      }, error: error => {
        console.log(error);
        this.isLoading = false;
        this._toast.error(error);
      }
    })
  }

  resendOtp(){
    this.sendVerification();
    this.mobileOtp = '';
    this.ngOtpInput.setValue('');
    this.invalidOtp = false;
    this.retryOtpCount = 30;
    for(let i = 0; i < 30; i++){
      setTimeout(() =>{
        this.retryOtpCount--;
      }, i*1000);
    }
  }

  onMobileOtpChange(otp: any) {
    if (otp && otp.length == 6) {
      this.mobileOtp = otp;
      this.isValidOtp = true;
    } else {
      this.isValidOtp = false;
    }
  }

  changeEmail() {
    this.isLoading = true;
    let input = {
      email: this.email,
      otp: this.mobileOtp,
    }
    this._agentService.updateEmail(input).subscribe({
      next: resp => {
        if(resp.body.code === 200) {
          this.isLoading = false;
          this._toast.success(resp.body.message);
          this.success.emit(this.email);
        }
        else {
          console.log(resp.body.message);
          this._toast.error(resp.body.message);
          this.isLoading = false;
          this.invalidOtp = true;
        }
      }, error: error => {
        console.log(error);
        this.isLoading = false;
        this._toast.error(error);
        this.invalidOtp = true;
      }
    })
  }
}
