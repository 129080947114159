import { Component, Input, OnInit } from '@angular/core';
import { RateCard } from 'src/app/models/rate-card';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'rate-table',
  templateUrl: './rate-table.component.html',
  styleUrls: ['./rate-table.component.css']
})
export class RateTableComponent implements OnInit {

  constructor(
    public sharedDataService: SharedDataService,
    private subscriptionService: SubscriptionService,
  ) { }

  build = environment.configuration;
  rateCard: RateCard[] = [];
  callRateCard: RateCard[] = [];
  private eventsSubscription!: Subscription;
  rateCardType: string = 'whatsapp';

  @Input() events!: Observable<void>;
  @Input() loggedIn: boolean = false;

  
  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
  ngOnInit(): void {
    if(this.loggedIn) {
      this.subscriptionService.getLoggedInRateCard().subscribe({
        next: resp => {
          if(resp.body.code === 200) {
            let rateList;
            if(this.sharedDataService.isPartnerBusiness) rateList = resp.body.data.view;
            if(!this.sharedDataService.isPartnerBusiness) rateList = resp.body.data.actual;
            rateList?.forEach((element: any) => {
              this.rateCard.push(new RateCard(element));
            });
          }
        }
      });
      this.subscriptionService.getLoggedInCallRateCard().subscribe({
        next: resp => {
          if(resp.body.code === 200) {
            console.log(resp);
            this.callRateCard = [];
            let rateList;
            rateList = resp.body.data;
            rateList?.forEach((element: any) => {
              this.callRateCard.push(new RateCard(element));
            });
          } else {
            console.log(resp.body.message);
          }
        }, error: error => {
            console.log(error);
        }
      })
    } else {
      this.subscriptionService.getRateCard().subscribe({
        next: resp => {
          if(resp.body.code === 200) {
            let rateList = resp.body.data;
            rateList?.forEach((element: any) => {
              this.rateCard.push(new RateCard(element));
            });
          }
        }
      });
    }
    this.eventsSubscription = this.events.subscribe((event: any) => {
      if(event?.type === 'call') {
        this.rateCardType = 'call';
        this.downloadRateCard();
      } else {
        this.rateCardType = 'whatsapp';
        this.downloadRateCard();
      }
    });
  }

  downloadRateCard() {
    console.log("Downloading "+this.rateCardType+" ratecard")
    setTimeout(()=> {
      let DATA: HTMLElement = document.getElementById('rate')!;
      var HTML_Width = DATA.offsetWidth;
		  var HTML_Height = DATA.offsetHeight;
		  var top_left_margin = 15;
		  var PDF_Width = HTML_Width+(top_left_margin*2);
		  var PDF_Height = (PDF_Width*1.5);
		  var canvas_image_width = HTML_Width;
		  var canvas_image_height = HTML_Height;
		  var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
      html2canvas(DATA,{ scale: 2 }).then((canvas: any) => {
        canvas.getContext('2d');
			  console.log(canvas.height+"  "+canvas.width);
			  var imgData = canvas.toDataURL("image/jpeg", 1.0);
			  var pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
		    pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);
			  for (var i = 1; i <= totalPDFPages; i++) { 
			  	pdf.addPage();
			  	pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin*1),canvas_image_width,canvas_image_height);
			  }
        pdf.save(this.build+' WhatsApp Conversation Pricing List_'+new Date().toUTCString().slice(5,16) + '.pdf');
      });
    },100)
  }


}
