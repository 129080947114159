<div class="dropdown">
  <button
  class="btn btn-custom-dropdown dropdown-toggle variable-dropdown"
  type="button"
  id="variableDropdownMenu"
  data-bs-toggle="dropdown"
  aria-expanded="false"
  [disabled]="disabled"
  >
    <span class="number-icon">
      <span>{{currentCloudNumber?.icon}}</span>
    </span>
    <span class="cloud-number-name-number">
      <p class="name">{{currentCloudNumber?.name}}</p>
      <p class="number">{{currentCloudNumber?.number | numberFormat}}</p>
    </span>
  </button>
  <ul
    class="dropdown-menu custom-dropdown-menu variable-dropdown-menu"
    aria-labelledby="headerDropdownMenu"
  >
    <li class="variable-dropdown-item" 
      *ngFor="let number of sharedDataService.cloudNumbers"
      (click)="numberChanged(number)"
      [ngClass]="{active : currentCloudNumber?.id === number?.id}"
    >
      <span class="number-icon">
        <span>{{number?.icon}}</span>
      </span>
      <span class="cloud-number-name-number">
        <p class="name">{{number?.name}}</p>
        <p class="number">{{number?.number | numberFormat}}</p>
      </span> 
    </li>
  </ul>
</div>