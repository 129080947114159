import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SharedDataService } from '../services/shared-data.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _router: Router, public sharedService: SharedDataService) {}

  canActivate(): boolean {
    let token = localStorage.getItem('token');
    if (token) {
      this.sharedService.isLoggedIn = true;
      return true;
    } else {
      this.sharedService.isLoggedIn = false;
      this._router.navigate(['/login']);
      return false;
    }
  }
}
