export class TeamMember {
  private _id: number;
  private _mobile: string;
  private _name: string;
  private _email: string;
  private _is_active: boolean;
  private _date_joined: Date;
  private _user_type: number;
  private _assigned_chat_count: number;
  private _last_login: Date;
  private _status: string;
  private _user_status: string;

    public get user_status(): string {
        return this._user_status;
    }

    public set user_status(user_status: string) {
        this._user_status = user_status;
    }


  public get last_login(): Date {
    return this._last_login;
  }

  public set last_login(last_login: Date) {
      this._last_login = last_login;
  }

  public get status(): string {
      return this._status;
  }

  public set status(status: string) {
      this._status = status;
  }
  public get assigned_chat_count(): number {
    return this._assigned_chat_count;
  }

  public set assigned_chat_count(assigned_chat_count: number) {
    this._assigned_chat_count = assigned_chat_count;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get mobile(): string {
    return this._mobile;
  }

  public set mobile(mobile: string) {
    this._mobile = mobile;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get email(): string {
    return this._email;
  }

  public set email(email: string) {
    this._email = email;
  }

  public get is_active(): boolean {
    return this._is_active;
  }

  public set is_active(is_active: boolean) {
    this._is_active = is_active;
  }

  public get date_joined(): Date {
    return this._date_joined;
  }

  public set date_joined(date_joined: Date) {
    this._date_joined = date_joined;
  }

  public get user_type(): number {
    return this._user_type;
  }

  public set user_type(user_type: number) {
    this._user_type = user_type;
  }

  constructor(teamMemberJson: any) {
    this._id = teamMemberJson?.id;
    this._mobile = teamMemberJson?.mobile;
    this._name = teamMemberJson?.name;
    this._email = teamMemberJson?.email;
    this._is_active = teamMemberJson?.is_active;
    this._date_joined = teamMemberJson?.date_joined;
    this._user_type = teamMemberJson?.user_type;
    this._assigned_chat_count = teamMemberJson?.assigned_chat_count ? teamMemberJson?.assigned_chat_count : 0;
    this._last_login = teamMemberJson?.last_login;
    this._status = teamMemberJson?.status;
    this._user_status = teamMemberJson?.user_status
  }
}