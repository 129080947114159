import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'small-document',
  templateUrl: './small-document.component.html',
  styleUrls: ['./small-document.component.css']
})
export class SmallDocumentComponent implements OnInit {

  @Input() filename!: string;
  @Output() documentClosed = new EventEmitter<boolean>();

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.matIconRegistry.addSvgIcon(
      'excel_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/excel_icon.svg'
      )
    );
  }

  ngOnInit(): void {
  }

  getFileType() {
    return this.filename?.split('.').pop();
  }

}
