import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'variable-dropdown',
  templateUrl: './variable-dropdown.component.html',
  styleUrls: ['./variable-dropdown.component.css']
})
export class VariableDropdownComponent implements OnInit {

  @Output() typeChange = new EventEmitter<string>();

  variableType: string = 'Text';
  labelName: string = 'labelName';
  variableDisplayName: Record<string,string> = {
    'Text' : 'Text',
    'first_name' : 'First name',
    'last_name': 'Last name',
    'full_name': 'Full name',
    'whatsapp_name': 'Whatsapp name',
    'date_of_birth': 'Date of birth',
    'email': 'Email',
    'mobile': 'Phone number'
  }

  constructor() { }

  ngOnInit(): void {
    this.labelName = uuidv4().toString();
  }

}
