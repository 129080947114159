export class CloudNumber {
  private _id: number
  private _name: string
  private _number: string
  private _country_code: string
  private _created_at: string
  private _updated_at: string
  private _icon: string

  constructor(cloudNumberJson: any) {
    this._id = cloudNumberJson?.id;
    this._name = cloudNumberJson?.name;
    this._number = cloudNumberJson?.number;
    this._country_code = cloudNumberJson?.country_code;
    this._created_at = cloudNumberJson?.created_at;
    this._updated_at = cloudNumberJson?.updated_at;
    this._icon = cloudNumberJson?.icon;
  }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get number(): string {
        return this._number;
    }

    public set number(number: string) {
        this._number = number;
    }

    public get country_code(): string {
        return this._country_code;
    }

    public set country_code(country_code: string) {
        this._country_code = country_code;
    }

    public get created_at(): string {
        return this._created_at;
    }

    public set created_at(created_at: string) {
        this._created_at = created_at;
    }

    public get updated_at(): string {
        return this._updated_at;
    }

    public set updated_at(updated_at: string) {
        this._updated_at = updated_at;
    }

    public get icon(): string {
        return this._icon;
    }

    public set icon(icon: string) {
        this._icon = icon;
    }

}