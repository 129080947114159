import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'small-image',
  templateUrl: './small-image.component.html',
  styleUrls: ['./small-image.component.css']
})
export class SmallImageComponent implements OnInit {

  @Input() imageUrl!: string;
  @Input() isActive: boolean = false;
  @Output() closeImage = new EventEmitter<boolean>();

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.matIconRegistry.addSvgIcon(
      'close_attachments_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/close_attachments_grey.svg'
      )
    );
   }

  ngOnInit(): void {
  }

}
