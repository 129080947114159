
<div class="number-component" [ngClass]="disabled ? 'disabled' : ''">
  <p class="name-count">
    <span>{{fieldName}}</span>
    <span *ngIf="maxChars">{{inputNumber?.length ? inputNumber?.length : 0}}/10</span>
  </p>
  <div class="number-input-container">
    <div class="dropdown">
      <button 
        class="btn dropdown-toggle country-code-dropdown" 
        data-bs-toggle="dropdown"
        aria-expanded="false" 
        type="button"
        id="countryDropdownMenu"
        (click)="focusSearch()"
        [disabled]="disabled"
      >
        <span *ngIf="numberDetails.flag === ''"><mat-icon svgIcon="globe_icon_grey"></mat-icon></span>
        <span *ngIf="numberDetails.flag !== ''">
          <img class="flag-image" [src]="numberDetails.flag" alt="Globe">
        </span>
      </button>
      <ul class="dropdown-menu country-code-dropdown-menu" aria-labelledby="countryDropdownMenu">
        <search-input (click)="$event.stopPropagation()" (searchInputChange)="countrySearchTag = $event" [(searchInput)]="countrySearchTag"></search-input>
        <li *ngFor="let country of _sharedDataService.countryCodesList | search:countrySearchTag" class="country-code-dropdown-item" (click)="onCountryChange(country)">
          <img [src]="country?.flag" alt="{{ country.name }} Flag" class="flag-image">
          {{ country?.name }} (+{{country?.callingCodes[0]}})
        </li>
      </ul>
    </div>
    <span class="number-input-country-code" *ngIf="numberDetails.callingCode !== ''">+{{numberDetails.callingCode}}</span>
    <input class="black-text" type="text" [disabled]="disabled" [(ngModel)]="inputNumber" (keyup)="numberDetails.number = inputNumber; numberChange.emit(numberDetails)" (keyup.enter)="enterClicked.emit(true)" (keypress)="checkLength($event)" (ngModelChange)="numberChange.emit(numberDetails)">
  </div>
</div>