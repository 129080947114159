import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";

@Component({
  selector: 'text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class TextInputComponent implements OnInit {

  @Input() inputText!: string;
  @Input() maxChars!: number;
  @Input() disable: boolean = false;
  @Input() placeholder: string = 'Enter text';
  @Output() textChange = new EventEmitter<string>();
  @Output() enterClicked = new EventEmitter<string>();

  ngOnInit(): void {
    this.restrictLength();
  }
  checkLength(event: any){
    if(this.maxChars){
      if(this.inputText?.length < this.maxChars) return true;
      else return false;
    } else return true;
  }
  restrictLength(){
    this.inputText = this.inputText?.substring(0,this.maxChars);
    this.textChange.emit(this.inputText)
  }
}

import { Directive, ElementRef, Host, Renderer2 } from '@angular/core';
// import { CardNumberComponent } from 'src/app/themes/card-number/card-number.component';

@Directive({
  selector: '[appInputStyle]'
})
export class InputStyleDirective {

  private input: HTMLInputElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.input = this.el.nativeElement;
  }

  ngOnInit() {
    this.input.style.setProperty('--placeHolder-color', 'red');
  }
}