<div class="message-input-box">
  <p class="message-heading"><span>{{fieldName}}</span><span>{{messageLength}}/{{maxChar}}</span></p>
  <textarea 
    #body
    [rows]="rows"
    [(ngModel)]="message"
    (keyup)="checkBodyVariables()"
    id="body-content"
    class="message-textarea"
    placeholder="Enter text"
  ></textarea>
  <p class="message-buttons-variables"> 
    <span class="message-buttons-icon" *ngIf="isButtons">
      <button
        type="button"
        class="btn btn-emoji"
        id="italic"
        style="padding: 0;"
        (clickOutside)="showEmojiPicker = false"
        >
          <mat-icon svgIcon="emoji_icon" (click)="openEmojiPicker()"></mat-icon>
          <emoji-mart
            *ngIf="showEmojiPicker"
            class="emoji-mart"
            (emojiSelect)="addEmoji($event)"
            title="Pick your emoji…"
          ></emoji-mart>
      </button>
      <mat-icon (click)="addBoldText()" svgIcon="bold_grey"></mat-icon>
      <mat-icon (click)="addItalicText()" svgIcon="italic_grey"></mat-icon>
      <mat-icon (click)="addStrikeText()" svgIcon="strikethrough_grey"></mat-icon>
    </span>
    <span class="message-variable-button" (click)="addBodyVariable()"><i class="fa-solid fa-plus"></i> Add Variable</span>
  </p>
  <div class="body-variable-input" *ngIf="bodyVariablesList.length > 0">
    <div class="variable-info-text">
      <span class="info-heading">Example content for variables</span>
      <span class="info-values">To help Meta review template, provide example for variable in message. Replace provided content when sending.</span>
    </div>
    <div *ngFor="let variable of bodyVariablesList; let i=index">
      <span class="info-heading"><span *ngIf="fieldName === 'Title'">Title</span> Variable {{i+1}}</span>
      <text-input
        [inputText]="bodyVariableExample[i]"
        [placeholder]="'Enter example content'"
        (textChange)="bodyVariableExample[i] = $event;"
      ></text-input>
    </div>
  </div>
</div>