import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { NumberDetails } from 'src/app/services/common.service';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.css']
})
export class NumberInputComponent implements OnInit{
  phoneNumberUtil = PhoneNumberUtil.getInstance();

  @Input() inputNumber!: string;
  @Input() fieldName!: string;
  @Input() maxChars!: number;
  @Input() disabled: boolean = false;
  @Output() numberChange = new EventEmitter<NumberDetails>();
  @Output() enterClicked = new EventEmitter<boolean>();
  countrySearchTag: string = '';
  numberDetails: NumberDetails = {
    number: this.inputNumber,
    callingCode: '',
    flag: '',
    countryCode: '',
  };

  constructor(
    public _sharedDataService: SharedDataService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ){
    this.matIconRegistry.addSvgIcon(
      'globe_icon_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/globe_icon_grey.svg'
      )
    );
  }
  
  ngOnInit(): void {
    if(this.inputNumber){
      try {
        const phoneNumber1 = this.phoneNumberUtil.parseAndKeepRawInput(
          '+'+ this.inputNumber
        );
        this.numberDetails.callingCode = phoneNumber1?.getCountryCode() ? ''+phoneNumber1.getCountryCode() : '';
        this.numberDetails.number = this.phoneNumberUtil.getNationalSignificantNumber(phoneNumber1);
        this._sharedDataService.countryCodesList.forEach((element)=> {
          if(element.callingCodes[0] === this.numberDetails.callingCode) {
            this.numberDetails.flag = element.flag;
            this.numberDetails.countryCode = element.alpha2Code;
          }
        })
        this.inputNumber = this.numberDetails.number;
        this.numberChange.emit(this.numberDetails);
        console.log(this.phoneNumberUtil.getNationalSignificantNumber(phoneNumber1));
      } catch (er) {
        console.log(er)
      }
    }
  }

  checkLength(event: any):boolean {
    if(event.keyCode > 47 && event.keyCode < 58 && this.inputNumber?.length < 15){
      this.inputNumber = this.inputNumber.toString().substring(0, 15);
      this.numberDetails.number = this.inputNumber;
      return true;
    } else {
      this.inputNumber = this.inputNumber.toString().substring(0, 15);
      this.numberDetails.number = this.inputNumber;
      return false;
    }
  }

  onCountryChange(country: any) {
    console.log(country);
    this.numberDetails.callingCode = country.callingCodes[0];
    this.numberDetails.countryCode = country.alpha2Code;
    this.numberDetails.number = this.inputNumber;
    this.numberDetails.flag = country.flag;
    this.numberChange.emit(this.numberDetails);
  }

  focusSearch() {
    document.getElementById('search')?.focus();
  }
}
