import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subscribable, Subscription, map } from 'rxjs';
import { GlobalStrings } from 'src/app/constants';

@Component({
  selector: 'map-preview',
  templateUrl: './map-preview.component.html',
  styleUrls: ['./map-preview.component.css']
})
export class MapPreviewComponent implements OnInit {

  @Input() latitude!: string;
  @Input() longitude!: string;
  mapsApiKey: string = GlobalStrings.dev_maps_api_key;
  imageUrl$: any;
  locationName: string = '';
  
  constructor(
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) { 
    this.matIconRegistry.addSvgIcon(
      'location_pin',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/location_pin.svg'
      )
    );
  }

  ngOnInit(): void {
    this.imageUrl$ = this.http.get<any>('https://maps.googleapis.com/maps/api/geocode/json?latlng='+this.latitude+','+this.longitude+'&key='+this.mapsApiKey,
    {
      headers: {
      skip: 'true',
    }})
    .pipe(
      map(response =>{
        if(response.results){
          this.locationName = response.results[0].formatted_address;
          return `https://maps.googleapis.com/maps/api/staticmap?center=${response.results[0].geometry.location.lat},${response.results[0].geometry.location.lng}&zoom=14&size=300x150&key=${this.mapsApiKey}`;
        }
        else return null
      }
    ));
  }

  openMap(){
    window.open('https://www.google.com/maps/place/'+ this.latitude+','+this.longitude,"_blank");
  }

}
