<div class="small-document-container">
  <span class="document-icon" *ngIf="getFileType() === 'pdf'">
    <i class="fa-solid fa-file-pdf"></i>
  </span>
  <span class="document-icon" *ngIf="getFileType() === 'docx'">
    <i class="fa-solid fa-file-lines"></i>
  </span>
  <span class="document-icon" *ngIf="getFileType() === 'pptx'">
    <i class="fa-solid fa-file-powerpoint"></i>
  </span>
  <span class="document-icon" *ngIf="getFileType() === 'xlsx'">
    <mat-icon
      svgIcon="excel_icon"
    ></mat-icon>
  </span>
  <span class="document-description">
    <p class="document-name">{{filename}}</p>
    <p class="document-type">{{getFileType()}}</p>
  </span>
  <mat-icon class="video-close-button" svgIcon="close_attachments_grey" (click)="documentClosed.emit(true)"></mat-icon>
</div>