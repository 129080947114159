<div class="dropdown">
  <button
  class="btn btn-custom-dropdown dropdown-toggle variable-dropdown"
  type="button"
  id="variableDropdownMenu"
  data-bs-toggle="dropdown"
  aria-expanded="false"
  >
    <span class="variable-selected-type">{{ variableDisplayName[variableType] }}</span>
  </button>
  <ul
    class="dropdown-menu custom-dropdown-menu variable-dropdown-menu"
    aria-labelledby="headerDropdownMenu"
  >
    <li class="variable-type-heading">Fixed</li>
    <li class="variable-dropdown-item" 
      (click)="variableType = 'Text'; typeChange.emit(variableType)"
      [ngClass]="{active : variableType === 'Text'}"
    >
      <mat-radio-button [name]="labelName" [checked]="variableType === 'Text'" >
        Text
      </mat-radio-button>
    </li>
    <li class="variable-type-heading">Dynamic</li>
    <!-- <li class="variable-dropdown-item" 
      (click)="variableType = 'name'; typeChange.emit(variableType)"
      [ngClass]="{active : variableType === 'name'}"
    >
      <mat-radio-button [name]="labelName" [checked]="variableType === 'name'" >
        Name
      </mat-radio-button>
    </li> -->
    <li class="variable-dropdown-item" 
      (click)="variableType = 'first_name'; typeChange.emit(variableType)"
      [ngClass]="{active : variableType === 'first_name'}"
    >
      <mat-radio-button [name]="labelName" [checked]="variableType === 'first_name'" >
        First name
      </mat-radio-button>
    </li>
    <li class="variable-dropdown-item" 
      (click)="variableType = 'last_name'; typeChange.emit(variableType)"
      [ngClass]="{active : variableType === 'last_name'}"
    >
      <mat-radio-button [name]="labelName" [checked]="variableType === 'last_name'" >
        Last name
      </mat-radio-button>
    </li>
    <li class="variable-dropdown-item" 
      (click)="variableType = 'full_name'; typeChange.emit(variableType)"
      [ngClass]="{active : variableType === 'full_name'}"
    >
      <mat-radio-button [name]="labelName" [checked]="variableType === 'full_name'" >
        Full name
      </mat-radio-button>
    </li>
    <li class="variable-dropdown-item" 
      (click)="variableType = 'whatsapp_name'; typeChange.emit(variableType)"
      [ngClass]="{active : variableType === 'whatsapp_name'}"
    >
      <mat-radio-button [name]="labelName" [checked]="variableType === 'whatsapp_name'" >
        Whatsapp name
      </mat-radio-button>
    </li>
    <li class="variable-dropdown-item" 
      (click)="variableType = 'date_of_birth'; typeChange.emit(variableType)"
      [ngClass]="{active : variableType === 'date_of_birth'}"
    >
      <mat-radio-button [name]="labelName" [checked]="variableType === 'date_of_birth'" >
        Date of birth
      </mat-radio-button>
    </li>
    <li class="variable-dropdown-item" 
      (click)="variableType = 'email'; typeChange.emit(variableType)"
      [ngClass]="{active : variableType === 'email'}"
    >
      <mat-radio-button [name]="labelName" [checked]="variableType === 'email'" >
        Email
      </mat-radio-button>
    </li>
    <li class="variable-dropdown-item" 
      (click)="variableType = 'mobile'; typeChange.emit(variableType)"
      [ngClass]="{active : variableType === 'mobile'}"
    >
      <mat-radio-button [name]="labelName" [checked]="variableType === 'mobile'" >
        Phone number
      </mat-radio-button>
    </li>
  </ul>
</div>