import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AppSettings } from '../app.settings';
import { TeamMember } from '../models/team-member';

@Injectable({
  providedIn: 'root',
})
export class AgentService {

  teamMember!: TeamMember;

  constructor(private http: HttpClient) {}

  setData(teamMember: TeamMember) {
    this.teamMember = new TeamMember(teamMember);
  }

  getData(): TeamMember {
    return this.teamMember;
  }

  getActiveAgents(pageNumber: number): Observable<any> {
    return this.http.get<any>('/user/agents?active=1&page=' + pageNumber, {
      observe: 'response',
    });
  }

  getAllAgents(pageNumber: number): Observable<any> {
    return this.http.get<any>('/user/agents?active=0&page=' + pageNumber, {
      observe: 'response',
    });
  }
  
  deleteAgent(id: number): Observable<any> {
    return this.http.delete<any>('/user/agents/' + id + '/', {
      observe: 'response',
    });
  }

  addAgent(obj: any): Observable<any> {
    return this.http.post<any>('/user/agents/', obj, { observe: 'response' });
  }

  updateAgent(obj: any, id: number): Observable<any> {
    return this.http.put<any>('/user/agents/' + id + '/', obj);
  }

  searchTeamMember(searchTag: string, pageNumber:number): Observable<any> {
    return this.http.get<any>('/search/team-member?q='+searchTag+'&page='+pageNumber,{
      observe: 'response',
    });
  }

  updateMobileOtp(obj: any): Observable<any> {
    return this.http.post<any>('/user/mobile/', obj, {
      observe: 'response',
    })
  }

  updateMobile(obj: any): Observable<any> {
    return this.http.put<any>('/user/mobile/', obj, {
      observe: 'response',
    })
  }

  updateEmailOtp(obj: any): Observable<any> {
    return this.http.post<any>('/user/email/', obj, {
      observe: 'response',
    })
  }

  updateEmail(obj: any): Observable<any> {
    return this.http.put<any>('/user/email/', obj, {
      observe: 'response',
    })
  }

  getSingleAgent(id: number): Observable<any> {
    return this.http.get<any>('/user/agents/' + id+'/', {
      observe: 'response',
    });
  }
}
