<div id="main-wrapper">
  <ng-container *ngIf="sharedService.isLoggedIn && !sharedService.templateStudioOpened">
    <menu class="menu-bar"></menu>
  </ng-container>
  <main [ngStyle]="{ 'padding-left' : sharedService.hoverActive ? '80px' : (this.sharedService.responsiveMenuBarOpen ? '300px' : '80px')}">
    <notification-bar *ngIf="sharedService.isLoggedIn"></notification-bar>
    <router-outlet></router-outlet>
    <audio-stream *ngIf="sharedService.audioPlayer"></audio-stream>
    <!-- <dailer *ngIf="sharedService.isLoggedIn"></dailer> -->
  </main>
  <footer>
  </footer>
  <div class="toastr" *ngIf="toastr.visibility">
    <toastr></toastr>
  </div>
</div>

<div id="small-screen">
  <div class="sm-wrapper">
    <img src="../assets/images/sobot_logo.png" class="sm-logo"/>
    <p>Please use Desktop version for better experience</p>
    <p class="sm-or-text">OR</p>
    <p>You can download mobile app from play store</p>
  </div>
</div>