import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { error } from 'console';
import { Observable, forkJoin } from 'rxjs';
import { GlobalStrings } from 'src/app/constants';
import { Conversation } from 'src/app/models/conversation';
import { Message, Row, Section } from 'src/app/models/message';
import { Template } from 'src/app/models/template';
import { ChatsService } from 'src/app/services/chats.service';
import { CommonService } from 'src/app/services/common.service';
import { MediaUploadService } from 'src/app/services/media-upload.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'template-view',
  templateUrl: './template-view.component.html',
  styleUrls: ['./template-view.component.css']
})
export class TemplateViewComponent implements OnInit {

  @Input() isCampaign: boolean = false;
  @Input() template!: Template;
  @Input() conversation!: Conversation;
  @Output() sendMessage = new EventEmitter<Message>();
  @Output() cancel = new EventEmitter<boolean>();
  @Output() campaign = new EventEmitter<any>();
  MAX_VARIABLES: number = 16;
  imageSrc: string = '';
  imageToBeUploaded!: any;
  imageSelected: boolean = false;
  documentSelected = false;
  documentToBeUploaded!: any;
  documentSrc: string = '';
  videoSelected = false;
  videoToBeUploaded!: any;
  videoSrc: string = '';
  isHeaderVariable = false;
  headerVariable1 = '';
  isBodyVariable = false;
  isWebsiteVariable = false;
  websiteVariableText: string[] = ['','']
  bodyVariablesCount = 0;
  previewHeaderText: string[] = [];
  previewBodyText: string[] = [];
  variableTextInput: string[] = Array<string>(this.MAX_VARIABLES).fill('');
  variableInputType: string[] = Array<string>(this.MAX_VARIABLES).fill('Text');
  headerVariableType: string = 'Text';

  presignObj: any;
  presignUrl: string = '';
  presignFields: any;
  loaderSendTemplate: boolean = false;

  location = {
    latitude : '',
    longitude: '',
    name: '',
    address: '',
  }
  variable = '{{1}}';
  today = new Date();
  scheduleDate!: Date;
  scheduleTime!: string;

  carouselFileList: {
    variableInputType: string[],
    file: File | null,
    src: string,
    presignUrl: string,
    presignFields: any,
    variables: {value: string}[],
  }[] = [];

  catalogRetailerId: string = '';
  productsList: Section[] = [];
  productsThumbnailId: string = '';
  emptyRow = {
    id: '',
    title: '',
    description: '',
  }
  emptySection = {
    rows: [this.emptyRow],
    title: ''
  }
  productsCount = 0;

  constructor(
    private toastr: ToasterService,
    private chatsService: ChatsService,
    private commonService: CommonService,
    private _mediaUploadService: MediaUploadService,
    private http: HttpClient,
    public constants: GlobalStrings,
    private _sharedDataService: SharedDataService,
  ) { }

  ngOnInit(): void {
    console.log(this.template);
    if (this.template.header) {
      let tempHeaderText = this.template.header;
      if (this.template.header.indexOf('{{1}}') > -1) {
        this.isHeaderVariable = true;
        let variableHeaderIndex = this.template.header.indexOf('{{1}}');
        this.previewHeaderText[0] = tempHeaderText.slice(
          0,
          variableHeaderIndex
        );
        this.previewHeaderText[1] = tempHeaderText.slice(
          variableHeaderIndex + 5
        );
        //console.log(this.previewHeaderText);
      } else {
        this.isHeaderVariable = false;
        this.previewHeaderText[0] = tempHeaderText;
      }
    }
    let tempBodyText = this.template.template;
    const regex = new RegExp(GlobalStrings.templateVariableRegex, 'gi');
    let bodyVariableMatches = tempBodyText.match(regex);
    if(bodyVariableMatches){
      this.isBodyVariable = true;
      this.bodyVariablesCount = bodyVariableMatches.length;
      let remainingStrings = tempBodyText.split(regex);
      this.previewBodyText = remainingStrings;
    } else {
      this.isBodyVariable = false;
      this.previewBodyText= [tempBodyText];
      this.bodyVariablesCount = 0;
    }
    this.template.cta_website?.forEach((element) => {
      if(element?.url?.indexOf('{{1}}') > 0) this.isWebsiteVariable = true;
    })
    if(this.template?.carousel_cards?.length > 0) {
      for(let index = 0;index< this.template?.carousel_cards?.length; index++){
        let cardPayload = {
          variableInputType: ['Text','Text'],
          file: null,
          src: this.template.carousel_cards[index].example,
          presignFields: null,
          presignUrl: '',
          variables: [{value: ''}],
        }
        cardPayload.variables = [];
        for(var variableIndex = 0;variableIndex < this.template.carousel_cards[index].body_text_example?.length; variableIndex++) {
          cardPayload.variables.push({value: this.template.carousel_cards[index].body_text_example[variableIndex]})
        }
        this.carouselFileList.push(cardPayload)
      }
    }
  }

  closeTemplateView() {
    this.cancel.emit();
  }

  getFileType(input: string | undefined) {
    return this.commonService.getStringAfterDot(input);
  }

  getVariablesReplacedString(){
    let replacedBody = ''+this.previewBodyText[0];
    for(var index = 1; index< this.previewBodyText?.length; index++){
      replacedBody = replacedBody + (this.variableTextInput[index-1] === '' ? '{{'+index+'}}' : this.variableTextInput[index-1]) + this.previewBodyText[index];
    }
    return replacedBody;
  }
  changeTemplateImage(event: any) {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.jpeg,.jpg,.png';
    input.onchange = (_) => {
      let files = input.files!;
      if (
        files[0]?.name.split('.').pop() === 'jpeg' ||
        files[0]?.name.split('.').pop() === 'jpg' ||
        files[0]?.name.split('.').pop() === 'png'
      ) {} else {
        this.toastr.error(GlobalStrings.templateImageType);
        return ;
      }
      if(files[0].size/1024 > 5120){
        this.toastr.error(GlobalStrings.mediaImageSize);
        return ;
      }
      this.imageToBeUploaded = files[0];
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e: any) => {
        this.imageSrc = e.target.result;
        this.template.example = this.imageSrc;
        // this.compressFile(
        //   this.imageSrc,
        //   this.imageToBeUploaded.name.replaceAll(' ', '_')
        // );
      };
      this._mediaUploadService.getPresignUrl(this.imageToBeUploaded.name,'IMAGE').subscribe((res: any) => {
        this.presignUrl = res.body.data.url;
        this.presignFields = res.body.data.fields;
      });
    };
    input.click();
  }
  // compressFile(image: string, fileName: string) {
  //   let compressedImageSrc = image;
  //   var orientation = -1;
  //   console.log(this.imageCompress.byteCount(image) / (1024 * 1024));
  //   this.imageCompress
  //     .compressFile(image, orientation, 50, 50)
  //     .then((result) => {
  //       this.compressedImage = result;
  //       console.log(this.imageCompress.byteCount(result) / (1024 * 1024));
  //       // call method that creates a blob from dataUri
  //       const imageBlob = this.dataURItoBlob(
  //         this.compressedImage.split(',')[1]
  //       );
  //       //imageFile created below is the new compressed file which can be send to API in form data

  //       let imageAfterCompressed = new File([imageBlob], fileName, {
  //         type: 'image/' + fileName.replaceAll(' ', '_').split('.').pop(),
  //       });
  //       this.multipleImages.push({
  //         imageSrc: compressedImageSrc,
  //         image: imageAfterCompressed,
  //       });
  //       console.log(this.multipleImages);
  //     });
  // }
  changeTemplateDocument(event: any) {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.pdf';
    input.onchange = (_) => {
      let files = input.files!;
      if (files[0]?.name.split('.').pop() === 'pdf') {
      } else {
        this.toastr.error(GlobalStrings.templateDocumentType);
        return ;
      }
      if(files[0].size/1024 > 102400){
        this.toastr.error(GlobalStrings.mediaDocumentSize);
        return ;
      }
      this.documentToBeUploaded = files[0];
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e: any) => {
        this.documentSrc = e.target.result;
        this.template.example = this.documentSrc;
      };
      this._mediaUploadService.getPresignUrl(this.documentToBeUploaded.name,'DOCUMENT').subscribe((res: any) => {
        this.presignUrl = res.body.data.url;
        this.presignFields = res.body.data.fields;
      });
    };
    input.click();
  }
  changeTemplateVideo(event: any) {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.mp4,.3gp';
    input.onchange = (_) => {
      let files = input.files!;
      if (
        files[0].name.split('.').pop() === 'mp4' ||
        files[0].name.split('.').pop() === '3gp'
      ) {
        if(files[0].size/1024 > 16384){
          this.toastr.error(GlobalStrings.mediaVideoSize);
          return ;
        }
        this.videoToBeUploaded = files[0];
        this.videoSrc = window.URL.createObjectURL(files[0]);
        var reader = new FileReader();
        reader.onload = (e: any) => {
          this.videoSrc = e.target.result;
          this.template.example = this.videoSrc;
          console.log(this.videoSrc);
          console.log(typeof this.videoSrc);
        };
        reader.readAsDataURL(files[0]);
        this._mediaUploadService.getPresignUrl(this.videoToBeUploaded.name,'VIDEO').subscribe((res: any) => {
            this.presignUrl = res.body.data.url;
            this.presignFields = res.body.data.fields;
          });
      } else {
        this.toastr.error(GlobalStrings.templateVideoType);
      }
    };
    input.click();
  }

  carouselTextChange(cardIndex: number, variableIndex: number, text: string) {
    this.template.carousel_cards[cardIndex].body_text_example[variableIndex] = text;
  }
  changeCarouselImage(event: any, index: number) {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.jpeg,.jpg,.png';
    input.onchange = (_) => {
      let files = input.files!;
      if (
        files[0]?.name.split('.').pop() === 'jpeg' ||
        files[0]?.name.split('.').pop() === 'jpg' ||
        files[0]?.name.split('.').pop() === 'png'
      ) {} else {
        this.toastr.error(GlobalStrings.templateImageType);
        return ;
      }
      if(files[0].size/1024 > 5120){
        this.toastr.error(GlobalStrings.mediaImageSize);
        return ;
      }
      this.carouselFileList[index].file = files[0];
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e: any) => {
        this.carouselFileList[index].src = e.target.result;
        this.template.carousel_cards[index].example = this.carouselFileList[index].src;
        // this.compressFile(
        //   this.imageSrc,
        //   this.imageToBeUploaded.name.replaceAll(' ', '_')
        // );
      };
      this._mediaUploadService.getPresignUrl(this.carouselFileList[index]!.file!.name,'IMAGE').subscribe((res: any) => {
        this.carouselFileList[index].presignUrl = res.body.data.url;
        this.carouselFileList[index].presignFields = res.body.data.fields;
      });
    };
    input.click();
  }
  changeCarouselVideo(event: any, index: number) {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.mp4,.3gp';
    input.onchange = (_) => {
      let files = input.files!;
      if (
        files[0].name.split('.').pop() === 'mp4' ||
        files[0].name.split('.').pop() === '3gp'
      ) {
        if(files[0].size/1024 > 16384){
          this.toastr.error(GlobalStrings.mediaVideoSize);
          return ;
        }
        this.carouselFileList[index].file = files[0];
        this.carouselFileList[index].src = window.URL.createObjectURL(files[0]);
        var reader = new FileReader();
        reader.onload = (e: any) => {
          this.carouselFileList[index].src = e.target.result;
          this.template.carousel_cards[index].example = this.carouselFileList[index].src;
        };
        reader.readAsDataURL(files[0]);
        this._mediaUploadService.getPresignUrl(this.carouselFileList[index].file!.name,'VIDEO').subscribe((res: any) => {
            this.carouselFileList[index].presignUrl = res.body.data.url;
            this.carouselFileList[index].presignFields = res.body.data.fields;
          });
      } else {
        this.toastr.error(GlobalStrings.templateVideoType);
      }
    };
    input.click();
  }
  handleCarouselTextChange(newText: string, cardIndex: number, variableIndex: number) {
    console.log(newText)
    this.template.carousel_cards[cardIndex].body_text_example[variableIndex] = newText;
  }

  validateMediaUrl() {
    if(this.getVariablesReplacedString().length > 1024) {
      this.toastr.error('Message body should not be more than 1024 characters');
      return;
    }
    if(this.template?.template_type === 'MEDIA') {
      if(this.template?.header_type === 'IMAGE') {
        if(this.imageToBeUploaded) this.sendTemplate();
        else {
          let input ={
            url : this.template?.example ? this.template?.example : '',
          }
          this._mediaUploadService.checkValidMedia(input).subscribe({
            next: resp => {
              if(resp.status === 200) {
                this.sendTemplate();
              } else {
                console.log("image doesn't exist");
                this.toastr.error('Please select image file');
                return ;
              }
            },
            error: error => {
              console.log("image doesn't exist");
              this.toastr.error('Please select image file');
              return ;
            }
          })
        }
      } else if(this.template?.header_type === 'VIDEO') {
        if(this.videoToBeUploaded) this.sendTemplate();
        else {
          let input ={
            url : this.template?.example ? this.template?.example : '',
          }
          this._mediaUploadService.checkValidMedia(input).subscribe({
            next: resp => {
              if(resp.status === 200) {
                this.sendTemplate();
              } else {
                console.log("video doesn't exist");
                this.toastr.error('Please select video file');
                return ;
              }
            },
            error: error => {
              console.log("video doesn't exist");
              this.toastr.error('Please select video file');
              return ;
            }
          });
        }
      } else if(this.template?.header_type === 'DOCUMENT') {
        if(this.documentToBeUploaded) this.sendTemplate();
        else {
          let input ={
            url : this.template?.example ? this.template?.example : '',
          }
          this._mediaUploadService.checkValidMedia(input).subscribe({
            next: resp => {
              if(resp.status === 200) {
                this.sendTemplate();
              } else {
                console.log("document doesn't exist");
                this.toastr.error('Please select document file');
                return ;
              }
            },
            error: error => {
              console.log("document doesn't exist");
              this.toastr.error('Please select document file');
              return ;
            }
          });
        }
      }
    } else this.sendTemplate();
  }

  sendTemplate() {
    let replacedFiles: any[] = [];
    for(var fileIndex = 0; fileIndex < this.carouselFileList?.length; fileIndex++) {
      if(this.carouselFileList[fileIndex].file) {
        this.carouselFileList[fileIndex].src = this.carouselFileList[fileIndex].presignUrl.charAt(this.carouselFileList[fileIndex].presignUrl.length -1) === '/' ? this.carouselFileList[fileIndex].presignUrl + this.carouselFileList[fileIndex].presignFields['key'] : this.carouselFileList[fileIndex].presignUrl +'/'+ this.carouselFileList[fileIndex].presignFields['key']
        replacedFiles.push({
          ...this.carouselFileList[fileIndex],
          index: fileIndex,
        });
      }
    }
    if(replacedFiles?.length === 0) {
      this.preparePayload();
    } else {
      this.uploadCarouselFiles(replacedFiles).subscribe({
        next: resp => {
          this.preparePayload();
        }, error: error => {
          this.toastr.error(error);
        }
      })
    }
  }

  preparePayload(){
    let input: Record<string, any> = {}
    if(!this.isCampaign) {
      this.loaderSendTemplate = true;
      let tempUUID = uuidv4();
      input['fb_msg_type'] = 'template';
      input['entity_name'] = this.template?.entity_name;
      input['msg_id'] = tempUUID;
      input['lang_code'] = this.template?.lang_code;
      if (!this.conversation.id) {
        input['customer_id'] = this.conversation.customer.id;
      } else {
        input['conversations_id'] = this.conversation.id;
      }
    }
    if(
      this.isHeaderVariable || 
      this.isBodyVariable || 
      this.isWebsiteVariable || 
      this.template.header_type === 'IMAGE' || 
      this.template.header_type === 'VIDEO' || 
      this.template.header_type === 'DOCUMENT' ||
      this.template.header_type === 'LOCATION' || 
      this.template?.limited_time_offer?.length > 0 ||
      this.template?.carousel_cards?.length > 0 || 
      this.template?.catalog_button_text?.length > 0 || 
      this.template?.mpm_button_text?.length > 0
    ) input['parms'] = [];
    if (this.isBodyVariable) {
      let bodyParameters = [];
      for(var index = 0;index< this.bodyVariablesCount;index++){
        if(this.variableInputType[index]=== 'Text' &&  this.variableTextInput[index] === ''){
          this.toastr.error('Enter body variable '+(index+1)+' value');
          this.loaderSendTemplate = false;
          return ;
        }
        if(this.isCampaign) {
          bodyParameters.push({
            type: 'text',
            text: this.variableTextInput[index] === '' ? '{{'+(index+1)+'}}' : this.variableTextInput[index],
            fixed: this.variableInputType[index]=== 'Text' ? true : false,
            field_name: this.variableInputType[index] === 'Text' ? 'name' : this.variableInputType[index],
          });
        } else {
          bodyParameters.push({
            type: 'text',
            text: this.variableTextInput[index] === '' ? '{{'+(index+1)+'}}' : this.variableTextInput[index],
          });
        }
      }
      input['parms'].push({
        type: 'body',
        parameters: bodyParameters,
      });
    }
    if(this.isWebsiteVariable){
      for(var i =0;i< this.template.cta_website?.length;i++) {
        if(this.template.cta_website[i]?.url?.indexOf('{{1}}') > 0) {
          if( this.websiteVariableText[i] === ''){
            this.toastr.error(`Enter URL variable ${i+1} value`);
            this.loaderSendTemplate = false;
            return;
          }
          input['parms'].push({
            type: 'button',
            sub_type: 'url',
            index: '0',
            parameters: [{type: 'text', text:  this.websiteVariableText[i]}]
          });
        }
      }
    }
    if((this.template?.limited_time_offer?.length > 0)) {
      if(this.template?.limited_time_offer[0]?.has_expiration) {
        if(!(this.scheduleDate && this.scheduleTime)) {
          this.toastr.error(`Enter expiration date and time`);
          this.loaderSendTemplate = false;
          return;
        }
        input['parms'].push({
          type: 'limited_time_offer',
          parameters: [
            {
              type: 'limited_time_offer', 
              limited_time_offer:  {
                expiration_time_ms: this.scheduleDate.getFullYear() +'-'+(this.scheduleDate.getMonth()+1)+'-'+this.scheduleDate.getDate()+ ' '+this.scheduleTime
              }
            }
          ]
        });
      } 
      input['parms'].push({
        "type": "button",
        "sub_type": "copy_code",
        "index": 0,
        "parameters": [
            {
                "type": "coupon_code",
                "coupon_code": this.template?.limited_time_offer[0]?.coupon_code
            }
        ]
      });
    }
    if(this.template.header_type === 'LOCATION') {
      if(this.location.latitude === '' || this.location.longitude === '' || this.location.name === '' || this.location.address === '') {
        this.toastr.error('Enter location details');
          this.loaderSendTemplate = false;
          return ;
      }
      input['parms'].push({
        type: 'header',
        parameters: [{
          type: 'location',
          location: {
            latitude : Number(this.location.latitude),
            longitude: Number(this.location.longitude),
            name: this.location.name,
            address: this.location.address,
          }
        }]
      })
    }
    if(this.template?.catalog_button_text?.length > 0) {
      if(this.catalogRetailerId === ''){
        this.toastr.error('Enter retailer id value for catalog');
        this.loaderSendTemplate = false;
        return ;
      }
      input['parms'].push(
        {
          type: "button",
          sub_type: "CATALOG",
          index: 0,
          parameters: [
            {
              type: "action",
              action: {
                thumbnail_product_retailer_id: this.catalogRetailerId,
              }
            }
          ]
        }
      )
    }
    if(this.template?.mpm_button_text?.length > 0) {
      if(this.productsThumbnailId === ''){
        this.toastr.error('Enter thumbnail retailer id value for products list');
        this.loaderSendTemplate = false;
        return ;
      }
      let parms: Record<string,any> = {
        type: "button",
        sub_type: "MPM",
        index: 0,
        parameters: [
          {
            type: "action",
            action: {
              thumbnail_product_retailer_id: this.productsThumbnailId,
              sections: []
            },
          }
        ]
      }
      if(this.productsList?.length === 0) {
        this.toastr.error('Atleat one product should be added');
        this.loaderSendTemplate = false;
        return ;
      }
      for(var sectionIndex= 0; sectionIndex < this.productsList?.length; sectionIndex++) {
        if(this.productsList[sectionIndex].title === ''){
          this.toastr.error(`Enter section ${sectionIndex+ 1} name `);
          this.loaderSendTemplate = false;
          return ;
        }
        let section: Record<string,any> = {
          title: this.productsList[sectionIndex].title,
        }
        section['product_items'] = [];
        for(var productIndex = 0; productIndex < this.productsList?.length; productIndex++) {
          if(this.productsList[sectionIndex].rows[productIndex].title === ''){
            this.toastr.error(`Enter retailer id of product ${productIndex+1} of section ${sectionIndex+ 1} `);
            this.loaderSendTemplate = false;
            return ;
          }
          let products = {
            product_retailer_id: this.productsList[sectionIndex].rows[productIndex].title
          }
          section['product_items'].push(products);
        }
        parms['parameters'][0]['action']['sections'].push(section);
      }
      input['parms'].push(parms);
    }
    if(this.template?.carousel_cards?.length > 0) {
      let carouselCards = [];
      for(let cardIndex = 0; cardIndex< this.template?.carousel_cards?.length; cardIndex++){
        let card = this.template.carousel_cards[cardIndex];
        let payload: Record<string,any> = {
          card_index: cardIndex,
          components: []
        }
        let bodyParameters = [];
        for(var index = 0;index< card.body_text_example?.length;index++){
          if(card.body_text_example[index]=== 'Text' &&  card.body_text_example[index] === ''){
            this.toastr.error('Enter body variable '+(index+1)+' value');
            this.loaderSendTemplate = false;
            return ;
          }
          if(this.isCampaign) {
            bodyParameters.push({
              type: 'text',
              text: card.body_text_example[index] === '' ? '{{'+(index+1)+'}}' : card.body_text_example[index],
              fixed: this.carouselFileList[cardIndex].variableInputType[index]=== 'Text' ? true : false,
              field_name: this.carouselFileList[cardIndex].variableInputType[index] === 'Text' ? 'name' : this.carouselFileList[cardIndex].variableInputType[index],
            });
          } else {
            bodyParameters.push({
              type: 'text',
              text: card.body_text_example[index] === '' ? '{{'+(index+1)+'}}' : card.body_text_example[index],
            });
          }
        }
        if(bodyParameters?.length > 0) payload['components'].push({
          type: 'BODY',
          parameters: bodyParameters,
        });
        if(card.header_type === 'IMAGE') {
          payload['components'].push({
            type: 'header',
            parameters: [
              {
                type: 'image',
                image: {
                  link: this.carouselFileList[cardIndex].src,
                },
              },
            ],
          },)
        }
        if(card.header_type === 'VIDEO') {
          payload['components'].push({
            type: 'header',
            parameters: [
              {
                type: 'video',
                video: {
                  link: this.carouselFileList[cardIndex].src,
                },
              },
            ],
          },)
        }
        if(this.template?.buttons?.length > 0) {
          for(var buttonIndex = 0; buttonIndex< this.template?.buttons?.length; buttonIndex++) {
            payload['components'].push({
              type: 'BUTTON',
              sub_type: 'QUICK_REPLY',
              index: buttonIndex
            })
          }
        }
        if(this.template?.cta_website?.length > 0) {
          for(var websiteIndex = 0; websiteIndex< this.template?.buttons?.length; websiteIndex++) {
            payload['components'].push({
              type: 'BUTTON',
              sub_type: 'URL',
              index: websiteIndex + this.template?.buttons?.length,
            })
          }
        }
        carouselCards.push(payload);
      }
      if(this.isCampaign) {
        input['parms'].push({
          type: 'CAROUSEL',
          parameters: [
            {
              type: 'cards',
              cards: carouselCards,
            }
          ]
        })
      } else {
        input['parms'].push({
          type: 'CAROUSEL',
          cards: carouselCards,
        })
      }
    }
    if(this.template.header_type === 'IMAGE' || this.template.header_type === 'VIDEO' || this.template.header_type === 'DOCUMENT'){
      let type = '';
      if(this.template.header_type === 'IMAGE'){
        input['parms'].push(
          {
            type: 'header',
            parameters: [
              {
                type: 'image',
                image: {
                  link: this.imageSrc
                    ? this.presignUrl.charAt(this.presignUrl.length -1) === '/' ? this.presignUrl + this.presignFields['key'] : this.presignUrl +'/'+ this.presignFields['key']
                    : this.template?.example,
                },
              },
            ],
          },
        );
        type = 'IMAGE';
      }
      else if(this.template.header_type === 'VIDEO'){
        input['parms'].push(
          {
            type: 'header',
            parameters: [
              {
                type: 'video',
                video: {
                  link: this.videoSrc
                    ? this.presignUrl.charAt(this.presignUrl.length -1) === '/' ? this.presignUrl + this.presignFields['key'] : this.presignUrl +'/'+ this.presignFields['key']
                    : this.template?.example,
                },
              },
            ],
          },
        );
        type = 'VIDEO';
      }
      else if(this.template.header_type === 'DOCUMENT'){
        input['parms'].push(
          {
            type: 'header',
            parameters: [
              {
                type: 'document',
                document: {
                  link: this.documentSrc
                    ? this.presignUrl.charAt(this.presignUrl.length -1) === '/' ? this.presignUrl + this.presignFields['key'] : this.presignUrl +'/'+ this.presignFields['key']
                    : this.template?.example,
                  filename: this.documentSrc ? this.documentToBeUploaded?.name.replaceAll(' ','_') : this.template?.example?.split('/').pop(),
                },
              },
            ],
          },
        );
        type = 'DOCUMENT';
      }
      if((type ==='IMAGE' && this.imageToBeUploaded) || 
        (type ==='VIDEO' && this.videoToBeUploaded) || 
        (type ==='DOCUMENT' && this.documentToBeUploaded)) {
        let file: File;
        if(type === 'IMAGE') file = this.imageToBeUploaded;
        else if(type === 'VIDEO') file = this.videoToBeUploaded;
        else file = this.documentToBeUploaded;
        if(this.isCampaign) {
          this.campaign.emit([input['parms'],file])
        } else {
          let response = this._mediaUploadService.uploadToS3(this.presignUrl,this.presignFields,file);
          response.subscribe((resp: any) => {
              this.chatsService.sendMessage(input).subscribe(
                (resp) => {
                  this.sendMessage.emit(new Message(resp.body.data));
                  this.loaderSendTemplate = false;
                },
                (error) => {
                  this.toastr.error(error);
                  this.loaderSendTemplate = false;
                  console.log(error);
                }
              );
            });
        }
      } else {
        if(this.isCampaign) {
          this.campaign.emit([input['parms']])
        } else {
          this.chatsService.sendMessage(input).subscribe(
            (resp) => {
              this.sendMessage.emit(new Message(resp.body.data));
              this.loaderSendTemplate = false;
            },
            (error) => {
              this.toastr.error(error);
              this.loaderSendTemplate = false;
              console.log(error);
            }
          );
        }
      }
    } else {
      if (this.isHeaderVariable) {
        if (this.headerVariable1 === '') {
          this.toastr.error('Enter header variable value');
          this.loaderSendTemplate = false;
          return;
        } else {
          let params = [
            {
              type: 'text',
              text: this.headerVariable1,
            },
          ];
          input['parms'].push({
            type: 'header',
            parameters: params,
          });
        }
      }
      if(this.isCampaign) {
        this.campaign.emit([input['parms']])
      } else {
        this.chatsService.sendMessage(input).subscribe(
          (resp) => {
            this.sendMessage.emit(new Message(resp.body.data));
            this.loaderSendTemplate = false;
          },
          (error) => {
            this.loaderSendTemplate = false;
            this.toastr.error(error);
            console.log(error);
          }
        );
      }
    }
  }

  uploadCarouselFiles(files: any[]) :Observable<any> {
    let allUploads = files.map((file) => {
      return this._mediaUploadService.uploadToS3(file.presignUrl,file.presignFields,file.file);
    })
    return forkJoin(allUploads)
  }

  addItems(index: number) {
    this.productsList[index].rows.push(new Row(this.emptyRow));
    this.productsCount++;
  }

  addSection() {
    this.productsList.push(new Section(this.emptySection));
    this.productsCount++;
  }

  removeProduct(section: number, item: number) {
    this.productsList[section].rows.splice(item,1);
    if(this.productsList[section].rows?.length === 0) this.productsList.splice(section,1);
    this.productsCount--;
  }
}
