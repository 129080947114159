<div class="search-bar-container">
  <i *ngIf="searchInput?.length === 0" class="fa-solid fa-magnifying-glass"></i>
  <input
    type="text"
    class="search-control"
    id="search"
    [(ngModel)]="searchInput"
    (ngModelChange)="searchInputChange.emit(searchInput)"
    placeholder="Search"
    #search
  />
  <i *ngIf="searchInput?.length !== 0" class="fa-solid fa-x" (click)="removeSearch()"></i>
</div>