import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import isOnline from 'is-online';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Subject, filter, fromEvent } from 'rxjs';
import { SharedDataService } from './shared-data.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  user: any = JSON.parse(localStorage.getItem('user')!);
  websocketOpened: boolean = false;
  internetConnectionLost = true;
  webSocket!: WebSocket;
  webSocketRoom!: WebSocket;
  access!: string;
  networkStatus: boolean = false;
  newConversations: any = [];
  chatMessages: any = [];
  websocketConenctionCount: number = 0;
  pingPongResponse = false;

  isLoading: boolean = false;

  callEvents = new BehaviorSubject<any>([]);
  userStatus = new BehaviorSubject<any>([]);
  campaignStatus = new BehaviorSubject<any>([]);
  campaignProgress = new BehaviorSubject<any>([]);

  constructor(
    private router: Router, 
    private sharedService: SharedDataService,
  ) {
    this.checkInternetConnection();
    let temp = localStorage.getItem('token')?.substr(4);
    //console.log(temp);
    if (temp) {
      //   this.access = temp[1];
      this.access = temp;
    }

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        const message = event.data;
        // Handle the message from the service worker
        console.log('Received message from service worker:', message);
        // Perform actions based on the message
        if(sharedService.isLoggedIn) {
          if(message.type === 'websocket') this.openWebSocket();
          else if(message.type === 'notification_sound') this.playNotificationSound();
        }
      });      
    }
  }

  setInternetConnection(status: boolean) {
    if (status) {
      this.internetConnectionLost = false;
    } else {
      this.internetConnectionLost = true;
    }
  }
  private webSocketMessage!: () => void;
  private newConversation!: () => void;
  private updateChats!: () => void;
  private connectWebhook!: () => void;

  //TODO Use behaviour subject for websocket messages
  public openWebSocket() {
    if (!this.websocketOpened && !this.internetConnectionLost && !this.isLoading) {
      this.isLoading = true;
      this.webSocket = new WebSocket(
        (environment.https ? 'wss' : 'ws')+'://'+environment.API_URL+'/ws/chat?token=' +
          localStorage.getItem('token')?.substr(4)
      );

      this.webSocket.onerror = (event) => {
        console.log('Error:', event);
        this.websocketOpened = false;
        this.isLoading = false;
      };

      this.webSocket.onopen = (event) => {
        console.log('Open: ', event);
        this.websocketConenctionCount = 1;
        this.websocketOpened = true;
        this.pingPongResponse = true;
        this.isLoading = false;
      };

      this.webSocket.onmessage = (event) => {
        let temp = JSON.parse(event.data);
        console.log(temp);
        if (temp.type === 'pong') {
          this.pingPongResponse = true;
        } else if(temp.type === 'webhook_status'){
          this.connectWebhook();
        } else if(temp.type === 'call'){
          // this.callEvents.next(temp.data);
        } else if(temp.type === 'user_status'){
          this.userStatus.next(temp.data);
        } else if(temp.type === 'campaign_status'){
          this.campaignStatus.next(temp.data);
        } else if(temp.type === 'campaign_progress'){
          this.campaignProgress.next(temp.data);
        } else {
          if (temp.conversation != null) {
            this.newConversations.push(temp.conversation);
            this.newConversation();
          } else {
            this.chatMessages.push(temp);
            if ((this.router.url.indexOf('/chats') > -1)) this.updateChats();
            if ((this.router.url.indexOf('/chats') > -1)) this.webSocketMessage();
          }
          if(temp.type === 'message' && temp.message?.msg_type === 'user') {
            if ((this.router.url.indexOf('/chats') > -1)) {
              let conversationId = localStorage.getItem('openConversationId');
              if(conversationId && conversationId === temp.message.conversation_id.toString()) {}
              else this.showNotification(temp);
              console.log(conversationId);
            } else this.showNotification(temp);
          }
        }
      };

      this.webSocket.onclose = (event) => {
        console.log(event);
        this.websocketOpened = false;
        this.pingPongResponse = false;
        this.isLoading = false;
        if('serviceWorker' in navigator){
          if(navigator.serviceWorker.controller){
            navigator.serviceWorker.controller.postMessage({ 
              action: 'custom-action', 
              payload: {
                type: 'websocket',
                connected: false,
              },
            });
          }
        }
      };
    }
  }
  onWebSocketMessage(fn: () => void) {
    this.webSocketMessage = fn;
  }

  onWebSocketNewConversation(fn: () => void) {
    this.newConversation = fn;
  }

  onMessageUpdateChats(fn: () => void) {
    this.updateChats = fn;
  }

  onWebhookConnect(fn: () => void) {
    this.connectWebhook = fn;
  }

  //TODO Destroy websocket object on close
  public closeWebSocket() {
    if(this.webSocket) {
      this.webSocket.close();
    }
  }

  public sendMessage(msg: string) {
    this.webSocket.send(msg);
  }

  websocketPingPong() {
    if (this.pingPongResponse) {
      let input = {
        type: 'ping',
      };
      this.webSocket.send(JSON.stringify(input));
      this.pingPongResponse = false;
    } else {
      this.websocketOpened = false;
      this.closeWebSocket();
      this.connectInternet();
    }
  }
  async checkInternetConnection() {
    if (await isOnline()) {
      console.log('internet present');
      this.internetConnectionLost = false;
    } else {
      console.log('Internet lost');
      this.internetConnectionLost = true;
    }
  }

  async connectInternet() {
    if (await isOnline()) {
      this.internetConnectionLost = false;
      this.openWebSocket();
      this.websocketOpened = true;
    } else {
      this.internetConnectionLost = true;
    }
  }

  showNotification(newMessage: any) {
    const notification = new Notification(newMessage?.customer?.name, { 
      body: newMessage?.message?.message ? newMessage?.message?.message : newMessage?.message?.fb_msg_type,
      icon: environment.favicon,
    });
    this.playNotificationSound();
  }
  
  playNotificationSound() {
    console.log("Playing sound")
    let audio = new Audio('/assets/web_notification.mp3');
    audio.load();
    audio.play();
  }
}
