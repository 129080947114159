export class Call {
  private _conversation: number
  private _customer: CallCustomer
  private _labels: CallLabel[]
  private _duration: number;
  private _call_status: string
  private _direction: string
  private _created_at: Date
  private _assignee: Assignee;
  private _updated_at: Date

  constructor(callJson: any) {
    this._conversation = callJson?.conversation;
    this._customer = new CallCustomer(callJson?.customer);
    this._labels = [];
    callJson?.labels?.forEach((element: any) => {
      this._labels.push(new CallLabel(element))
    });
    this._duration = callJson?.duration;
    this._call_status = callJson?.call_status;
    this._direction = callJson?.direction;
    this._created_at = callJson?.created_at;
    this._updated_at = callJson?.updated_at;
    this._assignee = new Assignee(callJson?.assignee);
  }

    public get conversation(): number {
        return this._conversation;
    }

    public set conversation(conversation: number) {
        this._conversation = conversation;
    }

    public get customer(): CallCustomer {
        return this._customer;
    }

    public set customer(customer: CallCustomer) {
        this._customer = new CallCustomer(customer);
    }

    public get labels(): CallLabel[] {
        return this._labels;
    }

    public set labels(labels: CallLabel[]) {
        this._labels = labels;
    }

    public get duration(): number {
        return this._duration;
    }

    public set duration(duration: number) {
        this._duration = duration;
    }

    public get call_status(): string {
        return this._call_status;
    }

    public set call_status(call_status: string) {
        this._call_status = call_status;
    }

    public get direction(): string {
        return this._direction;
    }

    public set direction(direction: string) {
        this._direction = direction;
    }

    public get created_at(): Date {
        return this._created_at;
    }

    public set created_at(created_at: Date) {
        this._created_at = created_at;
    }

    public get updated_at(): Date {
        return this._updated_at;
    }

    public set updated_at(updated_at: Date) {
        this._updated_at = updated_at;
    }

    public get assignee(): Assignee {
        return this._assignee;
      }
    
      public set assignee(assignee: Assignee) {
        this._assignee = new Assignee(assignee);
      }

}


export class Assignee {
    private _name: string;
    private _id: number;
  
    constructor(assgineeJson: any) {
      this._id = assgineeJson?.id;
      this._name = assgineeJson?.name;
    }
  
    public get name(): string {
      return this._name;
    }
  
    public set name(name: string) {
      this._name = name;
    }
  
    public get id(): number {
      return this._id;
    }
  
    public set id(id: number) {
      this._id = id;
    }
  }

export class CallCustomer {
  private _id: number
  private _name: string
  private _first_name: string
  private _last_name: string
  private _mobile: string

  constructor(callCustomerJson: any) {
    this._id = callCustomerJson?.id;
    this._name = callCustomerJson?.name;
    this._first_name = callCustomerJson?.first_name;
    this._last_name = callCustomerJson?.last_name;
    this._mobile = callCustomerJson?.mobile;
  }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get first_name(): string {
        return this._first_name;
    }

    public set first_name(first_name: string) {
        this._first_name = first_name;
    }

    public get last_name(): string {
        return this._last_name;
    }

    public set last_name(last_name: string) {
        this._last_name = last_name;
    }

    public get mobile(): string {
        return this._mobile;
    }

    public set mobile(mobile: string) {
        this._mobile = mobile;
    }

}

export class CallLabel {
  private _label_id: number;
  private _name: string;
  private _color: string;
  private _bg_color: string;

    public get bg_color(): string {
        return this._bg_color;
    }

    public set bg_color(bg_color: string) {
        this._bg_color = bg_color;
    }


  constructor(labelJson: any) {
    this._label_id = labelJson?.label_id;
    this._name = labelJson?.name;
    this._color = labelJson?.color;
    this._bg_color = labelJson?.bg_color;
  }

  public get label_id(): number {
    return this._label_id;
  }

  public set label_id(labelId: number) {
    this._label_id = labelId;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get color(): string {
    return this._color;
  }

  public set color(color: string) {
    this._color = color;
  }
}