import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggedUser } from '../models/logged-user';

@Injectable({
  providedIn: 'root'
})
export class MediaUploadService {

  user: LoggedUser = new LoggedUser(JSON.parse(localStorage.getItem('user')!));

  constructor(private http: HttpClient) { }

  getPresignUrl(fileName: string, fileType:string, kyc: boolean = false, excel: boolean = false): Observable<any> {
    let epoc = (new Date()).getTime()+'-'+this.user.id+'.'+fileName.split('.').pop();
    let presignObj: Record<string,any> = {
      key: '',
      content_type: '',
    }
    if(kyc) presignObj['kyc'] = true;
    if(excel) presignObj['excel'] = true;
    if(fileType === 'IMAGE'){
      presignObj['key'] = '/images/' + epoc;
      presignObj['content_type'] = epoc.split('.').pop() === 'png' ? 'image/png' : 'image/jpeg'; 
    } else if( fileType === 'DOCUMENT') {
      presignObj['key'] = '/documents/' + epoc;
      presignObj['content_type'] = 'application/' + epoc.split('.').pop();
    } else if( fileType === 'VIDEO') {
      presignObj['key'] = '/videos/' + epoc;
      presignObj['content_type'] = 'video/' + epoc.split('.').pop();
    } else if( fileType === 'AUDIO') {
      presignObj['key'] = '/audios/' + epoc;
      presignObj['content_type'] = 'audio/' + epoc.split('.').pop();
    } else if( fileType === 'CSV') {
      presignObj['key'] = '/excel-upload/' + epoc;
      presignObj['content_type'] = 'text/' + 'csv';
    }
    if(fileType === 'CALL_MUSIC') {
      presignObj['key'] = '/' + epoc;
      presignObj['content_type'] = 'audio/' + 'mpeg3';
      presignObj['tune'] = true;
    }
    return this.http.post<any>('/pre-sign/',presignObj, { observe: 'response' });
  }

  uploadToS3(presignUrl: any, presignFields: any,media: File){
    let formData = new FormData();
    formData.append('key', presignFields['key']);
    formData.append('Content-Type', presignFields['Content-Type']);
    if(presignFields['acl']) formData.append('acl', presignFields['acl']);
    formData.append('policy', presignFields['policy']);
    formData.append(
      'x-amz-algorithm',
      presignFields['x-amz-algorithm']
    );
    formData.append(
      'x-amz-credential',
      presignFields['x-amz-credential']
    );
    formData.append(
      'x-amz-signature',
      presignFields['x-amz-signature']
    );
    formData.append('x-amz-date', presignFields['x-amz-date']);
    formData.append('file', media);
    let response = this.http.post<any>(presignUrl, formData,{
      headers: {
        skip: 'true',
      }
    })
    return response;
  }

  checkValidMedia(obj: any) :Observable<any> {
    return this.http.post<any>('/templates/check-media',obj, { observe: 'response' });
  }
}
