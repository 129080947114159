<div class="dropdown">
  <button
  class="btn btn-custom-dropdown dropdown-toggle variable-dropdown"
  type="button"
  id="variableDropdownMenu"
  data-bs-toggle="dropdown"
  aria-expanded="false"
  [ngClass]="{active: labelName !== ''}"
  >
    <span class="variable-selected-type">
      <span>Label</span>
      <span class="selected-label" *ngIf="labelName !== ''">
        <span class="name">: {{labelName}}</span>
        <span class="count" *ngIf="labelsSet.size > 1">+{{labelsSet.size - 1}}</span></span>
    </span>
    <i class="fas fa-angle-down"></i>
  </button>
  <ul
    class="dropdown-menu custom-dropdown-menu variable-dropdown-menu"
    aria-labelledby="headerDropdownMenu"
    (click)="$event.stopPropagation()"
  >
    <div class="dropdown-heading mb-8">
      Labels
    </div>
    <hr class="mb-8">
    <div class="dropdown-list mb-8" *ngIf="!loaderLabelList" (scroll)="onLabelsScroll($event)">
      <li 
        [ngClass]="{active: labelsSet.has(0)}"
        class="label-item"
        (click)="addLabel(0)"
      >
        <mat-checkbox disabled="true" [checked]="labelsSet.has(0)">
          All
        </mat-checkbox>
      </li>
      <li 
        *ngFor="let label of labelList"
        [ngClass]="{active: labelsSet.has(label.id) || labelsSet.has(0)}"
        class="label-item"
        (click)="addLabel(label.id)"
      >
        <mat-checkbox disabled="true" [checked]="labelsSet.has(label.id) || labelsSet.has(0)">
          <span class="label-details">
            <label-component [labelElement]="label"></label-component>
          </span>
        </mat-checkbox>
      </li>
    </div>
    <div class="loader-container" *ngIf="loaderLabelList">
      <mat-progress-spinner
        mode="indeterminate"
        diameter="30"
      ></mat-progress-spinner>
    </div>
    <hr class="mb-8">
    <tertiary-button [buttonText]="'Clear filters'" (buttonClicked)="clearFilters()"></tertiary-button>
  </ul>
</div>