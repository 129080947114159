import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalStrings } from 'src/app/constants';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'message-input',
  templateUrl: './message-input.component.html',
  styleUrls: ['./message-input.component.css']
})
export class MessageInputComponent implements OnInit {

  constructor(
    private toastr: ToasterService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.matIconRegistry.addSvgIcon(
      'bold_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/bold_grey.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'italic_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/italic_grey.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'strikethrough_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/strikethrough_grey.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'emoji_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/emoji_icon.svg'
      )
    );
  }

  @Output() change = new EventEmitter<any>();
  @Input() fieldName: string = 'Message'
  @Input() message: string = '';
  @Input() bodyVariableExample: string[] = [];
  @Input() maxVariables: number = 16;
  @Input() maxChar: number = 1024;
  @Input() isButtons: boolean = true;
  @Input() rows: number = 5;
  @ViewChild('body') body!: ElementRef;
  showEmojiPicker: boolean = false;
  bodyVariableCount = 0;
  bodyVariablesText: string[] = [];
  bodyVariablesList: string[] = [];
  messageCursorPosition: number = 0;
  messageLength = 0;

  ngOnInit(): void {
    const regex = new RegExp(GlobalStrings.templateVariableRegex, 'gi');
    let allVariableMatches = this.message?.match(regex);
    let variablesIndices: number[] = [];
    if(allVariableMatches) this.bodyVariablesList = allVariableMatches;
    else this.bodyVariablesList = [];
  }

  getMessageLength() {
    let length = this.message?.length;
    const regex = new RegExp(GlobalStrings.templateVariableRegex, 'gi');
    let allVariableMatches = this.message.match(regex);
    let varCount =  allVariableMatches ? allVariableMatches.length : 0;
    const lineRegex = /\n/g;
    let newLines = this.message.match(regex) ? (this.message.match(regex)?.length ?? 0) : 0;
    return length - 3*(varCount) - newLines;
  }

  checkBodyVariables() {
    this.messageLength = this.getMessageLength()
    if(this.message?.length > this.maxChar){
      this.message = this.message.substring(0,this.maxChar);
    }
    const regex = new RegExp(GlobalStrings.templateVariableRegex, 'gi');
    let allVariableMatches = this.message.match(regex);
    let variablesIndices: number[] = [];
    if(allVariableMatches) this.bodyVariablesList = allVariableMatches;
    else this.bodyVariablesList = [];
    this.bodyVariableCount = allVariableMatches?.length ? allVariableMatches?.length : 0;
    let temp = Array.from({length:this.bodyVariableCount}).map(x => '');
    for(var index = 0;index< this.bodyVariableCount;index++) {
      temp[index] = index < this.bodyVariableExample.length ? this.bodyVariableExample[index] : '';
    }
    this.bodyVariableExample = temp.slice(0,this.bodyVariableCount);
    if(allVariableMatches){
      for(var index =0; index< allVariableMatches.length;index++){
        variablesIndices.push(this.message.indexOf(allVariableMatches[index]));
        if(this.message.indexOf(allVariableMatches[index]) === 0){
          this.toastr.error(GlobalStrings.templateStartsWithVariable);
          return false;
        }
        if(this.message.indexOf(allVariableMatches[index]) === (this.message?.length - 5) ){
          this.toastr.error(GlobalStrings.templateStartsWithVariable);
          return false;
        }
      }
      for(var index = 1; index < variablesIndices.length; index++){
        if((variablesIndices[index -1]+5) === variablesIndices[index] ||  this.message.substring(variablesIndices[index -1]+ 5, variablesIndices[index]).trim() === ''){
          this.toastr.error(GlobalStrings.templateConsecutiveVariable);
          return false;
        }
      }
      for(var index=0;index< allVariableMatches.length;index++){
        if(allVariableMatches[index] !== '{{'+(index+1)+'}}'){
          this.toastr.error(GlobalStrings.templateVariablesNumericOrder);
          return false;
        }
      }
    }
    this.emitChange();
    return true;
  }
  addBodyVariable(){
    if(this.message.length > (this.maxChar - 5)){
      return false;
    }
    this.getValidCount(this.bodyVariableCount);
    if (this.bodyVariableCount > this.maxVariables) {
      this.toastr.error('Cannot use more than '+this.maxVariables+' variables');
      return false;
    } else {
      let start = this.body.nativeElement.selectionStart;
      this.message = this.message.slice(0, start) +'{{'+this.bodyVariableCount+'}}' +this.message.slice(start);
      this.bodyVariableExample.push('');
    }
    this.emitChange();
    this.checkBodyVariables();
    return true;
  }
  getValidCount(count: number) {
    if (this.message.indexOf('{{' + count + '}}') > -1 || count == 0) {
      count++;
      this.bodyVariableCount = count;
    } else {
      count--;
      this.getValidCount(count);
    }
  }

  openEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
    this.messageCursorPosition = this.body.nativeElement.selectionStart;
  }
  addEmoji(event: any) {
    let updatedString = this.message.substring(0, this.messageCursorPosition) +event.emoji.native +this.message.substring(this.messageCursorPosition);
    this.message = updatedString;
    this.emitChange();
  }
  addBoldText() {
    let start = this.body.nativeElement.selectionStart;
    let end = this.body.nativeElement.selectionEnd;
    if (start === end) {
      this.message = this.message.slice(0, start) +'**' +this.message.slice(start);
    } else {
      this.message = this.message.slice(0,start)+'*'+this.message.slice(start,end)+'*'+this.message.slice(end);
    }
    this.emitChange();
  }
  addItalicText() {
    let start = this.body.nativeElement.selectionStart;
    let end = this.body.nativeElement.selectionEnd;
    if (start === end) {
      this.message = this.message.slice(0, start) +'__' +this.message.slice(start);
    } else {
      this.message = this.message.slice(0,start)+'_'+this.message.slice(start,end)+'_'+this.message.slice(end);
    }
    this.emitChange();
  }
  addStrikeText() {
    let start = this.body.nativeElement.selectionStart;
    let end = this.body.nativeElement.selectionEnd;
    if (start === end) {
      this.message = this.message.slice(0, start) +'~~' +this.message.slice(start);
    } else {
      this.message = this.message.slice(0,start)+'~'+this.message.slice(start,end)+'~'+this.message.slice(end);
    }
    this.emitChange();
  }

  emitChange() {
    let input = {
      message: this.message,
      variables: this.bodyVariableExample,
    }
    this.change.emit(input);
  }
}
