import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { error } from 'console';
import { VoiceService } from 'src/app/services/voice.service';

@Component({
  selector: 'call-add-note',
  templateUrl: './call-add-note.component.html',
  styleUrls: ['./call-add-note.component.css']
})
export class CallAddNoteComponent implements OnInit {

  @Input() callId!: number;
  @Input() inboxId: number | undefined;
  @Output() close = new EventEmitter<boolean>();
  @Output() onAdd = new EventEmitter<any>();
  message: string = '';
  isLoading: boolean = false;

  constructor(
    private _voiceService: VoiceService,
  ) {

  }

  ngOnInit(): void {
  }

  addNote() {
    let input = {
      inbox: this.inboxId,
      call_note:  {
        note: this.message
      }
    }
    this._voiceService.addCallLogNote(this.callId,input).subscribe({
      next: resp => {
        console.log(resp);
        let data = {
          note: this.message,
        }
        this.onAdd.emit(data);
      }, error: error => {
        console.log(error);
      }
    })
  }
}
