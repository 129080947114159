<div class="popup-container">
  <div class="white-card">
    <div class="white-card-heading" *ngIf="!isSearchLabel">
      <span class="card-heading-title">Apply Label <span class="assigned-label-count">({{assignedLabelList.length}}/{{MAX_LABEL_COUNT}})</span></span>
      <span (click)="isSearchLabel = true; focusSearch()"><i class="fa-solid fa-magnifying-glass"></i></span>
    </div>
    <div class="white-card-heading" id="popup-search" *ngIf="isSearchLabel">
      <search-input style="width: 100%" [(searchInput)]="labelSearchTag" (searchInputChange)="searchLabels($event)"></search-input>
    </div>
    <div class="card-list-container">
      <div *ngIf="!loaderLabelList" class="card-label-list" (scroll)="onLabelsScroll($event)">
        <div class="label-item"
          *ngFor="let label of labelList"
          [ngClass]="{active: labelSet.has(label.id)}"
          (click)="addLabel(label.id)">
          <mat-checkbox disabled="true" [checked]="labelSet.has(label.id)">
            <span class="label-details">
              <label-component [labelElement]="label"></label-component>
            </span>
          </mat-checkbox>
        </div>
      </div>
      <div class="empty-list" *ngIf="!loaderLabelList && labelList?.length === 0">
        {{ labelSearchTag && labelSearchTag.length > 1 ? searchEmptyList : emptyLabelsMessage}} 
      </div>
      <div *ngIf="loaderLabelList" class="loader-container">
        <mat-progress-spinner
          mode="indeterminate"
          diameter="40"
        ></mat-progress-spinner>
      </div>
    </div>
    <div class="footer-buttons">
      <cancel-button 
        [cancelButtonText]="'Cancel'"
        (cancelClicked)="exitAssign()"
      ></cancel-button>
      <save-button
        [saveButtonText]="'Apply'"
        [disableSave]="loaderAssignLabel"
        [loading]="loaderAssignLabel"
        (saveClicked)="assignLabel()"
      ></save-button>
    </div>
  </div>  
</div>