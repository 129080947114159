import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AppSettings } from '../app.settings';
import { Template } from '../models/template';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {

  public _templateData!: Template;

  public get templateData(): Template {
      return this._templateData;
  }
  public set templateData(Template: Template) {
      this._templateData = Template;
  }

  HEADER_APPLICATION_JWT_JSON: any = {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json; charset=utf-8')
      .set('Authorization', localStorage.getItem('token')!),
  };

  constructor(private http: HttpClient) {}

  getTemplate(status: number,pageNumber:number): Observable<any> {
    if(status === -1) {
      return this.http.get<any>('/templates/?page='+pageNumber, {
        observe: 'response',
      });
    } else {
      return this.http.get<any>('/templates/?status=' + status+'&page='+pageNumber, {
        observe: 'response',
      });
    }
  }

  getTemplateCount() :Observable<any> {
    return this.http.get<any>('/templates/count', {
      observe: 'response',
    })
  }

  addTemplate(obj: any): Observable<any> {
    console.log(obj);
    return this.http.post<any>('/templates/', obj, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  updateTemplate(id: number, obj: any): Observable<any> {
    return this.http.put<any>('/templates/'+id+'/', obj, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  searchTemplate(searchTag: string, pageNumber:number, status: number): Observable<any> {
    if(status === -1) {
      return this.http.get<any>('/search/template?q='+ searchTag+'&page='+pageNumber, {
        observe: 'response',
      });
    } else {
      return this.http.get<any>('/search/template?q='+ searchTag+'&extra='+status+'&page='+pageNumber,{
        observe: 'response',
      });
    }
  }

  getLanguageCodes(): Observable<any> {
    return this.http.get<any>('/templates/language',{
      observe: 'response',
    });
  }

  deleteTemplate(id: number): Observable<any> {
    return this.http.delete<any>('/templates/'+id+'/', {
      observe: 'response',
    });
  }

  syncTemplates(): Observable<any> {
    return this.http.get<any>('/templates/sync', { observe: 'response'});
  }

  singleTemplateSync(id: string): Observable<any> {
    return this.http.get<any>('/templates/sync?template_id='+id, { observe: 'response' });
  }

  getSingleTemplate(id: number): Observable<any> {
    return this.http.get<any>('/templates/'+id, { observe: 'response' });
  }

  getTemplateAnalytics(waba_id: string,id: number): Observable<any> {
    return this.http.get<any>('/templates/'+waba_id+'/analytics/'+id, { 
      observe: 'response',
    });
  }
}
