import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'small-video',
  templateUrl: './small-video.component.html',
  styleUrls: ['./small-video.component.css']
})
export class SmallVideoComponent implements OnInit {

  @Input() videoUrl!: string;
  @Output() closeVideo = new EventEmitter<boolean>();

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.matIconRegistry.addSvgIcon(
      'video_play_button',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/video_play_button.svg'
      )
    );
   }

  ngOnInit(): void {
  }

}
