<div class="assign-label-popup" *ngIf="popupOpen">
  <div class="main-container" [ngClass]="currentScreen === 'dialing' ? 'dialing' : ''">
    <div class="labels-assign" *ngIf="isAddLabel">
      <dialer-label 
        [assignedLabelList]="callDetails.labels" 
        [callId]="callSuccess?.call_id"
        (assignLabelEvent)="onAssignLabel($event)"
        (cancelLabelEvent)="isAddLabel = false"
      ></dialer-label>
    </div>
    <div class="white-card" *ngIf="currentScreen === 'dialing'">
      <div class="white-card-heading">
        <span class="card-heading-title">Start a call </span>
        <span class="card-heading-description">Make the call from</span>
      </div>
      <div class="cloud-number-container">
        <inbox-dropdown (change)="inboxChange($event)"></inbox-dropdown>
      </div>
      <div class="call-number">
        <input 
          class="call-number-input" 
          type="text" 
          id="search-input" 
          [(ngModel)]="numberDetails.number"
          (keydown)="searchContactsEvent($event)"
          (keypress)="restrictLength($event)"
          placeholder="Enter the name or phone number"
        >
        <span [ngClass]="{active: dialerPad}" class="dialpad-icon" (click)="dialerPad = !dialerPad">
          <mat-icon svgIcon="dialer_pad"></mat-icon>
        </span>
      </div>
      <div class="search-contacts-list" *ngIf="numberDetails.number.length > 2">
        <span class="card-heading-description">Contacts</span>
        <div class="contact-item" *ngIf="customerList?.length === 0 && numberDetails.number.length > 2">
          <div class="contact-item-description">
            <span>
              <expiry-icon [name]="numberDetails.number"></expiry-icon>
            </span>
            <span>
              <p class="name" style="display: flex;gap: 6px"><span *ngIf="checkIfStartWithNumber()">91 </span>{{numberDetails.number}}</p>
              <p class="number" style="display: flex;gap: 6px"><span *ngIf="checkIfStartWithNumber()">+91 </span>{{numberDetails.number}}</p>
            </span>
          </div>
          <span>
            <button class="contact-item-call-button" (click)="callNumber(null)">
              <mat-icon svgIcon="calls"></mat-icon> Call
            </button>
          </span>
        </div>
        <div class="contact-item" *ngFor="let customer of customerList">
          <div class="contact-item-description">
            <span>
              <expiry-icon [name]="customer | name"></expiry-icon>
            </span>
            <span>
              <p class="name">{{customer | name}}</p>
              <p class="number">{{customer?.mobile | numberFormat}}</p>
            </span>
          </div>
          <span>
            <button class="contact-item-call-button" (click)="callNumber(customer)">
              <mat-icon svgIcon="calls"></mat-icon> Call
            </button>
          </span>
        </div>
      </div>
      <div class="dialer-pad-container" *ngIf="dialerPad">
        <div class="dialer-buttons">
          <span (click)="dialerButtonClick(1)">1</span>
          <span (click)="dialerButtonClick(2)">2</span>
          <span (click)="dialerButtonClick(3)">3</span>
        </div>
        <div class="dialer-buttons">
          <span (click)="dialerButtonClick(4)">4</span>
          <span (click)="dialerButtonClick(5)">5</span>
          <span (click)="dialerButtonClick(6)">6</span>
        </div>
        <div class="dialer-buttons">
          <span (click)="dialerButtonClick(7)">7</span>
          <span (click)="dialerButtonClick(8)">8</span>
          <span (click)="dialerButtonClick(9)">9</span>
        </div>
        <div class="dialer-buttons">
          <span (click)="dialerButtonClick(-2)">+</span>
          <span (click)="dialerButtonClick(0)">0</span>
          <span (click)="dialerButtonClick(-1)"><mat-icon svgIcon="backspace"></mat-icon></span>
        </div>
      </div>
    </div>
    <div class="white-card" style="gap: 2rem" *ngIf="currentScreen === 'in-call'">
      <div class="call-duration">
        <span class="timer"><span>{{duration | timer}}</span> Outgoing call</span>
      </div>
      <div class="cloud-number-container">
        <inbox-dropdown [selectedNumberId]="currentInbox.id" [disabled]="true"></inbox-dropdown>
      </div>
      <div class="sender-receiver-container">
        <div class="sender-container" *ngFor="let joinee of callJoinees">
          <expiry-icon [name]="joinee.name"></expiry-icon>
          <span class="cloud-number-name-number">
            <p class="name">{{joinee.name}}<span *ngIf="joinee.type === 'user' && joinee.number === user.mobile">(You)</span><span *ngIf="joinee.type === 'user' && joinee.number !== user.mobile">(Teammate)</span></p>
            <p class="number">{{joinee.number| numberFormat}}  <span>{{joinee.status}}</span></p>
          </span>
        </div>
      </div>
      <div class="label-container" *ngIf="callDetails.labels && callDetails.labels !== []">
        <ng-container *ngFor="let label of callDetails.labels">
          <label-component [labelElement]="label"></label-component>
        </ng-container>
      </div>
      <div class="notes-body" *ngIf="callDetails.note !== ''">
        <mat-icon svgIcon="note" style="width: 18px; flex-shrink: 0;"></mat-icon>
        <div class="notes-description">
          <span class="notes-description-message">{{callDetails?.note}}</span>
        </div>
      </div>
      <div class="summary-buttons">
        <span class="action-button" [ngClass]="{active: isHoldEnabled}" (click)="duration > 0 ? holdCall() : ''">
          <span><mat-icon svgIcon="phone_paused"></mat-icon></span>
        </span>
        <span class="action-button" [ngClass]="{active: isTransferEnabled}" (click)="duration > 0 ? transferCall() : ''">
          <span><mat-icon svgIcon="phone_forwarded"></mat-icon></span>
        </span>
        <span class="action-button" [ngClass]="{active: isMuteEnabled}" (click)="duration > 0 ? muteCall() : ''">
          <span><mat-icon svgIcon="mic_off"></mat-icon></span>
        </span>
        <div class="dropdown">
          <button
          class="btn btn-custom-dropdown dropdown-toggle action-dropdown"
          type="button"
          id="actionDropdownMenu"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          >
            <span class="action-selected-type">
              <i class="fa-solid fa-ellipsis"></i>
            </span>
          </button>
          <ul
            class="dropdown-menu custom-dropdown-menu action-dropdown-menu"
            aria-labelledby="headerDropdownMenu"
          >
            <li class="action-dropdown-item" (click)="duration > 0 ? holdCall() : ''">
              <span>
                <mat-icon svgIcon="phone_paused"></mat-icon>
                Hold call
              </span>
            </li>
            <li class="action-dropdown-item" (click)="duration > 0 ? transferCall() : ''">
              <span>
                <mat-icon svgIcon="phone_forwarded"></mat-icon>
                Transfer call
              </span>
            </li>
            <li class="action-dropdown-item" (click)="duration > 0 ? muteCall() : ''">
              <span>
                <mat-icon svgIcon="mic_off"></mat-icon>
                Mute mic
              </span>
            </li>
            <hr>
            <li class="action-dropdown-item" (click)="openLabel()">
              <span>
                <mat-icon svgIcon="label_grey"></mat-icon>
                Apply label
              </span>
            </li>
            <li class="action-dropdown-item" (click)="openNote()">
              <span>
                <mat-icon svgIcon="add_notes"></mat-icon>
                Add note
              </span>
            </li>
            <hr>
            <li class="action-dropdown-item end-call-button" (click)="isLoading ? '' : cutCall()">
              <span>
                <mat-icon svgIcon="calls"></mat-icon>
                End call
              </span>
            </li>
          </ul>
        </div>
        <span class="action-button" (click)="isLoading ? '' : cutCall()">
          <span class="end-button"><mat-icon svgIcon="calls"></mat-icon></span>
        </span>
      </div>
    </div>
    <div class="white-card" style="gap: 2rem" *ngIf="currentScreen === 'call-ended'">
      <div class="call-duration">
        <span class="timer">{{duration | timer}}</span>
        <span class="status">Call ended</span>
      </div>
      <div class="cloud-number-container">
        <inbox-dropdown [selectedNumberId]="currentInbox.id" [disabled]="true"></inbox-dropdown>
      </div>
      <div class="sender-receiver-container">
        <div class="call-ended-container">
          <expiry-icon [name]="callJoinees[1].name"></expiry-icon>
          <span class="cloud-number-name-number">
            <p style="text-align: center;" class="name">{{callJoinees[1].name}}</p>
            <p class="number">{{callJoinees[1].number | numberFormat}}</p>
          </span>
        </div>
      </div>
      <div class="label-container">
        <ng-container *ngFor="let label of callDetails.labels">
          <label-component [labelElement]="label"></label-component>
        </ng-container>
      </div>
      <div class="summary-buttons">
        <span class="action-button" [ngClass]="{active: isAddLabel}" (click)="openLabel()">
          <span><mat-icon svgIcon="label_grey"></mat-icon></span>
        </span>
        <span class="action-button" [ngClass]="{active: isAddNote}" (click)="openNote()">
          <span><mat-icon svgIcon="add_notes"></mat-icon></span>
        </span>
        <span class="action-button close" (click)="closePopup()">
          <span><mat-icon svgIcon="rejected_icon"></mat-icon></span>
        </span>
      </div>
    </div>
    <div class="white-card" style="gap: 2rem" *ngIf="currentScreen === 'incoming-call'">
      <div class="call-duration">
        <span class="timer">Incoming call</span>
        <!-- <span class="status">Call ended</span> -->
      </div>
      <div class="cloud-number-container">
        <inbox-dropdown [disabled]="true"></inbox-dropdown>
      </div>
      <div class="sender-receiver-container">
        <div class="call-ended-container">
          <expiry-icon [name]="callJoinees[0].name"></expiry-icon>
          <span class="cloud-number-name-number">
            <!-- <p class="name">{{callSuccess?.to?.name ?? callSuccess?.to?.number}}(You)</p> -->
            <p class="number">{{callJoinees[0].number| numberFormat}}</p>
            <p class="number">is calling you</p>
          </span>
        </div>
      </div>
      <div class="check-your-phone" *ngIf="callJoinees[0].status === 'ringing'">Check your phone...</div>
      <div class="summary-buttons incoming">
        <button
          class="end-button"
          [disabled]="isLoading"
          (saveClicked)="cutCall('incoming')"
        >
        <mat-icon svgIcon="calls"></mat-icon> Reject
        </button>
      </div>
    </div>
    <div class="white-card" style="gap: 2rem" *ngIf="currentScreen === 'incoming-transfer'">
      <div class="call-duration">
        <span class="timer">Transfer request</span>
        <!-- <span class="status">Call ended</span> -->
      </div>
      <div class="cloud-number-container">
        <inbox-dropdown [selectedNumberId]="callDetails.inbox_id" [disabled]="true"></inbox-dropdown>
      </div>
      <div class="sender-receiver-container">
        <div class="call-ended-container">
          <expiry-icon [name]="callJoinees[0].name"></expiry-icon>
          <span class="cloud-number-name-number">
            <!-- <p class="name">{{callSuccess?.to?.name ?? callSuccess?.to?.number}}(You)</p> -->
            <p class="number" style="justify-content: center;">{{callJoinees[0].number| numberFormat}}</p>
            <p class="number"> has transferred a call from {{callJoinees[1]?.number}} to you</p>
          </span>
        </div>
      </div>
      <div class="check-your-phone" *ngIf="callJoinees[0].status === 'ringing'">Check your phone...</div>
      <div class="summary-buttons incoming">
        <button
          class="end-button"
          [disabled]="isLoading"
          (saveClicked)="cutCall('incoming')"
        >
        <mat-icon svgIcon="calls"></mat-icon> Reject
        </button>
      </div>
    </div>
    <div class="add-note" *ngIf="isAddNote">
      <dialer-note 
        [callId]="callSuccess?.call_id" 
        [inboxId]="currentInbox?.id"
        (close)="isAddNote = false"
        (onAdd)="noteUpdate($event)"
      ></dialer-note>
    </div>
    <div class="transfer-call" *ngIf="isTransferEnabled">
      <transfer-call 
        [conferenceId]="callSuccess?.call_id"
        [inboxId]="currentInbox.id"
        [customerCallSid]="callJoinees[1].sid"
        (close)="closeTransfer()" 
        (transfer)="transferUpdate($event)"
      ></transfer-call>
    </div>
  </div>
</div>

<div class="dialer-popup" (click)="popupOpen = true; focusSearch()" *ngIf="!popupOpen">
    <mat-icon svgIcon="calls"></mat-icon>
    Make a call
</div>
<div class="shaded-screen" (click)="closePopup()" *ngIf="currentScreen === 'dialing' && popupOpen"></div>