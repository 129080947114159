import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Label } from '../models/label';

@Injectable({
  providedIn: 'root',
})
export class LabelsService {
  HEADER_APPLICATION_JWT_JSON: any = {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json; charset=utf-8')
      .set('Authorization', localStorage.getItem('token')!),
  };

  label!: Label

  constructor(private http: HttpClient) {}

  setData(label: any) {
    this.label = new Label(label);
  }

  getData(): Label {
    return this.label;
  }

  addLabel(obj: any): Observable<any> {
    return this.http.post<any>('/labels/', obj, { observe: 'response' });
  }

  updateLabel(obj: any, id: number): Observable<any> {
    return this.http.put<any>('/labels/' + id + '/', obj, {
      observe: 'response',
    });
  }

  getLabel(pageNumber: number): Observable<any> {
    return this.http.get<any>('/labels?page=' + pageNumber, {
      observe: 'response',
    });
  }

  deleteLabel(id: number): Observable<any> {
    return this.http.delete<any>('/labels/' + id + '/', {
      observe: 'response',
    });
  }

  searchLabel(searchTag: string, pageNumber: number): Observable<any> {
    return this.http.get<any>('/search/label?q='+searchTag+'&page='+pageNumber,{
      observe: 'response',
    });
  }
}
