import { Pipe, PipeTransform } from '@angular/core';
import { Template } from '../models/template';

@Pipe({
  name: 'templateButtons'
})
export class TemplateButtonsPipe implements PipeTransform {

  transform(template: any, preview: boolean = false): { type: string; text: string; }[] {
    let templateObj = new Template(template);
    let buttons: { type: string; text: string; }[] = [];
    if(templateObj?.catalog_button_text?.length > 0) {
      buttons.push({
        type: 'CATALOG',
        text: templateObj.catalog_button_text,
      });
    }
    if(templateObj?.mpm_button_text?.length > 0) {
      buttons.push({
        type: 'CATALOG',
        text: templateObj.mpm_button_text,
      });
    }
    templateObj?.buttons?.forEach((element) => {
      buttons.push({
        type: 'QUICK_REPLY',
        text: element,
      });
    });
    templateObj?.cta_website?.forEach((element) => {
      buttons.push({
        type: 'URL',
        text: element.text,
      });
    })
    templateObj?.coupon_code?.forEach((element) => {
      buttons.push({
        type: 'COUPON_CODE',
        text: 'Copy offer code',
      });
    })
    templateObj?.limited_time_offer?.forEach((element) => {
      buttons.push({
        type: 'COUPON_CODE',
        text: 'Copy offer code',
      });
    })
    templateObj?.phone_numbers?.forEach((element) => {
      buttons.push({
        type: 'PHONE_NUMBER',
        text: element.text,
      });
    })
    if(preview) return buttons;
    if(buttons?.length > 3) {
      buttons = buttons.slice(0,2);
      buttons.push({
        type: 'SEE_MORE',
        text: 'See all options',
      })
      return buttons;
    }
    else return buttons;
  }

}
