import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Label } from 'src/app/models/label';
import { LabelsService } from 'src/app/services/labels.service';

@Component({
  selector: 'label-filter',
  templateUrl: './label-filter.component.html',
  styleUrls: ['./label-filter.component.css']
})
export class LabelFilterComponent implements OnInit {

  @Input() appliedLabels: number[] = [];
  @Output() filterChange = new EventEmitter<number[]>();

  labelsSet = new Set<number>();
  loaderLabelList: boolean = false;
  labelList: Label[] = [];
  loaderLabelPagination: boolean = false;
  pageNumber: number = 1;
  pageNext: boolean = false;
  labelName = '';

  constructor(
    private labelService: LabelsService, 
  ) { }

  ngOnInit(): void {
    this.labelsSet.clear();
    this.appliedLabels?.forEach(element => {
      this.labelsSet.add(element);  
    })
    this.loaderLabelList = true;
    this.pageNumber = 1;
    this.getLabels(1);
  }

  getLabels(pageNumber:number){
    this.loaderLabelPagination = true;
    let response = this.labelService.getLabel(pageNumber);
    response.subscribe((resp)=>{
      try {
        if(resp.body.code === 200) {
          if(resp.body.next) this.pageNext = true;
          else this.pageNext = false;
          if(pageNumber === 1) this.labelList = [];
          for (var index = 0; index < resp.body.data?.length; index++) {
            this.labelList.push(new Label(resp.body.data[index]));
            if(this.labelsSet.has(resp.body.data[index].id) && this.labelName === '') this.labelName = resp.body.data[index].name;
          }
          this.loaderLabelList = false;
          this.loaderLabelPagination = false;
          this.pageNumber++;
        }
      } catch (exception) {
      }
    },(error)=>{
      console.log(error);
      this.loaderLabelList = false;
      this.loaderLabelPagination = false;
    });
  }

  onLabelsScroll(event: any) {
    if (
      event.target.scrollHeight -
        event.target.offsetHeight -
        event.target.scrollTop <
      50
    ) {
      if (this.pageNext && !this.loaderLabelPagination) {
        this.getLabels(this.pageNumber);
      }
    }
  }

  addLabel(id: number){
    if(this.labelsSet.has(id)){
      if(id === 0) this.labelsSet.clear();
      else {
        this.labelsSet.delete(id);
        this.labelsSet.delete(0);
      }
    } else {
      if(id === 0) {
        this.labelsSet.add(0)
        this.labelList?.forEach((element) => {
          this.labelsSet.add(element.id);
        })
      } else {
        this.labelsSet.add(id);
        if(this.labelsSet.size === this.labelList.length) this.labelsSet.add(0);
      }
    }
    let labelArray: number[] = [];
    this.labelsSet.forEach((id)=>{
      labelArray.push(id);
    });
    if(labelArray.length === 0) this.labelName = '';
    else {
      if(this.labelsSet.has(0)) this.labelName = 'All';
      else {
        this.labelList?.forEach(element => {
          if(element.id === labelArray[0]) this.labelName = element.name
        })
      }
    }
    this.filterChange.emit(labelArray);
  }

  clearFilters() {
    this.labelsSet.clear();
    this.labelName = '';
    this.filterChange.emit([]);
  }
}
