import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { error } from 'console';
import { element } from 'prop-types';
import { Subject, interval, takeUntil } from 'rxjs';
import { CallEnum, GlobalEnums } from 'src/app/constants';
import { CloudNumber } from 'src/app/models/cloud-number';
import { Customer } from 'src/app/models/customer';
import { Label } from 'src/app/models/label';
import { LoggedUser } from 'src/app/models/logged-user';
import { CommonService, NumberDetails } from 'src/app/services/common.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { UsersService } from 'src/app/services/users.service';
import { VoiceService } from 'src/app/services/voice.service';
import { WebSocketService } from 'src/app/services/web-socket.service';

@Component({
  selector: 'dailer',
  templateUrl: './dailer.component.html',
  styleUrls: ['./dailer.component.css']
})
export class DailerComponent implements OnInit {

  user: LoggedUser = new LoggedUser(JSON.parse(localStorage.getItem('user')!));
  popupOpen: boolean = false;
  numberDetails: NumberDetails = {
    countryCode: '',
    callingCode: '',
    number: '',
    flag: ''
  };
  prevSearchKey: string = '';

  currentInbox!: CloudNumber;
  currentScreen: string = 'dialing';
  callSuccess: any;
  incomingCallDetails: any;
  incomingCallStatus: string = 'ringing';
  dialerPad: boolean = false;

  parentCallStatus!: string;
  childCallStatus!: string;
  isLoading: boolean = false;

  customerList: Customer[] = [];
  duration: number = 0;
  stopTimer: boolean = false;

  isAddLabel: boolean = false;
  assignedLabels: Label[] = []

  isMuteEnabled: boolean = false;
  isHoldEnabled: boolean = false;

  isAddNote: boolean = false;
  noteMessage!: string;

  isTransferEnabled: boolean = false;
  childCallSid!: string;

  private timer$ = new Subject();

  callJoinees: {
    id: number,
    type: string,
    name: string,
    number: string,
    sid: string,
    status: string,
  }[] = [];

  callDetails: {
    call_id: string,
    room_id: string,
    direction: string,
    inbox_id: number,
    note: string,
    labels: Label[],
  } = {
    call_id: '',
    room_id: '',
    direction: '',
    inbox_id: 0,
    note: '',
    labels: [],
  }

  constructor(
    private voiceService: VoiceService,
    public sharedDataService: SharedDataService,
    private websockService: WebSocketService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private _toastr: ToasterService,
    private _userService: UsersService,
    private _commonService: CommonService,
  ) {
    this.matIconRegistry.addSvgIcon(
      'dialer_pad',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/dialer_pad.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'backspace',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/backspace.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'calls',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/calls.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'label_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/label_grey.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'rejected_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/rejected_icon.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'phone_paused',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/phone_paused.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'phone_forwarded',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/phone_forwarded.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'add_notes',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/add_notes.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'note',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/note.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'mic_off',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/mic_off.svg'
      )
    );
  }

  ngOnInit(): void {
    this.websockService.callEvents.subscribe((data) => {
      this.updateCall(data);
    })
    this.voiceService.makeCall.subscribe((data) => {
      this.callCustomer(data);
    })
  }

  focusSearch() {
    setTimeout(()=> {
      document.getElementById('search-input')?.focus();
    },10);
  }
  
  inboxChange(number: CloudNumber) {
    this.currentInbox = number;
    this.callDetails.inbox_id = this.currentInbox?.id;
  }

  restrictLength(event: any){
    if(this.numberDetails.number?.length < 15) {
      return true;
    }
    else return false;
  }
  searchContactsEvent(event: any) {
    if(this.prevSearchKey !== this.numberDetails.number) {
      this.numberDetails.number = this.numberDetails.number?.substring(0,15);
      this.prevSearchKey = this.numberDetails.number;
      setTimeout(() => {
        if(this.numberDetails.number?.length > 2){
          this.searchContacts()
        }
      }, 300);
    }
  }
  checkIfStartWithNumber() {
    if(this.numberDetails.number.match(/^[0-9]/)) return true;
    else return false;
  }
  searchContacts() {
    this._userService.searchUsers(1,this.numberDetails.number).subscribe({
      next: resp => {
        if (resp.body?.code == 200) {
          this.customerList = [];
          for (var index = 0; index < resp.body.data?.length; index++) {
            this.customerList.push(new Customer(resp.body.data[index]));
          }
        } else {
          console.log(resp.body.message);
        }
        this.isLoading = false;
      }, error: error => {
        console.log(error);
      }
    })
  }
 
  callNumber(customer: Customer | null) {
    let input: any;
    if(customer) {
      input = {
        customer_num: customer?.mobile,
        inbox_id: this.currentInbox?.id ?? this.sharedDataService.cloudNumbers[0].id,
        country_code: customer?.country_code ?? '91'
      }
    } else {
      if(this._commonService.isValidNumber(this.numberDetails.number, 'IN')) {
        input = {
          customer_num: '91'+ this.numberDetails.number,
          inbox_id: this.currentInbox?.id,
          country_code: '91'
        }
      } else if(this._commonService.isValidNumber2('+'+this.numberDetails.number)) {
        input = {
          customer_num: this.numberDetails.number,
          inbox_id: this.currentInbox?.id,
          country_code: '91'
        }
      } else {
        this._toastr.error("Invalid number");
        return ;
      }
    }
    this.isLoading = true;
    this.voiceService.placeCall(input).subscribe({
      next: resp => {
        if (resp.body?.code == 200) {
          this.callSuccess = resp.body.data;
          let joinee = {
            id: this.user.id,
            type: 'user',
            name: this.callSuccess?.from?.name?.length > 0 ? this.callSuccess?.from?.name : this.callSuccess?.from?.number,
            number: this.callSuccess?.from?.number,
            sid: this.callSuccess?.sid,
            status: 'initiated',
          }
          this.callJoinees.push(joinee);
          let customer = {
            id: this.callSuccess?.to?.id,
            type: 'customer',
            name: this.callSuccess?.to?.name?.length > 0 ? this.callSuccess?.to?.name : this.callSuccess?.to?.number,
            number: this.callSuccess?.to?.number,
            sid: '',
            status: '',
          }
          this.callJoinees.push(customer);
          this.callDetails.direction = this.callSuccess?.direction;
          this.callDetails.call_id = this.callSuccess?.call_id;
          this.callDetails.room_id = this.callSuccess?.room_id;
          this.currentScreen = 'in-call';
        } else {
          this._toastr.error(resp.body.message);
        }
        this.isLoading = false;
      }, error: error => {
        console.log(error)
        this.isLoading = false;
        this._toastr.error(error);
      }
    })
  }

  cutCall(type: string = 'outgoing') {
    this.isLoading = true;
    let input = {
      call_conf_room_id: this.callDetails.call_id,
      call_sid: this.getMySid(),
    }
    this.voiceService.killCall(input).subscribe({
      next: resp => {
        if (resp.body?.code == 200) {
          this.currentScreen = 'call-ended';
          this.parentCallStatus = '';
          this.childCallStatus = '';
          if(type === 'incoming') this.closePopup();
        } else {
          console.log(resp.body.message);
          this._toastr.error(resp.body.message);
        }
        this.isLoading = false;
      }, error: error => {
        console.log(error);
        this._toastr.error(error);
        this.isLoading = false;
      }
    })
  }

  updateCall(data: any) {
    if(this.currentScreen === 'in-call') {
      if(data?.room_id === this.callDetails?.room_id) {
        if(data.direction) {
          for(var index= 0; index< this.callJoinees?.length;index++) {
            if(this.callJoinees[index].sid === data.sid) {
              this.callJoinees[index].status = data.event;
              if(this.callJoinees[1].status === CallEnum.in_progress) this.startTimer();
              if(this.callJoinees[index].id === this.user.id && this.callJoinees[index].status === CallEnum.completed) this.callEnded();
              return ;
            }
          }
          for(var index= 0; index< this.callJoinees?.length;index++) {
            if(this.callJoinees[index].sid === '') {
              this.callJoinees[index].sid = data.sid;
              this.callJoinees[index].status = data.event;
              return ;
            }
          }
        } else {
          if(data.event === CallEnum.conference_end) this.callEnded();
        }
      }
    } else if(this.currentScreen === 'incoming-call') {
      if(data.call_id === this.callDetails.call_id) {
        console.log("Inside incoming call room "+ data.call_id);
        if(data.direction === CallEnum.outbound) {
          console.log("Inside incoming call -> outbound event -> "+data.event);
          this.callJoinees[1].sid = data.sid;
          this.callJoinees[1].status = data.event;
          if(this.callJoinees[1].status === CallEnum.in_progress) {
            this.startTimer();
            this.currentScreen = 'in-call';
          }
          if(this.callJoinees[1].status === CallEnum.completed) {
            this.callEnded();
          }
        } else if(data.direction === CallEnum.inbound) {
          console.log("Inside incoming call -> inbound event -> "+data.event);
          if(data.event === CallEnum.completed) this.callEnded();
        }
      }
    } else {
      if(data?.direction === CallEnum.inbound) {
        console.log("New incoming call -> inbound event");
        if(data.event === CallEnum.ringing) {
          console.log("Inside incoming call -> inbound event -> "+data.event);
          this.popupOpen = true;
          this.currentScreen = 'incoming-call';
          this.callJoinees = [];
          let customer = {
            id: data?.customer?.id,
            type: 'customer',
            name: data?.customer?.name ?? data?.customer?.mobile,
            number: data?.customer?.mobile,
            sid: data.customer?.sid,
            status: CallEnum.ringing,
          }
          this.callJoinees.push(customer);
          let joinee = {
            id: this.user?.id,
            type: 'user',
            name: this.user?.name,
            number: this.user?.mobile,
            sid: '',
            status: '',
          }
          this.callJoinees.push(joinee);
          console.log(this.callJoinees);
          this.callDetails.inbox_id = data.cloud_number_id,
          this.callDetails.call_id = data.call_id;
          this.callDetails.room_id = data.room_id;
          this.callDetails.direction = CallEnum.inbound;
          for(var i = 0;i< this.sharedDataService.cloudNumbers?.length;i++) {
            if(this.callDetails.inbox_id === this.sharedDataService.cloudNumbers[i].id) this.inboxChange(this.sharedDataService.cloudNumbers[i]);
          }
          // console.log("Getting single log");
          // this.voiceService.getSingleCallLog(this.currentInbox?.id,data.call_id).subscribe({
          //   next: resp => {
          //     if(resp.body.code === 200) {
          //       let callLog = resp.body.data;
          //       if(callLog?.participants) {
          //         callLog?.participants?.forEach((element: any) => {
          //           if(element?.user && element?.user?.id === this.user.id) {
          //             this.callJoinees[1].sid = element?.call_sid;
          //             this.callJoinees[1].status = element?.call_status;
          //           }
          //           if(element?.customer) {
          //             this.isHoldEnabled = element?.is_hold;
          //           }
          //         })
          //       }
          //       console.log(this.callJoinees);
          //       console.log(callLog);
          //     } else {
          //       this._toastr.error(resp.body.message);
          //       console.log(resp.body.message);
          //     }
          //   }, error: error => {
          //     console.log(error);
          //     this._toastr.error(error);
          //   }
          // })
        } else {
          console.log("Inside incoming call -> inbound event -> "+data.event);
          if(this.callDetails.call_id === data.call_id) {
            this.callJoinees[0].status = data.event;
            if(data.event === CallEnum.completed) {
              this.callEnded();
              this.closePopup();
            }
          }
        }
      } else {
        if(data.trans_by) {
          console.log("Inside Transfer call");
          if(data.event !== CallEnum.initiated) {
            for(var index = 0;index < this.callJoinees?.length; index++) {
              if(this.callJoinees[index].sid === data.sid) {
                this.callJoinees[index].status = data.event;
                if(data.event === CallEnum.in_progress) {
                  this.currentScreen = 'in-call';
                  this.startTimer();
                }
                break;
              }
            }
          } else {
            this.callJoinees = [];
            let user = {
              id: data?.trans_by?.id,
              type: 'user',
              name: data?.trans_by?.name,
              number: data?.trans_by?.mobile,
              sid: '',
              status: CallEnum.in_progress,
            }
            this.callJoinees.push(user);
            let customer = {
              id: data?.customer?.id,
              type: 'customer',
              name: data?.customer?.name ?? data?.customer?.mobile,
              number: data?.customer?.mobile,
              sid: data.customer?.sid,
              status: CallEnum.in_progress,
            }
            this.callJoinees.push(customer);
            let joinee = {
              id: this.user?.id,
              type: 'user',
              name: this.user?.name,
              number: this.user?.mobile,
              sid: data.sid,
              status: data.event,
            }
            this.callJoinees.push(joinee);
            this.popupOpen = true;
            this.currentScreen = 'incoming-transfer';
            this.callDetails.inbox_id = data.cloud_number_id,
            this.callDetails.call_id = data.call_id;
            this.callDetails.room_id = data.room_id;
            for(var i = 0;i< this.sharedDataService.cloudNumbers?.length;i++) {
              if(this.callDetails.inbox_id === this.sharedDataService.cloudNumbers[i].id) this.inboxChange(this.sharedDataService.cloudNumbers[i]);
            }
            this.voiceService.getSingleCallLog(this.currentInbox?.id,data.call_id).subscribe({
              next: resp => {
                if(resp.body.code === 200) {
                  let callLog = resp.body.data;
                  let labels: Label[] = [];
                  callLog?.labels?.forEach((element: any) => {
                    labels.push(this._commonService.getLabelFromAudienceLabel(element));
                  })
                  this.callDetails.labels = labels;
                  if(callLog?.call_note && callLog?.call_note?.length > 0) this.callDetails.note = callLog?.call_note[0]?.note;
                  if(callLog?.participants) {
                    callLog?.participants?.forEach((element: any) => {
                      if(element?.user && element?.user?.id !== this.user.id) {
                        this.callJoinees[0].sid === element?.call_sid;
                      }
                      if(element?.customer) {
                        this.isHoldEnabled = element?.is_hold;
                      }
                    })
                  }
                } else {
                  this._toastr.error(resp.body.message);
                  console.log(resp.body.message);
                }
              }, error: error => {
                console.log(error);
                this._toastr.error(error);
              }
            })
          }
        } else {
          if(data.call_id === this.callDetails.call_id) {
            if(data.direction) {
              console.log("Inside outgoing call -> outbound event -> "+data.event);
              for(var index=0;index< this.callJoinees?.length;index++) {
                if(this.callJoinees[index]?.sid === data.sid) {
                  this.callJoinees[index].status = data.event;
                  if(this.callJoinees[1].status === CallEnum.in_progress) {
                    this.startTimer();
                    this.currentScreen = 'in call';
                  } 
                  if(data.event === CallEnum.completed) {
                    this.callEnded();
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  getMySid() {
    this.callJoinees?.forEach((element: any) => {
      if(element?.id === this.user.id) return element?.sid;
      else {}
    })
    if(this.callDetails.direction === CallEnum.inbound) return this.callJoinees[1].sid
    else return this.callJoinees[0].sid;
  }

  startTimer() {
    interval(1000).pipe(takeUntil(this.timer$)).subscribe(() => {
      this.duration++;
    });
  }

  callEnded() {
    console.log("call ended");
    this.stopTimer = true;
    this.currentScreen = 'call-ended';
    this.timer$.next(null);
    this.timer$.complete();
  }

  dialerButtonClick(i: number) {
    if(i === -1) this.numberDetails.number = this.numberDetails.number.slice(0,-1);
    else if(i === -2) this.numberDetails.number += '+';
    else this.numberDetails.number = this.numberDetails.number + i.toString();
  }

  openLabel() {
    this.isAddLabel = true;
  }

  onAssignLabel(labels: Label[]) {
    this.assignedLabels = labels;
    this.callDetails.labels = labels;
    this.isAddLabel = false;
  }

  callCustomer(customer: any) {
    if(customer?.id) {
      let newCustomer = new Customer(customer);
      this.popupOpen = true;
      this.numberDetails.number = newCustomer.mobile;
      this.searchContactsEvent(null)
    } else if(customer?.id === 0){
      this.popupOpen = true;
    }
  }

  closePopup() {
    this.currentScreen = 'dialing';
    this.popupOpen = false;
    this.numberDetails = {
      countryCode: '',
      callingCode: '',
      number: '',
      flag: ''
    };
    this.dialerPad = false;

    this.parentCallStatus = '';
    this.childCallStatus = '';
    this.isLoading = false;
    this.customerList = [];
    this.duration = 0;
    this.stopTimer = false;
    this.isAddLabel = false;
    this.assignedLabels = [];
    this.isMuteEnabled = false;
    this.isHoldEnabled = false;

    this.isAddNote = false;
    this.noteMessage = '';

    this.isTransferEnabled = false;
    this.childCallSid = '';
    this.callJoinees = [];
    this.callDetails.call_id = '';
    this.callDetails.room_id = '';
    this.callDetails.direction = '';
    this.callDetails.inbox_id = 0;
    this.callDetails.note = '';
    this.callDetails.labels = [];
  }

  muteCall() {
    let input = {
      mute: !this.isMuteEnabled,
      call_conf_room_id: this.callDetails.call_id,
      call_sid: this.callDetails?.direction === CallEnum.outbound ? this.callJoinees[0].sid : this.callJoinees[1].sid, 
    }
    this.isMuteEnabled = !this.isMuteEnabled;
    this.voiceService.muteCall(input).subscribe({
      next: resp => {
        console.log(resp)
      }
    })
  }

  holdCall() {
    let input = {
      hold: !this.isHoldEnabled,
      call_conf_room_id: this.callDetails.call_id,
      call_sid: this.callDetails?.direction === CallEnum.inbound ? this.callJoinees[0].sid : this.callJoinees[1].sid, 
    }
    this.isHoldEnabled = !this.isHoldEnabled;
    this.voiceService.holdCall(input).subscribe({
      next: resp => {
        console.log(resp)
      }
    })
  }

  openNote() {
    this.isAddNote = true;
  }
  noteUpdate(event: any) {
    this.callDetails.note = event?.note;
    this.isAddNote = false;
  }

  transferCall() {
    this.isTransferEnabled = true;
  }
  closeTransfer() {
    this.isTransferEnabled = false;
  }
  transferUpdate(event: any) {
    if(event.type === GlobalEnums.tran_now) {
      this.isTransferEnabled = false;
      this.callEnded();
    } else if(event.type === GlobalEnums.talk_first) {
      this.isHoldEnabled = true;
    } else {
      this.callJoinees.push(event);
      this.isTransferEnabled = false;
    }
  } 
}
