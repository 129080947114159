<div class="assign-team-member-popup">
  <div class="popup-white-card">
    <div class="popup-heading-container" *ngIf="!isSearchMember">
      <span class="popup-heading-title">
        <mat-icon svgIcon="team_member_grey"></mat-icon>
        Assign user
      </span>
      <span (click)="isSearchMember = true; focusSearch()"><i class="fa-solid fa-magnifying-glass"></i></span>
    </div>
    <div class="popup-heading-container" id="popup-search" *ngIf="isSearchMember">
      <search-input style="width: 100%" [(searchInput)]="teamMemberSearchTag" (searchInputChange)="searchTeamMembers($event)"></search-input>
    </div>
    <div class="popup-selection-list" (scroll)="onTeamMemberScroll($event)" *ngIf="teamMemberList?.length !== 0">
      <table class="popup-container-table">
        <tr class="popup-table-heading-row" >
            <th class="popup-table-heading-radio" style="color: #FFFFFF">Radio</th>
            <th class="popup-table-heading">Member name</th>
            <th class="popup-table-heading">Number</th>
            <th class="popup-table-heading">Assigned Chats</th>
        </tr>
        <tr class="popup-table-row" 
          *ngFor="let teamMember of teamMemberList" 
          (click)="selectTeamMember(teamMember)"
          [ngStyle]="{'background' : isTeamMemberChecked(teamMember) ? '#E3F7F3' : 'none'}"
          >
          <td class="popup-table-cell-radio">
            <mat-radio-group class="popup-table-cell-radio-button">
              <mat-radio-button [checked]="isTeamMemberChecked(teamMember)" [value]="teamMember" >
              </mat-radio-button>
            </mat-radio-group>
          </td>
          <td class="popup-table-set-name"><span>{{teamMember?.name}}</span><br>
            <span class="popup-table-admin" *ngIf="teamMember?.user_type == 1">(<mat-icon svgIcon="admin_shield_grey"></mat-icon>Admin)</span>
          </td>
          <td class="popup-table-cell">{{teamMember?.mobile | numberFormat}}</td>
          <td class="popup-table-cell">{{teamMember?.assigned_chat_count}}</td>
        </tr>
      </table>
    </div>
    <div *ngIf="loaderTeamMember" class="loader-container">
      <mat-progress-spinner
        mode="indeterminate"
        diameter="40"
      ></mat-progress-spinner>
    </div>
    <div class="empty-list-container" *ngIf="!loaderTeamMember && teamMemberList?.length === 0">{{searchEmptyList}}</div>
    <div class="popup-selection-footer" [ngClass]="(conversation?.assignee?.id || callConversation?.assignee?.id) ? 'unassign' : ''">
      <tertiary-button *ngIf="(conversation?.assignee?.id || callConversation?.assignee?.id)" [buttonText]="'Unassign'" [isDisabled]="loaderUnassignAgent" (buttonClicked)="unassignConversation()"></tertiary-button>
      <span class="popup-selection-footer-buttons">
        <cancel-button [cancelButtonText]="'Cancel'" (cancelClicked)="closeTeamMemberPopup()"></cancel-button>
        <save-button [saveButtonText]="'Assign'" [disableSave]="loaderAssignAgent || !isTeamMemberSelected" [loading]="loaderAssignAgent" (saveClicked)="assignChatSubmit()"></save-button>
      </span>
    </div>
  </div>
</div>

<div class="shaded-screen" ></div>